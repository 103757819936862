export enum ReportType {
  Intellitags = 'intellitags',
  Arrowboards = 'arrowboards',
  SmartCables = 'smartcables',
}

export type ReportTypeOrNull = ReportType | null;

// Helper function to get display name
export const getReportDisplayName = (type: ReportType | null): string => {
  if (type === null) return '';

  switch (type) {
    case ReportType.Intellitags:
      return 'Intellitags';
    case ReportType.Arrowboards:
      return 'Arrowboards';
    case ReportType.SmartCables:
      return 'Smart Cables';
    default:
      return type;
  }
};
