import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { buildQueryString, prepareHeaders } from '../../api/apiHelpers';

interface Params {
  id: string;
}

const path = `${import.meta.env.VITE_API_BASE_PATH}/notificationContact`;

const useDeleteNotificationContact = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isPending } = useMutation({
    mutationFn: async (params: Params) => {
      const queryString = buildQueryString(params);
      return await fetch(`${path}${queryString}`, {
        mode: 'cors',
        method: 'DELETE',
        headers: await prepareHeaders(instance),
      });
    },
    onError: (error) => {
      console.error('Delete notification contact failed:', error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-contacts'] });
    },
  });

  return {
    mutate,
    mutateAsync,
    isPending,
  };
};

export default useDeleteNotificationContact;
