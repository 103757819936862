import autoTable from 'jspdf-autotable';
import {
  SmartCableRow,
  SMART_CABLE_EVENT_TYPE,
  DeviceRow,
  LegendItem,
} from '../../../../models/SupervisionReport';
import { PdfBaseProps } from '../../../../models/PdfExport';
import {
  PDF_CONSTANTS,
  createPdfDocument,
  addHeader,
  createDocumentName,
  addCoverPage,
  addLastPage,
  addLegend,
} from './ExportPdfCommon';
import { formatDate } from '../../../../utils/utils';
import circleCheck from '../../../../assets/report/StatusIcons/circle-check.png';
import circleAlert from '../../../../assets/report/StatusIcons/alert-circle.png';
import circleDotted from '../../../../assets/report/StatusIcons/circle-dotted.png';
import disconnected from '../../../../assets/report/StatusIcons/disconnected.png';
import squareGray from '../../../../assets/report/StatusIcons/square-gray.png';
import dayjs from 'dayjs';
import { addAlarmEventsTable } from './ExportPdfAlarmEvents';
import { AlarmStatus, EquipmentType } from '../../../../models/enums/DeviceEnums';
import { ReportType } from '../../../../models/enums/ReportType';

const TIMESTAMP_CELL_WIDTH = 12;
const TEXT_FIELDS_CELL_WIDTH = 20;
const FONT_SIZE = 8;
const FIXED_ICON_SIZE = 5;

export interface PdfProps extends PdfBaseProps {
  rows: SmartCableRow[];
  imageMap: Map<string, HTMLImageElement>;
}

function convertToDeviceRows(rows: SmartCableRow[]): DeviceRow[] {
  return rows.map((row) => ({
    referenceId: row.referenceId,
    name: row.name,
    attachmentRef: row.attachmentRef,
    currentEquipmentType: EquipmentType.Sign,
    entries: [
      {
        logs: row.events.map((event) => ({
          timeStamp: event.timestamp,
          alarmStatus:
            event.eventType === SMART_CABLE_EVENT_TYPE.OUT_OF_BATTERY ||
            event.eventType === SMART_CABLE_EVENT_TYPE.DISCONNECTED
              ? AlarmStatus.Alarming
              : AlarmStatus.OK,
          workZoneId: '',
          batteryStatus: 1,
          batteryVoltage: 1,
          workZoneSnoozed: false,
        })),
      },
    ],
  }));
}

export async function createPDF(props: PdfProps): Promise<void> {
  return new Promise<void>((resolve) => {
    const startDateString = formatDate(props.reportStartDate);
    const endDateString = formatDate(props.reportEndDate);

    const doc = createPdfDocument();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add cover page
    addCoverPage(doc, {
      projectName: props.projectName,
      depot: props.depot,
      startDate: startDateString,
      endDate: endDateString,
    });

    // Get unique days from start to end date
    const uniqueDays: string[] = [];
    let currentDate = dayjs(props.reportStartDate).startOf('day');
    const endDate = dayjs(props.reportEndDate).startOf('day');

    while (currentDate.isSameOrBefore(endDate)) {
      uniqueDays.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    const headers = [
      'Reference Id',
      'Name',
      'Equipment',
      ...uniqueDays.map((day) =>
        new Date(day).toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
        })
      ),
    ];

    const allRows = props.rows.map((row) => {
      const statusByDay = uniqueDays.map((day) => {
        const dayEvents = row.events.filter((event) => event.timestamp.startsWith(day));

        if (dayEvents.length === 0) return SMART_CABLE_EVENT_TYPE.NO_LOGS;

        // Get the last event of the day to determine final status
        const lastEvent = dayEvents[dayEvents.length - 1];
        return lastEvent.eventType;
      });

      return [row.referenceId, row.name, row.attachmentRef || '-', ...statusByDay];
    });

    const getStatusIcon = (status: SMART_CABLE_EVENT_TYPE) => {
      switch (status) {
        case SMART_CABLE_EVENT_TYPE.BATTERY_OK:
          return circleCheck;
        case SMART_CABLE_EVENT_TYPE.OUT_OF_BATTERY:
          return circleAlert;
        case SMART_CABLE_EVENT_TYPE.DISCONNECTED:
          return disconnected;
        case SMART_CABLE_EVENT_TYPE.NO_LOGS:
        default:
          return circleDotted;
      }
    };

    autoTable(doc, {
      head: [headers],
      body: allRows,
      margin: {
        left: PDF_CONSTANTS.SIDE_MARGIN,
        right: PDF_CONSTANTS.SIDE_MARGIN,
        top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG + 5,
      },
      headStyles: {
        cellWidth: TIMESTAMP_CELL_WIDTH,
        valign: 'middle',
        halign: 'center',
        fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
        fontStyle: 'normal',
        fontSize: FONT_SIZE,
      },
      columnStyles: {
        0: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
        1: { cellWidth: TEXT_FIELDS_CELL_WIDTH + 3.5, overflow: 'ellipsize' },
        2: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: FONT_SIZE,
      },
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: [0, 1, 2],
      didDrawPage: (data) => {
        addHeader(doc, {
          title: 'Smart Cables Report',
          projectName: props.projectName,
          startDate: startDateString,
          endDate: endDateString,
          sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
        });

        // Add page number at the bottom right
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.BODY);
        doc.setTextColor(0, 0, 0);
        doc.text(
          `Page: ${data.pageNumber}`,
          pageWidth - PDF_CONSTANTS.SIDE_MARGIN - 20,
          pageHeight - PDF_CONSTANTS.SIDE_MARGIN,
          { align: 'right' }
        );

        // Add legend at the bottom left
        const legendItems: LegendItem[] = [
          { text: 'Battery OK', img: circleCheck },
          { text: 'Out of Battery', img: circleAlert },
          { text: 'Disconnected', img: disconnected },
          { text: 'No Logs', img: circleDotted },
          { text: 'Snoozed', img: squareGray },
        ];
        addLegend(doc, legendItems, pageHeight);
      },
      didDrawCell: (data) => {
        // Handle status icons
        if (data.column.index > 2 && data.cell.section === 'body') {
          const status = data.cell.raw as SMART_CABLE_EVENT_TYPE;
          const img = new Image();
          img.src = getStatusIcon(status);
          const x = data.cell.x + data.cell.width / 2 - FIXED_ICON_SIZE / 2;
          const y = data.cell.y + data.cell.height / 2 - FIXED_ICON_SIZE / 2;
          doc.addImage(img, x, y, FIXED_ICON_SIZE, FIXED_ICON_SIZE);
        }
        // Handle equipment column
        if (data.column.index === 2 && data.cell.section === 'body') {
          const attachmentRef = data.cell.raw as string;
          if (attachmentRef === '-') {
            // Center the dash
            doc.setFontSize(FONT_SIZE);
            doc.text('-', data.cell.x + data.cell.width / 2, data.cell.y + data.cell.height / 2, {
              align: 'center',
              baseline: 'middle',
            });
          } else {
            // Display equipment text
            doc.setFontSize(FONT_SIZE);
            doc.text(
              attachmentRef,
              data.cell.x + data.cell.width / 2,
              data.cell.y + data.cell.height / 2,
              {
                align: 'center',
                baseline: 'middle',
              }
            );
          }
        }
      },
      didParseCell: (data) => {
        // Clear text for status cells only (not equipment column anymore)
        if (data.cell.section === 'body' && data.column.index > 2) {
          data.cell.text = [];
        }
      },
    });

    // Add alarm events table on a new page
    addAlarmEventsTable({
      doc,
      rows: convertToDeviceRows(props.rows),
      projectName: props.projectName,
      startDate: props.reportStartDate,
      endDate: props.reportEndDate,
      imageMap: props.imageMap,
      reportType: ReportType.SmartCables,
      countryCode: props.countryCode,
    });

    // Add last page with contact information
    addLastPage(doc, props.contactPerson);

    doc.save(createDocumentName(props.projectName, startDateString, endDateString, 'SmartCables'));
    resolve();
  });
}
