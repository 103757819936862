import { useWindowEvent } from '@mantine/hooks';
import { useEffect } from 'react';

const useInfiniteScroll = (
  fetchNextPage: () => void,
  isLoading: boolean,
  isFetchingNextPage: boolean,
  hasNextPage: boolean
) => {
  const handleScroll = () => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
      const viewportHeight = window.innerHeight;
      const threshold = viewportHeight * 0.1; // 10% of viewport height
      if (scrollHeight - scrollTop <= clientHeight + threshold) {
        fetchNextPage();
      }
    }
  };

  useWindowEvent('scroll', handleScroll);

  useEffect(() => {
    // Check if the content fits within the viewport on initial load
    const checkIfContentFits = () => {
      const { scrollHeight, clientHeight } = document.documentElement;
      if (scrollHeight <= clientHeight && hasNextPage) {
        fetchNextPage();
      }
    };

    checkIfContentFits();
  }, [fetchNextPage, isLoading, isFetchingNextPage, hasNextPage]);
};

export default useInfiniteScroll;
