import { Stack } from '@mantine/core';
import { getPSAImage } from '../../../../utils/ImageHelpers';

type Props = {
  deviceModel: string;
};

export const PortableSiteAlarmMarker = ({ deviceModel }: Props) => {
  const psa = (
    <img
      src={getPSAImage(deviceModel)}
      alt=""
      style={{
        zIndex: '5',
        maxWidth: '30px',
        maxHeight: '30px',
        width: 'auto',
        height: 'auto',
      }}
    />
  );
  return (
    <>
      <Stack gap={0} align="center">
        {psa}
        {cone}
      </Stack>
    </>
  );
};

const cone = (
  <div
    style={{
      width: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '32px',
      clipPath: 'polygon(50% 0, 100% 100%, 0 100%)',
      position: 'absolute',
      top: '20px',
    }}
  >
    <div
      style={{
        flex: '1 1 100%',
        backgroundColor: '#868E96',
      }}
    ></div>
    <div
      style={{
        flex: '1 1 100%',
        backgroundColor: '#5B5B5B',
      }}
    ></div>
    <div
      style={{
        flex: '1 1 100%',
        backgroundColor: '#868E96',
      }}
    ></div>
    <div
      style={{
        flex: '1 1 100%',
        backgroundColor: '#5B5B5B',
      }}
    ></div>
    <div
      style={{
        flex: '1 1 100%',
        backgroundColor: '#868E96',
      }}
    ></div>
  </div>
);
