import { Grid, Stack, Text, Switch } from '@mantine/core';

export interface ProductSettings {
  intellitags: boolean;
  arrowboards: boolean;
}

interface Props {
  settings: ProductSettings;
  onUpdate: (settings: ProductSettings) => void;
}

export function ProductSetupStep({ settings, onUpdate }: Props) {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Stack>
          <Text size="sm" fw={500}>
            Product Settings
          </Text>

          <Switch
            label="Intellitags"
            description="Enable Intellitags for this project"
            checked={settings.intellitags}
            onChange={(event) =>
              onUpdate({
                ...settings,
                intellitags: event.currentTarget.checked,
              })
            }
          />

          <Switch
            label="Arrowboards"
            description="Enable Arrowboards for this project"
            checked={settings.arrowboards}
            onChange={(event) =>
              onUpdate({
                ...settings,
                arrowboards: event.currentTarget.checked,
              })
            }
          />
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
