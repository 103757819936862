import { Stack } from '@mantine/core';
import { Device, DeviceTypeNames } from '../../../../models/Device';
import { TwoColumnText } from '../../../../shared/TwoColumnText';

type Props = {
  device: Device;
};

export const DeviceInfo = ({ device }: Props) => {
  return (
    <Stack gap={'xs'}>
      <TwoColumnText
        width="100%"
        first={'Device type'}
        second={DeviceTypeNames[device.deviceType]}
      />
      <TwoColumnText width="100%" first={'Reference Id'} second={device.referenceId} />
      <TwoColumnText width="100%" first={'Battery'} second={`${device.batteryVoltage} (V)`} />
    </Stack>
  );
};
