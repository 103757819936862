import { Button, Divider, Group, Menu, Stack, Text } from '@mantine/core';
import { formatDateTimeString } from '../../../utils/utils.tsx';
import { IconDots, IconEdit, IconExternalLink } from '@tabler/icons-react';
import { Device } from '../../../models/Device';
import StatusGroup from '../../Status/StatusGroup';
import { getAttachmentText } from '../../../utils/signs.ts';
import { useState } from 'react';
import { EditDeviceInformationModal } from './../EditDeviceInformation/EditDeviceInformationModal.tsx';
import { DeviceImageWrapper } from '../../Map/InfoCard/DeviceInfo/DeviceImageWrapper.tsx';

type Props = {
  device: Device;
  isSnoozed?: boolean;
  countryCode: string;
  selected?: boolean;
  setSelectedDevice: (deviceId: string) => void;
  onNavigateToDevice: (deviceId: string) => void;
};

export function DeviceListItem({
  device,
  isSnoozed,
  countryCode,
  selected,
  setSelectedDevice,
  onNavigateToDevice,
}: Props) {
  const attachmentText = getAttachmentText(device, countryCode);
  const [assignToProjectModalOpened, setEditDeviceInformationModalOpened] = useState(false);

  return (
    <>
      <div
        onClick={() => setSelectedDevice(device.id)}
        style={{
          border: selected ? '1px solid black' : '1px solid transparent',
          borderRadius: '4px',
          padding: '2px',
          cursor: 'pointer',
        }}
      >
        <Group align="top" wrap="nowrap" fz="md" mb="xs" p={'md'} key={device.id} w={'100%'}>
          <DeviceImageWrapper baseDevice={device} height="fit-content" />
          <Stack w={'100%'} gap={0}>
            <Group gap={4}>
              <Text>
                <b>{attachmentText}</b>
                {attachmentText && device.currentName && ' - '}
                {device.currentName || ''}
              </Text>
            </Group>
            <Text opacity={0.5} fw={300} size="xs">
              {device.referenceId}
            </Text>
            <Group gap={'xs'} mt={'xs'}>
              <Text>
                <b>{device.batteryVoltage}</b> (V)
              </Text>
              <div style={{ flexGrow: 1 }}></div>
              <StatusGroup
                alarmStatus={device.alarmStatus}
                batteryStatus={device.batteryStatus}
                isSnoozed={isSnoozed}
              />
            </Group>
          </Stack>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                variant="transparent"
                c="black"
                p={0}
                size="xs"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the parent onClick from being triggered
                }}
              >
                <IconDots style={{ width: '3rem' }} /> {/* Horizontal dots */}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the parent onClick from being triggered
                  setEditDeviceInformationModalOpened(true);
                }}
              >
                <IconEdit size={12} style={{ marginRight: 8 }} /> {/* Pen icon for Edit */}
                Edit device
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the parent onClick from being triggered
                  onNavigateToDevice(device.id);
                }}
              >
                <IconExternalLink size={12} style={{ marginRight: 8 }} /> {/* External link icon */}
                Go to Device page
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Text c={'gray.6'} ta={'right'} pr={'md'} size="xs">
          Latest update: {formatDateTimeString(device.latestUpdate) ?? '-'}
        </Text>
        <EditDeviceInformationModal
          device={device}
          countryCode={countryCode}
          showModal={assignToProjectModalOpened}
          close={() => {
            setEditDeviceInformationModalOpened(false);
          }}
          onSubmit={() => {
            setEditDeviceInformationModalOpened(false);
          }}
        />
      </div>
      <Divider />
    </>
  );
}
