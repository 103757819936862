import { Button, Group, TextInput, Textarea } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { IPublicClientApplication } from '@azure/msal-browser';

import { api } from '../../services/api';
import { useMsal } from '@azure/msal-react';

interface BugReportProps {
  onSubmit: () => void;
}

export default function BugReport({ onSubmit }: BugReportProps) {
  const { instance } = useMsal();
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      urlpage: '',
      subject: '',
      message: '',
    },
    validate: {
      urlpage: isNotEmpty('Url or name of the page is required.'),
      subject: isNotEmpty('Subject is required.'),
      message: isNotEmpty('Message is required.'),
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        sendBugReport(instance, values.urlpage, values.subject, values.message);
        onSubmit();
      })}
    >
      <TextInput
        py="sm"
        label="URL / Page"
        description="Url or name of the page where the bug occurred."
        data-autofocus
        withAsterisk
        required={false}
        key={form.key('urlpage')}
        {...form.getInputProps('urlpage')}
      />
      <TextInput
        py="sm"
        label="Subject"
        description="Short description of the bug."
        withAsterisk
        required={false}
        key={form.key('subject')}
        {...form.getInputProps('subject')}
      />
      <Textarea
        py="sm"
        label="Message"
        description="Detailed description of the bug."
        withAsterisk
        required={false}
        key={form.key('message')}
        {...form.getInputProps('message')}
      />
      <Group justify="flex-end" gap="lg">
        <p
          style={{
            marginBottom: '0',
            color: 'var(--mantine-color-dimmed)',
            fontSize:
              'var(--input-description-size, calc(var(--mantine-font-size-sm) - calc(0.125rem * var(--mantine-scale))))',
          }}
        >
          Your bug report will be sent to the development team.
        </p>
        <Button type="submit" style={{ width: '25%', marginTop: '10px' }}>
          Submit
        </Button>
      </Group>
    </form>
  );
}

async function sendBugReport(
  instance: IPublicClientApplication,
  urlPage: string | null,
  subject: string | null,
  message: string | null
) {
  await api.post(instance, 'BugReport', {
    urlPage: urlPage,
    subject: subject,
    message: message,
  });
}
