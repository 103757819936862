import { Map, useApiIsLoaded, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { panTo } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import { Device } from '../../models/Device';
import { WorkZone } from '../../models/Project';
import useDevicesInArea from '../../data/hooks/Device/useDevicesInArea';
import { useMsal } from '@azure/msal-react';
import { Button, Group, Loader, Paper, Stack, Text } from '@mantine/core';
import { IconReload } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import useProjectsInArea from '../../data/hooks/Project/useProjectsInArea';
import { Mapbounds } from '../../shared/types';
import { InfoCardWrapper } from './InfoCard/InfoCardWrapper';
import useProjectDevices from '../../data/hooks/Device/useProjectDevicesHook';
import { MarkerWrapper } from './Markers/MarkerWrapper';
import { MapConstants } from './MapConstants';
import useDepots from '../../data/hooks/Depots/useDepots';

export const GoogleMapComponent = () => {
  const map = useMap();
  const { instance } = useMsal();
  const { state } = useLocation();
  const apiIsLoaded = useApiIsLoaded();
  const queryClient = useQueryClient();
  const [showProject, setShowProject] = useState<WorkZone | null>(null);
  const [showDevice, setShowDevice] = useState<Device | null>(null);
  const [showRefetchDevices, setShowRefetchDevices] = useState(false);

  const {
    data: projects,
    handleBoundsChange: change,
    isPending: projectsPending,
  } = useProjectsInArea(instance);

  const { data: devices, handleBoundsChange, isPending } = useDevicesInArea(instance);

  const projectIds = (projects ? projects : []).map((wz) => wz.id) || [];
  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds,
  });
  const projectIdToNameMap = (projects ? projects : []).reduce(function (
    map: { [projectId: string]: string },
    project: WorkZone
  ) {
    map[project.id] = project.name;
    return map as { [projectId: string]: string };
  }, {});

  const { depotsNameMap } = useDepots(instance);

  useEffect(() => {
    if (state && state.device && apiIsLoaded) {
      panTo(state.device.position.coordinates[1], state.device.position.coordinates[0], map);
      setShowDevice(state.device);
    }
  }, [state, map, apiIsLoaded]);

  const getMapBounds = (): Mapbounds | null => {
    const mapBounds = map?.getBounds();
    if (mapBounds) {
      return {
        northEastLatitude: mapBounds.getNorthEast().lat(),
        northEastLongitude: mapBounds.getNorthEast().lng(),
        southWestLatitude: mapBounds.getSouthWest().lat(),
        southWestLongitude: mapBounds.getSouthWest().lng(),
      };
    }
    return null;
  };

  const onClick = (device: Device | null, project: WorkZone | null): void => {
    if (device) {
      setShowProject(null);
      setShowDevice(device);
    } else {
      setShowDevice(null);
      setShowProject(project);
    }
  };

  map?.addListener('idle', () => {
    const zoom = map.getZoom();
    const viewportBounds = getMapBounds();
    if (zoom && viewportBounds) {
      if (zoom > MapConstants.DEVICE_ZOOM) {
        setShowRefetchDevices(true);
        handleBoundsChange(viewportBounds);
      } else {
        setShowRefetchDevices(false);
        change(viewportBounds);
      }
    }
  });

  return (
    <>
      <InfoCardWrapper
        device={showDevice}
        project={showProject}
        projectDevices={showProject ? projectDevices[showProject.id] : []}
        projectName={showDevice ? projectIdToNameMap[showDevice?.workZoneId] : '-'}
        depotName={depotsNameMap.get(showProject?.depotId)}
        onClose={() => {
          setShowDevice(null);
          setShowProject(null);
        }}
      />

      <div className="loadMoreBox">
        <Stack align="center" w={'fit-content'}>
          <Button
            size="xs"
            onClick={() =>
              queryClient.invalidateQueries({
                queryKey: [showRefetchDevices ? 'devices' : 'projects'],
              })
            }
            rightSection={<IconReload />}
            variant="default"
          >
            {showRefetchDevices ? 'Refetch devices in this area' : 'Refetch projects in this area'}
          </Button>
          {(isPending || projectsPending || isLoading) && (
            <Paper w={'fit-content'} shadow="md" bg={'white'} p={'sm'}>
              <Group>
                <Text size="sm">Loading...</Text>
                <Loader color="deepGreen.9" size={'xs'} />
              </Group>
            </Paper>
          )}
        </Stack>
      </div>

      <Map
        style={{ width: '100%', height: 'calc(100vh - 60px)' }}
        defaultCenter={{ lat: 59.32709, lng: 18.06788 }}
        defaultZoom={6}
        gestureHandling={'greedy'}
        disableDefaultUI={false}
        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
      >
        <MarkerWrapper
          projects={projects ?? []}
          projectDevices={projectDevices}
          onClick={(id, idType) => onClick(id, idType)}
          map={map}
          devices={devices ?? []}
          selectedDevice={showDevice?.id}
        />
      </Map>
    </>
  );
};
