import { createTheme } from '@mantine/core';

export default createTheme({
  primaryColor: 'primaryGreen',
  black: '#1A1A1A',
  fontFamily: "'Söhne Web', 'Söhne', system-ui, -apple-system, sans-serif",

  /* Typography Scale */
  fontSizes: {
    xs: '12px', // Extra small
    sm: '14px', // Small
    md: '16px', // Body
    lg: '20px', // H5
    xl: '24px', // H4
    xxl: '32px', // H3
    xxxl: '40px', // H2
    display: '64px', // Display
  },

  lineHeights: {
    xs: '16px', // Extra small
    sm: '20px', // Small
    md: '24px', // Body
    lg: '24px', // H5
    xl: '32px', // H4
    xxl: '40px', // H3
    xxxl: '48px', // H2
    display: '80px', // Display
  },

  headings: {
    fontFamily: "'Söhne Web', 'Söhne', system-ui, -apple-system, sans-serif",
    sizes: {
      h1: { fontSize: '64px', lineHeight: '80px' }, // Display
      h2: { fontSize: '40px', lineHeight: '48px' },
      h3: { fontSize: '32px', lineHeight: '40px' },
      h4: { fontSize: '24px', lineHeight: '32px' },
      h5: { fontSize: '20px', lineHeight: '24px' },
    },
  },

  colors: {
    primaryGreen: [
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#398582',
      '#2E6B69',
      '#285958',
      '#fff',
      '#fff',
    ],
    primaryGreenLight: [
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
    ],
    deepGreen: [
      '#e8ebeb',
      '#d1d6d6',
      '#b9c2c2',
      '#a2adad',
      '#8b9999',
      '#748484',
      '#5d7070',
      '#455b5b',
      '#2e4747',
      '#173232',
    ],
    mainYellow: [
      '#fffce0',
      '#fff8ca',
      '#fff099',
      '#ffe862',
      '#ffe136',
      '#ffdc18',
      '#ffda00',
      '#e3c100',
      '#c9ab00',
      '#ae9300',
    ],
    sand: [
      '#fffaeb',
      '#fffbed',
      '#fffbef',
      '#fffcf1',
      '#fffcf3',
      '#fffdf5',
      '#fffdf7',
      '#fffef9',
      '#fffefb',
      '#fffffd',
    ],
    sandBg: [
      '#f8f6f1',
      '#edebe4',
      '#dbd4c5',
      '#c8bda1',
      '#b9a984',
      '#af9c70',
      '#ab9664',
      '#958254',
      '#857348',
      '#74633a',
    ],
    greyText: [
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
    ],
    dimmedText: [
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
    ],
    lightGrey: [
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
    ],
    successGreen: [
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
    ],
    warningYellow: [
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
    ],
    dangerRed: [
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
      '#e53400',
    ],
  },
  spacing: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
});
