import { Box, InputWrapper, Stack, Text, Input } from '@mantine/core';
import { Device, DeviceTypeNames } from '../../../../models/Device';
import { DeviceDefaultImage } from '../../../Map/InfoCard/DeviceInfo/DeviceDefaultImage';
import { getDeviceInfoDescription, getDeviceInfoLabel } from '../../../../copy';
import { WorkZone } from '../../../../models/Project';
import { useEffect } from 'react';

type Props = {
  device: Device;
  project: WorkZone;
  onChange: (value: string) => void;
};

export const GenericDeviceInput = ({ device, project, onChange }: Props) => {
  useEffect(() => onChange(''));
  return (
    <Stack align="center" gap={'lg'}>
      <Box w={'100%'}>
        <Text size="sm" fw={600}>
          Device Type
        </Text>
        <Text fw={300}>{DeviceTypeNames[device.deviceType]}</Text>
        <Text size="xs" c={'gray.6'}>
          {device.referenceId}
        </Text>
      </Box>
      <DeviceDefaultImage deviceType={device.deviceType} size="30%" />
      <InputWrapper
        w={'100%'}
        label={getDeviceInfoLabel(project.countryCode ?? '')}
        description={getDeviceInfoDescription(project.countryCode ?? '')}
      >
        <Input onChange={(event) => onChange(event.currentTarget.value)} />
      </InputWrapper>
    </Stack>
  );
};
