import { Divider, Group, Image, Stack, Text } from '@mantine/core';
import { getImageUrlForSign } from '../../../utils/ImageHelpers';

type Props = {
  label: string;
  text: string;
  signCode?: string;
};

export default function Row({ label, text, signCode }: Props) {
  return (
    <Stack gap="xs">
      <Text c="#575757" size="xs">
        {label}
      </Text>

      <Group>
        <Text fw={600}>{text}</Text>
        {signCode && <Image w={30} src={getImageUrlForSign(signCode)} />}
      </Group>

      <Divider />
    </Stack>
  );
}
