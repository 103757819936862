import { Intellitag } from '../../../../models/Device';
import { EquipmentTypeNames } from '../../../../models/enums/DeviceEnums';
import { TwoColumnText } from '../../../../shared/TwoColumnText';

export const IntellitagInfo = (props: Intellitag) => {
  return (
    <>
      <TwoColumnText
        width="100%"
        first="Equipment Type"
        second={props.currentEquipmentType ? EquipmentTypeNames[props.currentEquipmentType] : '-'}
      />
      <TwoColumnText width="100%" first="Attachment" second={props.attachmentRef} />
    </>
  );
};
