import { IPublicClientApplication } from '@azure/msal-browser';
import { useInfiniteQuery, InfiniteData, QueryKey } from '@tanstack/react-query';
import { useState } from 'react';
import { DeviceLog } from '../../../models/DeviceLog';
import { useDebouncedCallback } from '@mantine/hooks';
import useInfiniteScroll from '../useInfiniteScroll';
import { APIResponseList, buildQueryString, prepareHeaders } from '../../api/apiHelpers';

const path = `${import.meta.env.VITE_API_BASE_PATH}/deviceLog`;

interface Params {
  deviceId?: string;
  instance: IPublicClientApplication;
}

const useInfDeviceLogList = ({ instance, deviceId }: Params) => {
  const [search, setSearch] = useState('');
  const [serachTerm, setSerachTerm] = useState('');
  const pageSize = 20;

  const { data, isLoading, isFetching, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      APIResponseList<DeviceLog>,
      Error,
      InfiniteData<APIResponseList<DeviceLog>>,
      QueryKey,
      number
    >({
      queryKey: ['device-logs', { deviceId }],
      queryFn: async ({ pageParam }) => {
        const queryString = buildQueryString({
          searchTerm: serachTerm,
          pageSize,
          pageNumber: pageParam,
          deviceId,
        });
        const response = await fetch(`${path}${queryString}`, {
          mode: 'cors',
          method: 'GET',
          headers: await prepareHeaders(instance),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return {
          items: data.data,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          totalCount: data.totalCount,
        };
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        const nextPage =
          lastPage.pageNumber < lastPage.totalCount ? lastPage.pageNumber + 1 : undefined;
        return nextPage;
      },
    });

  useInfiniteScroll(fetchNextPage, isLoading, isFetchingNextPage, hasNextPage);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    handleSearch(event.target.value);
  };

  const handleSearch = useDebouncedCallback(async (search: string) => {
    setSerachTerm(search);
  }, 500);

  const deviceLogs = data?.pages.flatMap((page) => page.items) ?? [];

  return {
    deviceLogs,
    isLoading,
    isFetching,
    isError,
    search,
    handleSearchChange,
    fetchNextPage,
    setSearch,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useInfDeviceLogList;
