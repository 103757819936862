import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import postData from '../../api/postData';

interface Params {
  id: string;
}

const path = `/workzone/close`;

const useCloseProject = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: (params: Params) => {
      return postData<Params>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error assigning device to project`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
    },
  });

  return {
    mutate,
    mutateAsync,
    isPending,
    isError,
    isSuccess,
  };
};

export default useCloseProject;
