import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { prepareHeaders } from '../../api/apiHelpers';
import { Depot } from '../../../shared/types';

const path = `/depot`;
const baseUrl = import.meta.env.VITE_API_BASE_PATH;

const useDepots = (instance: IPublicClientApplication) => {
  const { data, isLoading, isError } = useQuery<Depot[]>({
    queryKey: ['depots'],
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await fetch(`${baseUrl}${path}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    },
  });

  const nameMap = new Map();
  data?.map((obj) => nameMap.set(obj.id, obj.name));

  return {
    depots: data ? data : [],
    depotsNameMap: nameMap,
    isLoading,
    isError,
  };
};

export default useDepots;
