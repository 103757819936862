import { DeviceType } from '../../../models/Device';
import { DeviceDefaultImage } from '../InfoCard/DeviceInfo/DeviceDefaultImage';

type Props = {
  deviceType: DeviceType;
};

export const GenericDeviceMarker = ({ deviceType }: Props) => {
  return (
    <>
      <div
        style={{
          borderRadius: '4px',
          zIndex: '5',
          position: 'relative',
          width: '36px',
          height: '44px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #000',
          color: '#000',
        }}
      >
        <DeviceDefaultImage deviceType={deviceType} />
      </div>
      <div
        style={{
          width: '5px',
          height: '20px',
          position: 'absolute',
          top: '46px',
          backgroundColor: 'rgb(60, 60, 60)',
          borderRadius: '2px',
        }}
      ></div>
    </>
  );
};
