import { Device, DeviceType } from '../../../../models/Device';
import { ArrowboardInfo } from './ArrowBoardInfo';
import { IntellitagInfo } from './IntellitagInfo';

interface Props {
  device: Device;
}

export const DeviceSpecifcInfoWrapper = ({ device }: Props) => {
  if (device.deviceType == DeviceType.ArrowBoard)
    return <ArrowboardInfo arrowDirection={device.arrowDirection} />;
  if (device.deviceType == DeviceType.Intellitag)
    return (
      <IntellitagInfo
        attachmentRef={device.attachmentRef}
        currentEquipmentType={device.currentEquipmentType}
        inPowerSavingMode={device.inPowerSavingMode}
      />
    );
};
