import { Image, Container, Title, Text, Button, SimpleGrid } from '@mantine/core';
import classes from '../styles/ErrorPage.module.css';
import image_404 from '../assets/404.svg';

export default function ErrorPage() {
  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        <Image src={image_404} className={classes.mobileImage} />
        <div className={classes.textBox}>
          <Title order={3} className={classes.title}>
            Something is not right...
          </Title>
          <Text c="dimmed" size="lg">
            An error has occured on the page you are trying to open. Please contact support.
          </Text>
          <a href="/home">
            <Button variant="outline" size="md" mt="xl" className={classes.control}>
              Get back to home page
            </Button>
          </a>
        </div>
        <Image mt={'xl'} src={image_404} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}
