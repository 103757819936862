import { Group, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

import styles from './List.module.css';
import { WorkZone } from '../../../../models/Project';

type Props = {
  onSelect: (project: WorkZone) => void;
  searchString: string;
  projects: WorkZone[];
  projectName?: string;
};

export default function ProjectList({ onSelect, searchString, projects, projectName }: Props) {
  return projects
    .filter((project) =>
      project.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    )
    .map((item) => {
      return (
        <Group key={item.id} onClick={() => onSelect(item)} className={styles.listItem}>
          {projectName === item.name && <IconCheck color={'#748484'} />}
          <Text
            c={projectName === item.name ? '#2e4747' : '#455b5b'}
            fw={projectName === item.name ? 700 : 400}
          >
            {item.name}
          </Text>
        </Group>
      );
    });
}
