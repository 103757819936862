import { useContext, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, Flex, Group, Loader, Space, Stack, Text } from '@mantine/core';
import { IconZzz, IconScan } from '@tabler/icons-react';

import { PWAContext } from '../../providers/PWAProvider';
import HalfPanel from '../../components/HalfPanel';
import Guide from '../../components/Guide';
import useSnoozeProject from '../../../data/hooks/Project/useSnoozeProjectHook';
import useUnSnoozeProject from '../../../data/hooks/Project/useUnSnoozeProjectHook';
import useProjectDevices from '../../../data/hooks/Device/useProjectDevicesHook';
import useProject from '../../../data/hooks/Project/useProjectHook';
import PwaPageHeading from '../../components/PwaPageHeading';
import MainContent from './components/MainContent';
import SnoozeBadge from '../../../components/Badge/SnoozeBadge';
import { formatDateTime } from '../../../utils/utils';

const SNOOZE_TIME_UNITS = [1, 2, 4, 8, 12];

export const PwaProjectOverviewPage = () => {
  const { instance } = useMsal();
  const { setMessage } = useContext(PWAContext);
  const [showSnoozeConfirm, setShowSnoozeConfirm] = useState(false);
  const [snoozeTime, setSnoozeTime] = useState<number | null>(null);
  const { projectId } = useParams<{ projectId: string }>();

  const { mutateAsync: snoozeProject } = useSnoozeProject(instance);
  const { mutateAsync: unsnoozeProject } = useUnSnoozeProject(instance);
  const navigate = useNavigate();
  const { project } = useProject({ instance, workZoneId: projectId ?? '' });

  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ? projectId : ''],
  });

  const addHours = (date: Date, hours: number) => {
    const hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
  };

  const handleSnooze = async () => {
    if (!project || !project.id || !snoozeTime) return;
    snoozeProject({
      id: project?.id,
      snoozedUntil: addHours(new Date(), snoozeTime),
    }).catch(() =>
      setMessage({
        title: 'Failed to snooze alarms',
        text: 'Something went wrong when trying to snooze the projects alarms',
        type: 'error',
      })
    );
  };

  const handleUnsnooze = async () => {
    if (project && project.id)
      unsnoozeProject({ id: project.id }).catch(() =>
        setMessage({
          title: 'Failed to unsnooze project',
          text: 'Something went wrong when unsnoozing the project',
          type: 'error',
        })
      );
  };

  const handleSnoozeProject = () => {
    if (!project || !project.id) {
      navigate('/pwa/projects');
      return;
    }

    setShowSnoozeConfirm(false);
    setSnoozeTime(null);

    project.isSnoozed ? handleUnsnooze() : handleSnooze();
  };

  const getHandleSnoozePanel = () =>
    project?.isSnoozed ? (
      <Text>Are you sure you want to reactivate alarms for this project?</Text>
    ) : (
      <>
        <Text>Select duration of time</Text>
        <Group flex={1} gap={10} mt="sm" w="100%">
          {SNOOZE_TIME_UNITS.map((time) => (
            <Button
              flex={1}
              miw={'fit-content'}
              key={time}
              onClick={() => setSnoozeTime(time)}
              size="lg"
              variant={snoozeTime === time ? 'filled' : 'default'}
            >
              {time}h
            </Button>
          ))}
        </Group>
      </>
    );

  return (
    <>
      <Guide />

      <Stack flex={1} style={{ overflow: 'auto' }} justify="space-between" mb="lg">
        <Stack>
          <Group gap="sm" justify="space-between">
            <PwaPageHeading label="Project">
              {project?.name || 'No project selected'}
            </PwaPageHeading>

            <Link to="/pwa/projects" state={{ prevPath: 'overview' }}>
              <Button size="md" variant="default">
                Change
              </Button>
            </Link>
          </Group>

          {project?.isSnoozed && (
            <Group wrap="nowrap" gap={10}>
              <SnoozeBadge size="lg" />
              <Text c={'gray.7'} size="sm">{`Until ${formatDateTime(project?.snoozedUntil)}`}</Text>
            </Group>
          )}

          {isLoading ? (
            <Flex flex={1} align="center" justify="center">
              <Space h={200} />
              <Loader />
            </Flex>
          ) : (
            project &&
            projectId && (
              <MainContent projectId={projectId} deviceList={projectDevices[project.id]} />
            )
          )}
        </Stack>

        <Stack gap="sm">
          <Button
            onClick={() => setShowSnoozeConfirm(true)}
            size="lg"
            variant="default"
            leftSection={<IconZzz />}
            disabled={!project || projectDevices[project.id].length === 0}
          >
            {project?.isSnoozed ? 'Unsnooze alarms' : 'Snooze alarms'}
          </Button>

          <Link to="/pwa/projects/scanner">
            <Button
              size="lg"
              variant="filled"
              w="100%"
              leftSection={<IconScan />}
              disabled={!project}
            >
              Add/Remove device
            </Button>
          </Link>
        </Stack>
      </Stack>

      <HalfPanel
        confirmDisabled={!project?.isSnoozed && !snoozeTime}
        label={project?.isSnoozed ? '' : 'Silence all alarms for a set period of time'}
        open={showSnoozeConfirm}
        onClose={() => {
          setShowSnoozeConfirm(!showSnoozeConfirm);
          setSnoozeTime(null);
        }}
        onConfirm={handleSnoozeProject}
        title={project?.isSnoozed ? 'Unsnooze project' : 'Snooze alarms'}
        confirmLabel={project?.isSnoozed ? 'Confirm' : 'Activate'}
      >
        {getHandleSnoozePanel()}
      </HalfPanel>
    </>
  );
};
