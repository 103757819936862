import { Button, Group, Stack, Text } from '@mantine/core';
import { formatDateTime } from '../../../utils/utils';
import { SnoozeBadge } from '../../Badge';
import { WorkZone } from '../../../models/Project';
import { IconBell } from '@tabler/icons-react';

const UnSnoozeButton = (props: {
  project: WorkZone;
  unsnoozeProject: (params: { id: string }) => void;
}) => {
  return (
    <Stack>
      <Button
        w={220}
        leftSection={<IconBell size={18} stroke={1.5} />}
        onClick={() => {
          props.unsnoozeProject({ id: props.project.id });
        }}
      >
        Resume Alarms
      </Button>
      <Group>
        <SnoozeBadge size={'md'} />
        <Text size="xs" c={'gray.6'}>
          {`Snoozed until ${formatDateTime(props.project.snoozedUntil)}`}
        </Text>
      </Group>
    </Stack>
  );
};

export default UnSnoozeButton;
