export interface Map {
  [key: string]: string | undefined;
}

export const SEsigns: Map = {
  A20: 'SE_A20',
  A21: 'SE_A21',
  A5_1: 'SE_A5-1',
  A5_2: 'SE_A5-2',
  A5_3: 'SE_A5-3',
  A8: 'SE_A8',
  A9: 'SE_A9',
  A10: 'SE_A10',
  A11: 'SE_A11',
  A13: 'SE_A13',
  A14: 'SE_A14',
  A15: 'SE_A15',
  A16: 'SE_A16',
  A22: 'SE_A22',
  A25: 'SE_A25',
  A27: 'SE_A27',
  A28: 'SE_A28',
  A29: 'SE_A29',
  A34: 'SE_A34',
  A40: 'SE_A40',
  B1: 'SE_B1',
  B2: 'SE_B2',
  B3_1: 'SE_B3-1',
  B3_2: 'SE_B3-2',
  B4: 'SE_B4',
  B5: 'SE_B5',
  B6: 'SE_B6',
  B7: 'SE_B7',
  C1: 'SE_C1',
  C2: 'SE_C2',
  C3: 'SE_C3',
  C4: 'SE_C4',
  C7: 'SE_C7',
  C10: 'SE_C10',
  C15: 'SE_C15',
  C16: 'SE_C16',
  C17: 'SE_C17',
  C20: 'SE_C20',
  C25_1: 'SE_C25-1',
  C25_2: 'SE_C25-2',
  C26: 'SE_C26',
  C27: 'SE_C27',
  C28: 'SE_C28',
  C31_3: 'SE_C31-3',
  C31_4: 'SE_C31-4',
  C31_5: 'SE_C31-5',
  C31_6: 'SE_C31-6',
  C31_7: 'SE_C31-7',
  C31_8: 'SE_C31-8',
  C31_9: 'SE_C31-9',
  C31_10: 'SE_C31-10',
  C31_11: 'SE_C31-11',
  C31_12: 'SE_C31-12',
  C34: 'SE_C34',
  C35: 'SE_C35',
  C39: 'SE_C39',
  C40_1: 'SE_C40-1',
  C42: 'SE_C42',
  C43: 'SE_C43',
  D1_1: 'SE_D1-1',
  D2_1: 'SE_D2-1',
  D2_3: 'SE_D2-3',
  D3: 'SE_D3',
  D4: 'SE_D4',
  D5: 'SE_D5',
  D6: 'SE_D6',
  D7_1: 'SE_D7-1',
  D10: 'SE_D10',
  D11: 'SE_D11',
  E11_2: 'SE_E11-2',
  E11_3: 'SE_E11-3',
  E11_4: 'SE_E11-4',
  E11_5: 'SE_E11-5',
  E11_6: 'SE_E11-6',
  E11_7: 'SE_E11-7',
  E11_8: 'SE_E11-8',
  E11_9: 'SE_E11-9',
  E12: 'SE_E12',
  E15: 'SE_E15',
  E16: 'SE_E16',
  E17_1: 'SE_E17-1',
  E19: 'SE_E19',
  E23: 'SE_E23',
  F5: 'SE_F5',
  F23: 'SE_F23',
  F24_1: 'SE_F24-1',
  F25_1: 'SE_F25-1',
  F26_1: 'SE_F26-1',
  F26_2: 'SE_F26-2',
  J2: 'SE_J2',
  T1: 'SE_T1',
  T2: 'SE_T2',
  T6: 'SE_T6',
  T7: 'SE_T7',
  T11_3: 'SE_T11-3',
  T22: 'SE_T22',
  T22_1: 'SE_T22-1',
  X1: 'SE_X1',
  X2: 'SE_X2',
  X3: 'SE_X3',
};

export const CATEGORIES_SE: Map = {
  A: 'A. Varningsmärken',
  B: 'B. Väjningspliktsmärken',
  C: 'C. Förbudsmärken',
  D: 'D. Påbudsmärken',
  E: 'E. Anvisningsmärken',
  F: 'F. Lokaliseringsmärken',
  J: 'J. Upplysningsmärken',
  T: 'T. Tilläggstavlor',
  X: 'X. Andra anordningar',
};

export const UKsigns: Map = {
  UNDEFINED: 'UK_UNDEFINED',
};

export const NOsigns: Map = {
  '106': 'NO_106',
  '108': 'NO_108',
  '109': 'NO_109',
  '110': 'NO_110_0',
  '112': 'NO_112',
  '116': 'NO_116',
  '132': 'NO_132',
  '140': 'NO_140_0',
  '148': 'NO_148',
  '149': 'NO_149',
  '156': 'NO_156',
  '302': 'NO_302_0',
  '306_0': 'NO_306_0',
  '306_1': 'NO_306_1',
  '314': 'NO_314_0',
  '330_1': 'NO_330_1',
  '330_2': 'NO_330_2',
  '362_30': 'NO_362_30',
  '362_40': 'NO_362_40',
  '362_50': 'NO_362_50',
  '362_60': 'NO_362_60',
  '362_70': 'NO_362_70',
  '362_80': 'NO_362_80',
  '362_90': 'NO_362_90',
  '362_30_a': 'NO_362_30_a',
  '362_40_a': 'NO_362_40_a',
  '362_50_a': 'NO_362_50_a',
  '362_60_a': 'NO_362_60_a',
  '362_70_a': 'NO_362_70_a',
  '364_30': 'NO_364_30',
  '364_40': 'NO_364_40',
  '364_50': 'NO_364_50',
  '364_60': 'NO_364_60',
  '364_70': 'NO_364_70',
  '370': 'NO_370_0',
  '372': 'NO_372_0',
  '376_1': 'NO_376_1',
  '402_1': 'NO_402_1',
  '402_2': 'NO_402_2',
  '402_3': 'NO_402_3',
  '402_4': 'NO_402_4',
  '402_5': 'NO_402_5',
  '402_6': 'NO_402_6',
  '402_7': 'NO_402_7',
  '402_8': 'NO_402_8',
  '404_1': 'NO_404_1',
  '404_2': 'NO_404_2',
  '406': 'NO_406_0',
  '526_1': 'NO_526_1',
  '522': 'NO_522_0',
  '522_a': 'NO_522_a',
  '560_infotavle': 'NO_560_infotavle',
  '713': 'NO_713',
  '808': 'NO_808',
  '902_ah': 'NO_902_ah',
  '902_av': 'NO_902_av',
  '906_ah': 'NO_906_ah',
  '906_av': 'NO_906_av',
  Troll: 'NO_Troll',
};

export const CATEGORIES_NO: Map = {
  1: 'Fareskilt',
  3: 'Forbudsskilt',
  4: 'Paabudsskilt',
  5: 'Opplysningsskilt',
  7: 'Vegvisningsskilt',
  8: 'Underskilt',
  9: 'Markeringsskilt',
};

export const FIsigns: Map = {
  A3_1: 'FI_a3_1',
  A4: 'FI_a4',
  A5: 'FI_a5',
  A9: 'FI_a9',
  A10: 'FI_a10',
  A11: 'FI_a11',
  A12: 'FI_a12',
  A13: 'FI_a13',
  A14: 'FI_a14',
  A15: 'FI_a15',
  A16: 'FI_a16',
  A17: 'FI_a17',
  A18: 'FI_a18',
  A21: 'FI_a21',
  A22_1: 'FI_a22_1',
  A23: 'FI_a23',
  A24: 'FI_a24',
  A25: 'FI_a25',
  A30: 'FI_a30',
  A33: 'FI_a33',
  B1: 'FI_b1',
  B2: 'FI_b2',
  B3: 'FI_b3',
  B4: 'FI_b4',
  B5: 'FI_b5',
  B6: 'FI_b6',
  C1: 'FI_c1',
  C2: 'FI_c2',
  C3: 'FI_c3',
  C4: 'FI_c4',
  C10: 'FI_c10',
  C11: 'FI_c11',
  C12: 'FI_c12',
  C13: 'FI_c13',
  C14: 'FI_c14',
  C15: 'FI_c15',
  C17: 'FI_c17',
  C18: 'FI_c18',
  C19: 'FI_c19',
  C20: 'FI_c20',
  C21: 'FI_c21',
  C22: 'FI_c22',
  C23: 'FI_c23',
  C24: 'FI_c24',
  C27: 'FI_c27',
  C28: 'FI_c28',
  C29: 'FI_c29',
  C30: 'FI_c30',
  C32: 'FI_c32',
  C33: 'FI_c33',
  C34: 'FI_c34',
  C35: 'FI_c35',
  C37: 'FI_c37',
  C38: 'FI_c38',
  C39: 'FI_c39',
  C40: 'FI_c40',
  C41: 'FI_c41',
  C42: 'FI_c42',
  C44_1: 'FI_c44_1',
  D1_1: 'FI_d1_1',
  D2: 'FI_d2',
  D3_1: 'FI_d3_1',
  D4: 'FI_d4',
  D5: 'FI_d5',
  D6: 'FI_d6',
  D7_1: 'FI_d7_1',
  E1: 'FI_e1',
  E2: 'FI_e2',
  E4_1: 'FI_e4_1',
  E6: 'FI_e6',
  E8: 'FI_e8',
  E14_2: 'FI_e14_2',
  E22: 'FI_e22',
  E23: 'FI_e23',
  E24: 'FI_e24',
  E25: 'FI_e25',
  E26: 'FI_e26',
  E27: 'FI_e27',
  E30: 'FI_e30',
  F5: 'FI_f5',
  F6: 'FI_f6',
  F7_2: 'FI_f7_2',
  F8_1: 'FI_f8_1',
  F8_2: 'FI_f8_2',
  F15: 'FI_f15',
  F24_1: 'FI_f24_1',
  F50: 'FI_f50',
  F52: 'FI_f52',
};

export const CATEGORIES_FI: Map = {
  A: 'Varoitusmerkit',
  B: 'Etuajo-oikeus ja väistämismerkit',
  C: 'Kielto- ja rajoitusmerkit',
  D: 'Määräysmerkit',
  E: 'Sääntömerkit',
  F: 'Opastusmerkit',
};

export const DEsigns: Map = {
  VZ_101: 'DE_VZ-101',
  'VZ_101-21': 'DE_VZ-101-21',
  'VZ_101-25': 'DE_VZ-101-25',
  'VZ_101-52': 'DE_VZ-101-52',
  VZ_102: 'DE_VZ-102',
  'VZ_103-10': 'DE_VZ-103-10',
  'VZ_103-20': 'DE_VZ-103-20',
  'VZ_105-10': 'DE_VZ-105-10',
  'VZ_105-20': 'DE_VZ-105-20',
  VZ_112: 'DE_VZ-112',
  VZ_114: 'DE_VZ-114',
  VZ_120: 'DE_VZ-120',
  'VZ_121-10': 'DE_VZ-121-10',
  'VZ_121-20': 'DE_VZ-121-20',
  VZ_123: 'DE_VZ-123',
  VZ_124: 'DE_VZ-124',
  VZ_125: 'DE_VZ-125',
  VZ_131: 'DE_VZ-131',
  'VZ_133-10': 'DE_VZ-133-10',
  'VZ_133-20': 'DE_VZ-133-20',
  'VZ_138-10': 'DE_VZ-138-10',
  'VZ_138-20': 'DE_VZ-138-20',
  VZ_205: 'DE_VZ-205',
  VZ_208: 'DE_VZ-208',
  VZ_209: 'DE_VZ-209',
  'VZ_209-10': 'DE_VZ-209-10',
  'VZ_209-30': 'DE_VZ-209-30',
  VZ_211: 'DE_VZ-211',
  'VZ_211-10': 'DE_VZ-211-10',
  VZ_214: 'DE_VZ-214',
  'VZ_214-10': 'DE_VZ-214-10',
  'VZ_214-30': 'DE_VZ-214-30',
  VZ_215: 'DE_VZ-215',
  VZ_222: 'DE_VZ-222',
  'VZ_222-10': 'DE_VZ-222-10',
  VZ_224: 'DE_VZ-224',
  VZ_229: 'DE_VZ-229',
  VZ_237: 'DE_VZ-237',
  VZ_238: 'DE_VZ-238',
  VZ_239: 'DE_VZ-239',
  VZ_240: 'DE_VZ-240',
  VZ_241: 'DE_VZ-241',
  'VZ_241-30': 'DE_VZ-241-30',
  'VZ_241-31': 'DE_VZ-241-31',
  'VZ_242.2': 'DE_VZ-242.2',
  'VZ_244.1': 'DE_VZ-244.1',
  'VZ_244.3': 'DE_VZ-244.3',
  'VZ_244.4': 'DE_VZ-244.4',
  VZ_250: 'DE_VZ-250',
  VZ_259: 'DE_VZ-259',
  VZ_260: 'DE_VZ-260',
  VZ_261: 'DE_VZ-261',
  'VZ_264-2': 'DE_VZ-264-2',
  VZ_267: 'DE_VZ-267',
  'VZ_274-5': 'DE_VZ-274-5',
  'VZ_274-10': 'DE_VZ-274-10',
  'VZ_274-20': 'DE_VZ-274-20',
  'VZ_274-30': 'DE_VZ-274-30',
  'VZ_274-40': 'DE_VZ-274-40',
  'VZ_274-50': 'DE_VZ-274-50',
  'VZ_274-60': 'DE_VZ-274-60',
  'VZ_274-70': 'DE_VZ-274-70',
  'VZ_274-80': 'DE_VZ-274-80',
  'VZ_274-90': 'DE_VZ-274-90',
  'VZ_274-100': 'DE_VZ-274-100',
  'VZ_274-110': 'DE_VZ-274-110',
  'VZ_274-120': 'DE_VZ-274-120',
  'VZ_274-130': 'DE_VZ-274-130',
  VZ_276: 'DE_VZ-276',
  VZ_277: 'DE_VZ-277',
  'VZ_277.1': 'DE_VZ-277.1',
  'VZ_278-5': 'DE_VZ-278-5',
  'VZ_278-10': 'DE_VZ-278-10',
  'VZ_278-20': 'DE_VZ-278-20',
  'VZ_278-30': 'DE_VZ-278-30',
  'VZ_278-40': 'DE_VZ-278-40',
  'VZ_278-50': 'DE_VZ-278-50',
  'VZ_278-60': 'DE_VZ-278-60',
  'VZ_278-70': 'DE_VZ-278-70',
  'VZ_278-80': 'DE_VZ-278-80',
  'VZ_278-90': 'DE_VZ-278-90',
  'VZ_278-100': 'DE_VZ-278-100',
  'VZ_278-110': 'DE_VZ-278-110',
  'VZ_278-120': 'DE_VZ-278-120',
  'VZ_278-130': 'DE_VZ-278-130',
  VZ_280: 'DE_VZ-280',
  VZ_281: 'DE_VZ-281',
  'VZ_281.1': 'DE_VZ-281.1',
  VZ_282: 'DE_VZ-282',
  VZ_283: 'DE_VZ-283',
  VZ_286: 'DE_VZ-286',
  VZ_301: 'DE_VZ-301',
  VZ_306: 'DE_VZ-306',
  VZ_307: 'DE_VZ-307',
  VZ_308: 'DE_VZ-308',
  VZ_327: 'DE_VZ-327',
  VZ_328: 'DE_VZ-328',
  'VZ_330.1': 'DE_VZ-330.1',
  'VZ_330.2': 'DE_VZ-330.2',
  'VZ_331.1': 'DE_VZ-331.1',
  'VZ_331.2': 'DE_VZ-331.2',
  'VZ_350-10': 'DE_VZ-350-10',
  'VZ_350-20': 'DE_VZ-350-20',
  VZ_357: 'DE_VZ-357',
  'VZ_357-50': 'DE_VZ-357-50',
  'VZ_357-51': 'DE_VZ-357-51',
  'VZ_357-52': 'DE_VZ-357-52',
  'VZ_455.2': 'DE_VZ-455.2',
  'VZ_455.1-10': 'DE_VZ-455.1-10',
  'VZ_455.1-11': 'DE_VZ-455.1-11',
  'VZ_455.1-12': 'DE_VZ-455.1-12',
  'VZ_455.1-13': 'DE_VZ-455.1-13',
  'VZ_455.1-20': 'DE_VZ-455.1-20',
  'VZ_455.1-21': 'DE_VZ-455.1-21',
  'VZ_455.1-22': 'DE_VZ-455.1-22',
  'VZ_455.1-23': 'DE_VZ-455.1-23',
  'VZ_455.1-30': 'DE_VZ-455.1-30',
  'VZ_455.1-31': 'DE_VZ-455.1-31',
  'VZ_455.1-50': 'DE_VZ-455.1-50',
  'VZ_457.1': 'DE_VZ-457.1',
  'VZ_457.2': 'DE_VZ-457.2',
  VZ_721: 'DE_VZ-721',
  Plantafel: 'DE_Plantafel',
  'ZZ_1000-10': 'DE_ZZ-1000-10',
  'ZZ_1000-11': 'DE_ZZ-1000-11',
  'ZZ_1000-12': 'DE_ZZ-1000-12',
  'ZZ_1000-20': 'DE_ZZ-1000-20',
  'ZZ_1000-21': 'DE_ZZ-1000-21',
  'ZZ_1000-22': 'DE_ZZ-1000-22',
  'ZZ_1000-30': 'DE_ZZ-1000-30',
  'ZZ_1000-31': 'DE_ZZ-1000-31',
  'ZZ_1000-32': 'DE_ZZ-1000-32',
  'ZZ_1001-31': 'DE_ZZ-1001-31',
  'ZZ_1060-31': 'DE_ZZ-1060-31',
};

export const CAsigns: Map = {
  RA1: 'CA_RA1',
  RA12: 'CA_RA12',
  RA2: 'CA_RA2',
  RA4: 'CA_RA4',
  RA4T: 'CA_RA4T',
  RA5L: 'CA_RA5L',
  RA5R: 'CA_RA5R',
  RA6S: 'CA_RA6S',
  RA6TAC: 'CA_RA6TAC',
  RA7: 'CA_RA7',
  RA7TAC: 'CA_RA7TAC',
  RA9A: 'CA_RA9A',
  RB10: 'CA_RB10',
  RB11: 'CA_RB11',
  RB12: 'CA_RB12',
  RB13: 'CA_RB13',
  RB14: 'CA_RB14',
  RB15: 'CA_RB15',
  RB16: 'CA_RB16',
  RB16A: 'CA_RB16A',
  RB16T: 'CA_RB16T',
  RB19: 'CA_RB19',
  RB1A: 'CA_RB1A',
  RB20: 'CA_RB20',
  RB21: 'CA_RB21',
  RB24: 'CA_RB24',
  RB25: 'CA_RB25',
  RB27: 'CA_RB27',
  RB30A: 'CA_RB30A',
  RB31: 'CA_RB31',
  RB33: 'CA_RB33',
  RB34: 'CA_RB34',
  RB35: 'CA_RB35',
  RB36: 'CA_RB36',
  RB37: 'CA_RB37',
  RB37TAC: 'CA_RB37TAC',
  RB39: 'CA_RB39',
  RB39A: 'CA_RB39A',
  RB41: 'CA_RB41',
  RB42: 'CA_RB42',
  RB43: 'CA_RB43',
  RB44: 'CA_RB44',
  RB45: 'CA_RB45',
  RB46: 'CA_RB46',
  RB47: 'CA_RB47',
  RB48: 'CA_RB48',
  RB48A: 'CA_RB48A',
  RB51: 'CA_RB51',
  RB55: 'CA_RB55',
  RB58: 'CA_RB58',
  RB61: 'CA_RB61',
  RB62: 'CA_RB62',
  RB66: 'CA_RB66',
  RB67: 'CA_RB67',
  RB68: 'CA_RB68',
  RB69: 'CA_RB69',
  RB6A: 'CA_RB6A',
  RB70: 'CA_RB70',
  RB71: 'CA_RB71',
  RB73: 'CA_RB73',
  RB78: 'CA_RB78',
  RB79R: 'CA_RB79R',
  RB80: 'CA_RB80',
  RB86: 'CA_RB86',
  RB86A: 'CA_RB86A',
  RB88: 'CA_RB88',
  RB90A: 'CA_RB90A',
  RB90B: 'CA_RB90B',
  RB91: 'CA_RB91',
  RB92: 'CA_RB92',
  RC12: 'CA_RC12',
  RC4L: 'CA_RC4L',
  RC4R: 'CA_RC4R',
  RC9: 'CA_RC9',
  SFR16: 'CA_SFR16',
  SFR19: 'CA_SFR19',
  SFR21: 'CA_SFR21',
  SFR23: 'CA_SFR23',
  TC1: 'CA_TC1',
  TC10: 'CA_TC10',
  TC10AL: 'CA_TC10AL',
  TC10ALR: 'CA_TC10ALR',
  TC10AR: 'CA_TC10AR',
  TC10BBR: 'CA_TC10BBR',
  TC10BL: 'CA_TC10BL',
  TC10BR: 'CA_TC10BR',
  TC10BRR: 'CA_TC10BRR',
  TC10C: 'CA_TC10C',
  TC10CR: 'CA_TC10CR',
  TC10D: 'CA_TC10D',
  TC10EL: 'CA_TC10EL',
  TC10ER: 'CA_TC10ER',
  TC10FL: 'CA_TC10FL',
  TC10FR: 'CA_TC10FR',
  TC10T: 'CA_TC10T',
  TC11: 'CA_TC11',
  TC13: 'CA_TC13',
  TC15: 'CA_TC15',
  TC16AL: 'CA_TC16AL',
  TC16AR: 'CA_TC16AR',
  TC16BL: 'CA_TC16BL',
  TC16BR: 'CA_TC16BR',
  TC16CL: 'CA_TC16CL',
  TC16CR: 'CA_TC16CR',
  TC16DL: 'CA_TC16DL',
  TC16DR: 'CA_TC16DR',
  TC16EL_2: 'CA_TC16EL (2)',
  TC16EL: 'CA_TC16EL',
  TC16ER_2: 'CA_TC16ER (2)',
  TC18: 'CA_TC18',
  TC19: 'CA_TC19',
  TC19A: 'CA_TC19A',
  TC20: 'CA_TC20',
  TC21_A_FRAME: 'CA_TC21',
  TC23: 'CA_TC23',
  TC23A: 'CA_TC23A',
  TC24: 'CA_TC24',
  TC25L: 'CA_TC25L',
  TC25R: 'CA_TC25R',
  TC26: 'CA_TC26',
  TC2A: 'CA_TC2A',
  TC2B: 'CA_TC2B',
  TC31L: 'CA_TC31L',
  TC31R: 'CA_TC31R',
  TC32: 'CA_TC32',
  TC33A: 'CA_TC33A',
  TC34: 'CA_TC34',
  TC35: 'CA_TC35',
  TC36: 'CA_TC36',
  TC36B: 'CA_TC36B',
  TC37: 'CA_TC37',
  TC39: 'CA_TC39',
  TC3CT: 'CA_TC3CT',
  TC3L: 'CA_TC3L',
  TC3R: 'CA_TC3R',
  TC4: 'CA_TC4',
  TC40L: 'CA_TC40L',
  TC40R: 'CA_TC40R',
  TC40T: 'CA_TC40T',
  TC41: 'CA_TC41',
  TC41AR: 'CA_TC41AR',
  TC41R: 'CA_TC41R',
  TC42: 'CA_TC42',
  TC43: 'CA_TC43',
  TC5: 'CA_TC5',
  TC7: 'CA_TC7',
  TC9L_2: 'CA_TC9L (2)',
  TC9L: 'CA_TC9L',
  TC9R_2: 'CA_TC9R (2)',
  TC9R: 'CA_TC9R',
  WA11: 'CA_WA11',
  WA11A: 'CA_WA11A',
  WA12: 'CA_WA12',
  WA12A: 'CA_WA12A',
  WA13: 'CA_WA13',
  WA13A: 'CA_WA13A',
  WA14: 'CA_WA14',
  WA15: 'CA_WA15',
  WA15A: 'CA_WA15A',
  WA16: 'CA_WA16',
  WA17: 'CA_WA17',
  WA19: 'CA_WA19',
  WA1L: 'CA_WA1L',
  WA1R: 'CA_WA1R',
  WA21: 'CA_WA21',
  WA22: 'CA_WA22',
  WA22A: 'CA_WA22A',
  WA23L: 'CA_WA23L',
  WA23LT: 'CA_WA23LT',
  WA23R: 'CA_WA23R',
  WA23RT: 'CA_WA23RT',
  WA23TAC: 'CA_WA23TAC',
  WA24: 'CA_WA24',
  WA25: 'CA_WA25',
  WA26: 'CA_WA26',
  WA28: 'CA_WA28',
  WA29: 'CA_WA29',
  WA2L: 'CA_WA2L',
  WA2R: 'CA_WA2R',
  WA30: 'CA_WA30',
  WA30A: 'CA_WA30A',
  WA31: 'CA_WA31',
  WA32: 'CA_WA32',
  WA33L: 'CA_WA33L',
  WA34: 'CA_WA34',
  WA35: 'CA_WA35',
  WA3L: 'CA_WA3L',
  WA3R: 'CA_WA3R',
  WA4L: 'CA_WA4L',
  WA4R: 'CA_WA4R',
  WA50L: 'CA_WA50L',
  WA50R: 'CA_WA50R',
  WA51L: 'CA_WA51L',
  WA51R: 'CA_WA51R',
  WA52L: 'CA_WA52L',
  WA52R: 'CA_WA52R',
  WA56L: 'CA_WA56L',
  WA56R: 'CA_WA56R',
  WA57L: 'CA_WA57L',
  WA57R: 'CA_WA57R',
  WA5L: 'CA_WA5L',
  WA5R: 'CA_WA5R',
  WA74: 'CA_WA74',
  WA75: 'CA_WA75',
  WA7T: 'CA_WA7T',
  WA8: 'CA_WA8',
  WA8L: 'CA_WA8L',
  WA8LR: 'CA_WA8LR',
  WA8R: 'CA_WA8R',
  WA9: 'CA_WA9',
  WB1: 'CA_WB1',
  WB1A: 'CA_WB1A',
  WB2: 'CA_WB2',
  WB3: 'CA_WB3',
  WB4: 'CA_WB4',
  WB6: 'CA_WB6',
  WB7: 'CA_WB7',
  WC1: 'CA_WC1',
  WC10L: 'CA_WC10L',
  WC10R: 'CA_WC10R',
  WC14: 'CA_WC14',
  WC15: 'CA_WC15',
  WC19: 'CA_WC19',
  WC20: 'CA_WC20',
  WC21: 'CA_WC21',
  WC24: 'CA_WC24',
  WC24LTAC: 'CA_WC24LTAC',
  WC24RTAC: 'CA_WC24RTAC',
  WC24T: 'CA_WC24T',
  WC26: 'CA_WC26',
  WC29: 'CA_WC29',
  WC3: 'CA_WC3',
  WC4: 'CA_WC4',
  WC43: 'CA_WC43',
  WC5: 'CA_WC5',
  WC6L: 'CA_WC6L',
  WC6R: 'CA_WC6R',
  WC7: 'CA_WC7',
  WC8L: 'CA_WC8L',
  WC8R: 'CA_WC8R',
};

export const CATEGORIES_CA: Map = {
  R: 'Regulatory',
  T: 'Temporary Condition',
  W: 'Warning',
};
