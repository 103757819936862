import { Grid, Stack, Text, Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { SearchEmployee } from '../../SearchEmployee/SearchEmployee';
import { AddRecipiant } from '../AddRecipiant/AddRecipiant';
import { ContactsList } from '../ContactsList/ContactsList';
import { IPublicClientApplication } from '@azure/msal-browser';
import { NotificationContact } from '../../../models/NotificationContact';
import { Employee } from '../../../models/Employee';

interface ContactData {
  workZoneId: string;
  name: string;
  email: string;
  phone: string;
  external: boolean;
  timeZoneId: string;
  externalUserId?: string;
  timeFrame: {
    start: string;
    end: string;
    daysOfWeek: number[];
  };
}

interface Props {
  isMobile: boolean;
  showInput: boolean;
  instance: IPublicClientApplication;
  notificationContacts: NotificationContact[];
  contactsLoading: boolean;
  onCreateContact: (data: ContactData) => void;
  onToggleInput: (show: boolean) => void;
  fetchEmployees: (query: string) => Promise<Employee[]>;
}

export function NotificationsStep({
  isMobile,
  showInput,
  instance,
  notificationContacts,
  contactsLoading,
  onCreateContact,
  onToggleInput,
  fetchEmployees,
}: Props) {
  return (
    <Grid>
      <Grid.Col span={6}>
        <Stack>
          <Text size="sm" fw={500}>
            Internal colleague
          </Text>
          <SearchEmployee
            width={isMobile ? '100%' : '100%'}
            onChange={(values) => {
              onCreateContact({
                workZoneId: '', // Detta kommer från parent
                name: values.name ?? '',
                email: values.email ?? '',
                phone: values.phone ?? '',
                external: false,
                timeZoneId: 'Central European Time',
                externalUserId: values.externalUserId,
                timeFrame: {
                  start: '00:00:00',
                  end: '23:59:00',
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                },
              });
            }}
            onClose={() => {}}
            fetchEmployees={fetchEmployees}
          />

          <Text mt="lg" ta="center" c="dimmed">
            OR
          </Text>

          <Text size="sm" fw={500}>
            Contact Name
          </Text>
          {showInput ? (
            <AddRecipiant
              width="100%"
              onSubmit={(values) => {
                onCreateContact({
                  workZoneId: '', // Detta kommer från parent
                  name: values.name ?? '',
                  email: values.email ?? '',
                  phone: values.phone ?? '',
                  external: true,
                  timeZoneId: 'Central European Time',
                  timeFrame: {
                    start: '00:00:00',
                    end: '23:59:00',
                    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                  },
                });
                onToggleInput(false);
              }}
              onClose={() => onToggleInput(false)}
            />
          ) : (
            <Button
              leftSection={<IconPlus size={'18px'} />}
              variant="outline"
              onClick={() => onToggleInput(true)}
            >
              Add contact
            </Button>
          )}
        </Stack>
      </Grid.Col>

      <Grid.Col span={6}>
        <Stack>
          <Text size="sm" fw={500}>
            Alarm Notifications Contact
          </Text>
          <ContactsList
            contactsLoading={contactsLoading}
            instance={instance}
            contacts={notificationContacts}
            external={false}
          />
          <ContactsList
            contactsLoading={contactsLoading}
            instance={instance}
            contacts={notificationContacts}
            external={true}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
