import autoTable, { CellHookData, CellInput } from 'jspdf-autotable';
import { DeviceEntry, DeviceRow, LegendItem } from '../../../../models/SupervisionReport';
import { AlarmStatus, EquipmentType } from '../../../../models/enums/DeviceEnums';
import circleDotted from '../../../../assets/report/StatusIcons/circle-dotted.png';
import circleAlert from '../../../../assets/report/StatusIcons/alert-circle.png';
import circleCheck from '../../../../assets/report/StatusIcons/circle-check.png';
import squareGray from '../../../../assets/report/StatusIcons/square-gray.png';
import { createDateRanges, formatDate, formatTimeByCountry } from '../../../../utils/utils';
import { PdfBaseProps } from '../../../../models/PdfExport';
import {
  PDF_CONSTANTS,
  createPdfDocument,
  addHeader,
  createDocumentName,
  addCoverPage,
  addLastPage,
  addLegend,
} from './ExportPdfCommon';
import { addAlarmEventsTable } from './ExportPdfAlarmEvents';
import { ReportType } from '../../../../models/enums/ReportType';

const TIMESTAMP_CELL_WIDTH = 12;
const TEXT_FIELDS_CELL_WIDTH = 20;
const FONT_SIZE = 8;
const PAGE_COLUMS_MAX_LIMT = 18;
const PAGE_ROWS_MAX_LIMT = 24;
const FIXED_COLUMNS = 3;
const FIXED_ICON_SIZE = 5;
const MAX_IMAGE_HEIGHT = 8; // Reduced from 10
const MAX_IMAGE_WIDTH = 8; // Reduced from 10
const CELL_IMAGE_SCALE = 0.5; // Reduced from 0.6 to make images even smaller relative to cell size

export interface PdfProps extends PdfBaseProps {
  timeStamps: string[];
  intervalInHours: number;
  rows: DeviceRow[];
  showSnoozed: boolean;
  imageMap: Map<string, HTMLImageElement>;
}

enum LogStatus {
  NoLog = 0,
}

export async function createPDF(props: PdfProps): Promise<void> {
  return new Promise<void>((resolve) => {
    const startDateString = formatDate(props.reportStartDate);
    const endDateString = formatDate(props.reportEndDate);
    const timeStampHeaders = props.timeStamps.map((timestamp) => {
      const day = new Date(timestamp).toLocaleDateString('en-US', {
        weekday: 'short',
      });
      const start = formatTimeByCountry(timestamp, props.countryCode);
      const end = formatTimeByCountry(
        getNextTimestamp(timestamp, props.intervalInHours),
        props.countryCode
      );
      return `${day}\n${start}\n${end}`;
    });

    const allRows = props.rows.map((row) => {
      const talkInData = props.timeStamps.map((_, index) => {
        const entry = row.entries[index];
        return getTemporaryTextStatus(entry, props.showSnoozed);
      });
      const equipmentType =
        row.currentEquipmentType == EquipmentType.Barrier
          ? EquipmentType.Barrier.toString()
          : EquipmentType.Sign.toString();
      const attachmentRef = row.attachmentRef ? row.attachmentRef : equipmentType;
      return [row.referenceId, row.name, attachmentRef, talkInData].flat();
    });

    const numbOfTalkinColumns = allRows[0].length - FIXED_COLUMNS;
    const columsInOneDay = 24 / props.intervalInHours;
    const pageBreakPages = Math.ceil(props.rows.length / PAGE_ROWS_MAX_LIMT);

    const headers = ['Reference Id', 'Name', 'Attachment', ...timeStampHeaders];

    const doc = createPdfDocument();
    const pageWidth = doc.internal.pageSize.getWidth();

    function createDaysOnEachPagesArray(columns: number, limit: number) {
      const numbOfFullPages = (columns - (columns % limit)) / limit;
      const nonFullPage = columns % limit;

      const columnsOnEachPageArray = Array(numbOfFullPages).fill(limit);
      if (nonFullPage) columnsOnEachPageArray.push(nonFullPage);

      return columnsOnEachPageArray.map((v) => Math.floor(v / columsInOneDay));
    }

    const daysOnEachPageArray = createDaysOnEachPagesArray(
      numbOfTalkinColumns,
      PAGE_COLUMS_MAX_LIMT
    );
    const pagesDateSpans = createDateRanges(
      props.reportStartDate,
      props.reportEndDate,
      daysOnEachPageArray,
      pageBreakPages
    );

    function addStatusIconToCell(data: CellHookData) {
      if (data.column.index > 2 && data.cell.section == 'body') {
        const img = new Image();
        img.src = getExportIcon(data.cell.raw);
        const x = data.cell.x + data.cell.width / 2 - FIXED_ICON_SIZE / 2;
        const y = data.cell.y + data.cell.height / 2 - FIXED_ICON_SIZE / 2;
        doc.addImage(img, x, y, FIXED_ICON_SIZE, FIXED_ICON_SIZE);
      }
    }

    function removeTemporaryStatusId(data: CellHookData) {
      if (data.column.index > 2 && data.cell.section == 'body') {
        data.cell.text[0] = '';
      }
    }

    // GENERATE PDF
    addCoverPage(doc, {
      projectName: props.projectName,
      depot: props.depot,
      startDate: startDateString,
      endDate: endDateString,
    });
    //Autotable is synchronous, if any async action need to take place do that before and add it as input to this file
    autoTable(doc, {
      head: [headers],
      body: allRows,
      margin: {
        left: PDF_CONSTANTS.SIDE_MARGIN,
        right: PDF_CONSTANTS.SIDE_MARGIN,
        top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG + 5,
      },
      headStyles: {
        cellWidth: TIMESTAMP_CELL_WIDTH,
        valign: 'middle',
        halign: 'center',
        fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
        fontStyle: 'normal',
        fontSize: FONT_SIZE,
      },
      columnStyles: {
        0: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
        1: { cellWidth: TEXT_FIELDS_CELL_WIDTH + 3.5, overflow: 'ellipsize' },
        2: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: FONT_SIZE,
      },
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: [0, 1, 2],
      didDrawCell: (data) => {
        if (data.column.index == 2 && data.cell.section == 'body' && data.cell.raw) {
          const image = props.imageMap.get(data.cell.raw as string);
          if (!image) return;

          const ratio = image.naturalWidth / image.naturalHeight;

          // Calculate dimensions while maintaining aspect ratio and respecting maximums
          let width, height;
          if (ratio > 1) {
            // Wider than tall
            width = Math.min(MAX_IMAGE_WIDTH, data.cell.width * CELL_IMAGE_SCALE);
            height = width / ratio;
          } else {
            // Taller than wide
            height = Math.min(MAX_IMAGE_HEIGHT, data.cell.height * CELL_IMAGE_SCALE);
            width = height * ratio;
          }

          // Ensure neither dimension exceeds its maximum
          if (width > MAX_IMAGE_WIDTH) {
            width = MAX_IMAGE_WIDTH;
            height = width / ratio;
          }
          if (height > MAX_IMAGE_HEIGHT) {
            height = MAX_IMAGE_HEIGHT;
            width = height * ratio;
          }

          // Center in cell
          const x = data.cell.x + (data.cell.width - width) / 2;
          const y = data.cell.y + (data.cell.height - height) / 2;

          if (!isNaN(x) && !isNaN(y) && !isNaN(width) && !isNaN(height)) {
            doc.addImage(image, x, y, width, height);
          } else {
            console.error('Invalid dimensions for attachment image');
          }
        }
        addStatusIconToCell(data);
      },
      didParseCell: (data) => {
        removeTemporaryStatusId(data);
        if (data.column.index == 2 && data.cell.section == 'body') {
          data.cell.text[0] = '';
        }
      },
      didDrawPage: (data) => {
        addHeader(doc, {
          projectName: props.projectName,
          title: 'Intellitags',
          startDate: formatDate(pagesDateSpans[data.pageNumber - 1].start),
          endDate: formatDate(pagesDateSpans[data.pageNumber - 1].end),
          sideMargin: data.settings.margin.right,
        });

        // Add page number at the bottom right
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.BODY);
        doc.setTextColor(0, 0, 0);
        doc.text(
          `Page: ${data.pageNumber}`,
          pageWidth - PDF_CONSTANTS.SIDE_MARGIN - 20,
          pageHeight - PDF_CONSTANTS.SIDE_MARGIN,
          { align: 'right' }
        );

        // Add legend at the bottom left
        const legendItems: LegendItem[] = [
          { text: 'OK', img: circleCheck },
          { text: 'Fallen', img: circleAlert },
          { text: 'No Log', img: circleDotted },
          { text: 'Snoozed', img: squareGray },
        ];
        addLegend(doc, legendItems, pageHeight);
      },
    });
    // Add alarm events table on a new page
    addAlarmEventsTable({
      doc,
      rows: props.rows,
      projectName: props.projectName,
      startDate: props.reportStartDate,
      endDate: props.reportEndDate,
      imageMap: props.imageMap,
      reportType: ReportType.Intellitags,
      countryCode: props.countryCode,
    });
    addLastPage(doc, props.contactPerson);
    doc.save(createDocumentName(props.projectName, startDateString, endDateString));
    resolve();
  });
}

function getNextTimestamp(dateString: string, intervalInHours: number | null) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + (intervalInHours ?? 0));
  return date.toISOString();
}

const getTemporaryTextStatus = (
  entry: DeviceEntry,
  showSnoozed: boolean
): AlarmStatus | LogStatus => {
  const logsToShow = entry?.logs?.length
    ? showSnoozed
      ? entry.logs
      : entry.logs.filter((dl) => !dl.workZoneSnoozed)
    : [];

  if (logsToShow.length === 0) return LogStatus.NoLog;
  if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming))
    return AlarmStatus.Alarming;
  return AlarmStatus.OK;
};

const getExportIcon = (id: CellInput) => {
  if (id == AlarmStatus.OK) return circleCheck;
  if (id == AlarmStatus.Alarming) return circleAlert;
  return circleDotted;
};
