import { Input, Select, Stack, Checkbox, Box } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { useEffect } from 'react';
import { Country } from '../../../models/Country';
import { Depot } from '../../../shared/types';
import { CreateWorkZone } from '../../../models/Project';

interface Props {
  create: boolean;
  initialValues: CreateWorkZone | null;
  onSubmit: (data: CreateWorkZone | null) => void;
  countries?: Country[];
  depots?: Depot[];
}

export const EditAndCreateProjectInputForm = ({
  create = false,
  onSubmit,
  initialValues,
  countries,
  depots,
}: Props) => {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: initialValues
      ? {
          name: initialValues.name,
          customer: initialValues.customerName,
          startDate: initialValues.startDate ? new Date(initialValues.startDate) : null,
          endDate: initialValues.endDate ? new Date(initialValues.endDate) : null,
          countryCode: initialValues.countryCode,
          depot: initialValues.depotId,
          isDemoProject: initialValues.isDemoProject,
        }
      : {
          name: '',
          customer: '',
          startDate: new Date(),
          endDate: null,
          countryCode: '',
          depot: '',
          isDemoProject: false,
        },
    validate: {
      name: isNotEmpty(),
      customer: isNotEmpty(),
      startDate: isNotEmpty(),
      countryCode: isNotEmpty(),
      depot: isNotEmpty(),
      endDate: (value, values) => {
        if (value) {
          const endDate = value as Date;
          if (endDate && values.startDate && endDate <= values.startDate) {
            return 'End date should be empty or greater than start date';
          }
        }
      },
    },
  });

  useEffect(() => {
    if (!create) return;
    handleSubmit();
  }, [create]);

  const handleSubmit = () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      onSubmit(null);
      return;
    }

    const formValues = form.getValues();
    const project: CreateWorkZone = {
      name: formValues.name,
      customerName: formValues.customer,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      countryCode: formValues.countryCode,
      depotId: formValues.depot,
      isDemoProject: formValues.isDemoProject,
      manualCenter: false,
    };
    onSubmit(project);
  };

  return (
    <Box>
      <Stack gap="md">
        <Input.Wrapper labelProps={{ fw: 600 }} label="Name" required>
          <Input
            placeholder="Enter project name"
            required
            key={form.key('name')}
            {...form.getInputProps('name')}
          />
        </Input.Wrapper>

        <Checkbox
          label="This is a demo project"
          {...form.getInputProps('isDemoProject', { type: 'checkbox' })}
        />

        {countries != null && (
          <Input.Wrapper labelProps={{ fw: 600 }} label="Country" required>
            <Select
              placeholder="Select country"
              required
              key={form.key('countryCode')}
              data={countries.map((c) => ({
                label: c.name,
                value: c.code,
              }))}
              {...form.getInputProps('countryCode')}
              onChange={(c) => {
                form.setFieldValue('countryCode', c ?? '');
                form.setFieldValue('depot', '');
              }}
            />
          </Input.Wrapper>
        )}

        {depots && (
          <Input.Wrapper labelProps={{ fw: 600 }} label="Depot" required>
            <Select
              placeholder="Select depot"
              required
              key={form.key('depot')}
              data={depots
                .filter((d) => d.countryCode === form.getValues().countryCode)
                .map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
              {...form.getInputProps('depot')}
            />
          </Input.Wrapper>
        )}

        <Input.Wrapper labelProps={{ fw: 600 }} label="Customer" required>
          <Input
            placeholder="Enter customer name"
            required
            key={form.key('customer')}
            {...form.getInputProps('customer')}
          />
        </Input.Wrapper>

        <Stack gap="md">
          <DatePickerInput
            placeholder="Start date"
            miw={'140px'}
            label="Start date"
            required
            clearable
            key={form.key('startDate')}
            labelProps={{ fw: 600 }}
            {...form.getInputProps('startDate')}
          />
          <DatePickerInput
            placeholder="End date"
            miw={'140px'}
            label="End date"
            clearable
            key={form.key('endDate')}
            labelProps={{ fw: 600 }}
            {...form.getInputProps('endDate')}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
