import { ActionIcon, Group, Text, Paper } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { TimeFrame } from '../../../models/NotificationContact';
import { DaysOfWeekNames } from '../../../models/enums/NotificationContactsEnums';
import { useDisclosure } from '@mantine/hooks';
import { AddTimeFrameModal } from '../AddTimeFrame/AddTimeFrameModal';

export const ProjectTimeFrame = (props: {
  timeFrame: TimeFrame;
  onUpdate: (existingTimeFrame: TimeFrame, updatedTimeFrame: TimeFrame) => void;
  onDelete: () => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const weekdays =
    props.timeFrame.daysOfWeek.length === 7
      ? 'All Days'
      : props.timeFrame.daysOfWeek.map((d) => DaysOfWeekNames[d]).join(', ');

  return (
    <>
      <AddTimeFrameModal
        timeFrame={props.timeFrame}
        onSubmit={(newTimeFrame) => props.onUpdate(props.timeFrame, newTimeFrame)}
        opened={opened}
        open={open}
        close={close}
        editModal={true}
        onDelete={props.onDelete}
      />
      <Paper withBorder p="sm" radius="sm">
        <Group justify="space-between" align="center">
          <Text size="sm">
            {weekdays}: {props.timeFrame.start.slice(0, 5)}-{props.timeFrame.end.slice(0, 5)}
          </Text>
          <Group gap="xs">
            <ActionIcon variant="subtle" onClick={open} size="lg">
              <IconEdit size={18} />
            </ActionIcon>
            <ActionIcon variant="subtle" color="red" onClick={props.onDelete} size="lg">
              <IconTrash size={18} />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>
    </>
  );
};
