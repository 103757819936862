import { useContext } from 'react';
import { Center, FileInput } from '@mantine/core';
import { IconWand } from '@tabler/icons-react';
import Compressor from 'compressorjs';

import { getAttachmentByImage } from '../../../services/attachments';
import { useMsal } from '@azure/msal-react';
import { PWAContext } from '../../providers/PWAProvider';
import { Sign } from '../../../models/Sign';

type Props = {
  isLoading: boolean;
  onImageRecognized: (sign: Sign) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export default function SignRecognition({ isLoading, onImageRecognized, setIsLoading }: Props) {
  const { instance } = useMsal();
  const { setMessage } = useContext(PWAContext);

  const compressImage = (file: File) => {
    new Compressor(file, {
      quality: 0.05,
      success(result) {
        getAttachment(result);
      },
      error() {
        getAttachment(file);
      },
    });
  };

  const getAttachment = async (file: File | Blob) => {
    try {
      const attachment = await getAttachmentByImage(instance, file);

      onImageRecognized({
        name: attachment.code,
        value: `SE_${attachment.code}`,
      });
    } catch (error) {
      setMessage({
        title: 'Sign identification failed',
        text: 'Something went wrong when analyzing your image',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (file: File | null) => {
    if (file) {
      setIsLoading(true);
      compressImage(file);
    }
  };

  return (
    <Center h="42px" w="70px" bg="#2e6b691a" style={{ borderRadius: 4 }}>
      {isLoading ? (
        <IconWand height="42px" color="rgb(46, 107, 105)" />
      ) : (
        <>
          <FileInput
            bg="transparent"
            accept="image/png,image/jpg"
            h="42px"
            onChange={handleFileChange}
            pos="absolute"
            variant="unstyled"
            w="70px"
          />

          <IconWand height="42px" color="rgb(46, 107, 105)" />
        </>
      )}
    </Center>
  );
}
