import jsPDF from 'jspdf';
import primaryLogo from '../../../../assets/report/logo-primary.png';
import primaryLogoWhite from '../../../../assets/report/logo-primary-white.png';
import coverBackground from '../../../../assets/report/cover-page.png';
import { PdfBaseProps } from '../../../../models/PdfExport';
import { LegendItem } from '../../../../models/SupervisionReport';

export const PDF_CONSTANTS = {
  SIDE_MARGIN: 8,
  TOP_MARGIN: 12,
  LOGO: {
    WIDTH: 4006 / 90,
    HEIGHT: 1031 / 90,
  },
  FONT: {
    SIZES: {
      TITLE: 20,
      HEADING: 16,
      SUBHEADING: 11,
      BODY: 10,
    },
    FAMILY: {
      NORMAL: 'helvetica',
      BOLD: 'helvetica',
    },
  },
  COLORS: {
    GREEN_PRIMARY: '#2E6B69',
    SAND: '#FFFAEB',
    YELLOW: '#FFE137',
  },
  HEIGHTS: { SUB_HEADER_BG: 16 },
};

export interface PdfDocumentOptions {
  projectName: string;
  depot: string;
  startDate: string;
  endDate: string;
  contactPerson?: PdfBaseProps['contactPerson'];
}

export function createPdfDocument(): jsPDF {
  return new jsPDF({ orientation: 'landscape' });
}

export function addCoverPage(doc: jsPDF, options: PdfDocumentOptions) {
  const TITLE_Y = 90;
  // Add cover page
  doc.addImage(
    coverBackground,
    'PNG',
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );
  doc.addImage(primaryLogo, 'PNG', 5, doc.internal.pageSize.getHeight() - 20, 4006 / 70, 1031 / 70);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.BOLD, 'bold');
  doc.setFontSize(28);
  doc.setTextColor(PDF_CONSTANTS.COLORS.SAND);
  doc.text(options.projectName, 8, TITLE_Y);
  doc.setFontSize(20);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  doc.text(`Depot: ${options.depot}`, 8, TITLE_Y + 12);
  doc.text(`${options.startDate} - ${options.endDate}`, 8, TITLE_Y + 22);
  doc.addPage();
}

export function addLogo(doc: jsPDF, pageWidth: number) {
  doc.addImage(
    primaryLogo,
    'PNG',
    pageWidth - PDF_CONSTANTS.LOGO.WIDTH - PDF_CONSTANTS.SIDE_MARGIN,
    4,
    PDF_CONSTANTS.LOGO.WIDTH,
    PDF_CONSTANTS.LOGO.HEIGHT
  );
}

export function addLastPage(doc: jsPDF, contactPerson: PdfBaseProps['contactPerson']) {
  const LOGO_WIDTH = PDF_CONSTANTS.LOGO.WIDTH * 5;
  const LOGO_HEIGHT = PDF_CONSTANTS.LOGO.HEIGHT * 5;
  const LOGO_X = doc.internal.pageSize.getWidth() / 2 - LOGO_WIDTH / 2;
  const LOGO_Y =
    doc.internal.pageSize.getHeight() / 2 - LOGO_HEIGHT / 2 - (contactPerson?.name ? 20 : 0);

  doc.addPage();
  doc.setFillColor(PDF_CONSTANTS.COLORS.YELLOW);
  doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');
  doc.addImage(primaryLogoWhite, 'PNG', LOGO_X, LOGO_Y, LOGO_WIDTH, LOGO_HEIGHT);

  if (!contactPerson?.name) return;

  const TEXT_X = LOGO_X + 16;
  const TEXT_Y = doc.internal.pageSize.getHeight() - 60;
  doc.setTextColor('black');
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.HEADING);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.BOLD, 'bold');
  doc.text('Contact person', TEXT_X, TEXT_Y);

  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.SUBHEADING);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  doc.text(`Name: ${contactPerson.name}`, TEXT_X, TEXT_Y + 8);

  if (contactPerson.phone) {
    doc.text(`Phone: ${contactPerson.phone}`, TEXT_X, TEXT_Y + 16);
  }

  if (contactPerson.email) {
    doc.text(`Email: ${contactPerson.email}`, TEXT_X, TEXT_Y + (contactPerson.phone ? 24 : 16));
  }
}

interface OptionsProps {
  projectName: string;
  title: string;
  startDate: string;
  endDate: string;
  sideMargin?: number;
}
export function addHeader(
  doc: jsPDF,
  { projectName, title, startDate, endDate, sideMargin }: OptionsProps
) {
  const SUB_HEADER_SIDE_MARGIN = 4;
  const SUB_HEADER_Y = PDF_CONSTANTS.TOP_MARGIN + 4;
  const SUB_HEADER_TEXT_Y = SUB_HEADER_Y + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG / 2;
  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const LEFT_POS = PDF_CONSTANTS.SIDE_MARGIN;
  const RIGHT_POS = PAGE_WIDTH - PDF_CONSTANTS.SIDE_MARGIN;

  // HEADING + LOGO
  doc.setTextColor('black');
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.HEADING);
  doc.text(projectName, PDF_CONSTANTS.SIDE_MARGIN, PDF_CONSTANTS.TOP_MARGIN);
  addLogo(doc, PAGE_WIDTH);

  // SUB HEADING
  // BACKGROUND
  doc.setFillColor(PDF_CONSTANTS.COLORS.GREEN_PRIMARY);
  doc.rect(
    LEFT_POS,
    SUB_HEADER_Y,
    PAGE_WIDTH - (sideMargin || PDF_CONSTANTS.SIDE_MARGIN) * 2,
    PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG,
    'F'
  );
  // TEXT
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.TITLE);
  doc.setTextColor('white');
  // Right Text
  doc.text(title, LEFT_POS + SUB_HEADER_SIDE_MARGIN, SUB_HEADER_TEXT_Y, {
    baseline: 'middle',
  });
  // Left text
  doc.text(`${startDate} - ${endDate}`, RIGHT_POS - SUB_HEADER_SIDE_MARGIN, SUB_HEADER_TEXT_Y, {
    align: 'right',
    baseline: 'middle',
  });
}

export function createDocumentName(
  projectName: string,
  startDate: string,
  endDate: string,
  reportType?: string
): string {
  const documentTitle = `${projectName} ${startDate} ${endDate}${reportType ? ` ${reportType}` : ''}`;
  return `${documentTitle.split(' ').join('_')}.pdf`;
}

export function addLegend(doc: jsPDF, items: LegendItem[], pageHeight: number) {
  const ICON_SIZE = 5;
  const ICON_TEXT_GAP = 2;
  const ITEM_GAP = 10;

  let currentX = PDF_CONSTANTS.SIDE_MARGIN;
  const y = pageHeight - PDF_CONSTANTS.SIDE_MARGIN;

  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.BODY);
  doc.setTextColor(0, 0, 0);

  items.forEach((item) => {
    if (item.img) {
      const img = new Image();
      img.src = item.img;
      doc.addImage(img, currentX, y - ICON_SIZE, ICON_SIZE, ICON_SIZE);
    }
    doc.text(item.text, currentX + ICON_SIZE + ICON_TEXT_GAP, y, {
      baseline: 'bottom',
    });
    // Move to next item position
    const textWidth = doc.getTextWidth(item.text);
    currentX += ICON_SIZE + ICON_TEXT_GAP + textWidth + ITEM_GAP;
  });
}
