import { api } from './api';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Attachment {
  code: string;
}

const resource = 'attachment';

export async function getAttachmentByImage(
  instance: IPublicClientApplication,
  image: File | Blob
): Promise<Attachment> {
  const formData = new FormData();
  formData.append('image', image, 'image');

  const data = await api.formPost(instance, `${resource}/identify`, formData);
  return (await data.json()) as Attachment;
}
