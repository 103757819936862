import { Device, DeviceType } from '../../../../models/Device';
import { EquipmentType, PowerSource } from '../../../../models/enums/DeviceEnums';
import { GenericDeviceMarker } from '../GenericDeviceMarker';
import { ArrowBoardMarker } from './ArrowBoardMarker';
import { BarrierMarkerComponent } from './IntellitagMarkers/BarrierMarker';
import { RoadSignMarkerComponent } from './IntellitagMarkers/RoadSignMarker';
import { PortableSiteAlarmMarker } from './PortableSiteAlarmMarker';
import { PoweredEquipmentMarker } from './PoweredEquipmentMarker/PoweredEquipmentMarker';

type Props = {
  device: Device;
};

export const DeviceMarkerWrapper = ({ device }: Props) => {
  if (device.deviceType == DeviceType.Intellitag) {
    if (device.currentEquipmentType == EquipmentType.Sign) {
      return <RoadSignMarkerComponent attachmentRef={device.attachmentRef} />;
    } else if (device.currentEquipmentType == EquipmentType.Barrier) {
      return <BarrierMarkerComponent />;
    }
  }
  if (device.deviceType == DeviceType.PSA) {
    return <PortableSiteAlarmMarker deviceModel={device.deviceModel} />;
  }
  if (device.deviceType == DeviceType.ArrowBoard)
    return <ArrowBoardMarker arrowDirection={device.arrowDirection} />;
  if (device.deviceType == DeviceType.SmartCable)
    return (
      <PoweredEquipmentMarker
        poweredBy={PowerSource.SmartCable}
        equipment={device.poweredEquipment}
      />
    );
  if (device.deviceType == DeviceType.SmartSolar)
    return (
      <PoweredEquipmentMarker
        poweredBy={PowerSource.SmartSolar}
        equipment={device.poweredEquipment}
      />
    );
  return <GenericDeviceMarker deviceType={device.deviceType} />;
};
