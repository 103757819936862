export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const DaysOfWeekNames = {
  [DaysOfWeek.Sunday]: 'Sun',
  [DaysOfWeek.Monday]: 'Mon',
  [DaysOfWeek.Tuesday]: 'Tue',
  [DaysOfWeek.Wednesday]: 'Wed',
  [DaysOfWeek.Thursday]: 'Thu',
  [DaysOfWeek.Friday]: 'Fri',
  [DaysOfWeek.Saturday]: 'Sat',
};
