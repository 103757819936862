import { Stack, Title } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

export const NoProjectsFound = () => {
  return (
    <Stack p={'lg'} w={'100%'} mt={'xl'} align="center">
      <IconSearch size={'3rem'} />
      <Title order={4} fw={300}>
        No projects found
      </Title>
    </Stack>
  );
};
