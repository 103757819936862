import {
  Title,
  Group,
  Stack,
  Drawer,
  Text,
  Input,
  Button,
  Center,
  Loader,
  Tabs,
  Switch,
} from '@mantine/core';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { WorkZoneCardComponent } from '../components/WorkZoneList/WorkZoneCard';
import { WorkZone } from '../models/Project.ts';
import useInfProjectList from '../data/hooks/Project/useInfProjectListHook.ts';
import useProjectDevices from '../data/hooks/Device/useProjectDevicesHook.ts';
import { ProjectDrawer } from '../components/Drawer/ProjectDrawer.tsx';
import { IconLayoutDashboard, IconMenu2 } from '@tabler/icons-react';
import { useMediaQuery } from 'react-responsive';
import { ProjectListView } from '../components/Project/ProjectList/ProjectListView.tsx';
import { NoProjectsFound } from '../components/Project/NoProjectsFound/NoProjectsFound.tsx';
import useDepots from '../data/hooks/Depots/useDepots.tsx';
import { CustomMultiSelect } from '../shared/MultiSelect/CustomMultiSelect.tsx';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const smallScreen = useMediaQuery({ query: '(max-width: 760px)' });
  const { instance } = useMsal();
  const [drawer, setDrawer] = useState<WorkZone | null>(null);
  const navigate = useNavigate();
  const {
    projects,
    isLoading: loading,
    search,
    handleSearchChange,
    setIncludeDemoProjects,
    setDepot: setDepotCodes,
    includeDemoProjects,
    depot: depotCodes,
    includeClosedProjects,
    setIncludeClosedProjects,
  } = useInfProjectList(instance);

  const projectIds = projects.map((wz) => wz.id) || [];
  const { projectDevices } = useProjectDevices({ instance, projectIds });
  const { depots, depotsNameMap } = useDepots(instance);

  const handleDrawerClose = () => {
    setDrawer(null);
  };

  const projectCardList = projects.map((wz) => {
    const devices = projectDevices[wz.id];
    return (
      <WorkZoneCardComponent
        key={wz.id}
        workZone={wz}
        depotName={depotsNameMap.get(wz.depotId)}
        devices={devices}
        onClick={() => setDrawer(wz)}
      />
    );
  });

  const handleCreateProjectClick = () => {
    navigate('/projects/create');
  };

  return (
    <>
      <Stack bg={'white'} px={'md'} pt={'md'} pb={'xl'}>
        <Group justify="space-between">
          <Title size="h2">Active Projects</Title>
          <Button bg="white" c="black" variant="outline" onClick={handleCreateProjectClick}>
            Create new project
          </Button>
        </Group>
        <Group>
          <Input
            placeholder="Search for project"
            value={search}
            miw={300}
            onChange={(event) => handleSearchChange(event.currentTarget.value)}
          />
          <CustomMultiSelect
            width="350px"
            values={depots.filter((d) => depotCodes.includes(d.id)).map((d) => d.name)}
            data={depots.map((d) => d.name)}
            onChange={(values) => {
              setDepotCodes(depots.filter((d) => values.includes(d.name)).map((d) => d.id));
            }}
          />
        </Group>
        <Group>
          <Switch
            checked={includeClosedProjects}
            onChange={(event) => setIncludeClosedProjects(event.currentTarget.checked)}
            label="Show closed projects"
          />
          <Switch
            label="Show demo projects"
            labelPosition="right"
            defaultChecked={includeDemoProjects}
            onChange={() => {
              setIncludeDemoProjects(!includeDemoProjects);
            }}
          ></Switch>
        </Group>
      </Stack>
      <Tabs defaultValue="card">
        <Tabs.List px={'md'} bg={'white'}>
          <Tabs.Tab value="card" leftSection={<IconLayoutDashboard size={'20px'} />}>
            <Text>Card</Text>
          </Tabs.Tab>
          <Tabs.Tab value="list" leftSection={<IconMenu2 size={'20px'} />}>
            <div
              onClick={() => {
                if (window._paq) {
                  window._paq.push(['trackEvent', 'Home portal', 'List view selected']);
                }
              }}
            >
              <Text>List</Text>
            </div>
          </Tabs.Tab>
          <div style={{ flexGrow: 1 }}></div>
          <Text size={'sm'} fw={500}>
            Ordered by:
          </Text>
          <Text size={'sm'} ml={'xs'}>
            Last created
          </Text>
        </Tabs.List>
        <Tabs.Panel value="card">
          {loading ? (
            <Center mt="xl">
              <Loader />
            </Center>
          ) : (
            <Group align="stretch" justify={smallScreen ? 'center' : 'start'} p={'md'}>
              {projectCardList.length ? projectCardList : <NoProjectsFound />}
            </Group>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="list">
          <ProjectListView
            projects={projects}
            projectDevices={projectDevices}
            depotNameMap={depotsNameMap}
            isLoading={loading}
            onClick={(project) => setDrawer(project)}
          />
        </Tabs.Panel>
      </Tabs>

      <Drawer
        position="right"
        opened={!!drawer}
        onClose={handleDrawerClose}
        title="Project Summary"
        bg="blue"
        overlayProps={{ backgroundOpacity: 0.2 }}
        zIndex={202}
      >
        {drawer && (
          <ProjectDrawer
            project={drawer}
            projectDevices={projectDevices[drawer.id]}
            depotName={depotsNameMap.get(drawer.depotId)}
          />
        )}
      </Drawer>
    </>
  );
}
