import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import postData from '../../api/postData';
import { Point } from 'geojson';

interface SetCenterParams {
  id: string;
  center: Point | null;
  isManual: boolean;
}

const path = `/workZone/set-center-point`;

const useSetManualCenter = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError } = useMutation({
    mutationFn: (params: SetCenterParams) => {
      return postData<SetCenterParams>(path, params, instance).then(() =>
        postData(
          '/workzone/toggle-manual-center',
          {
            id: params.id,
            isManual: params.isManual,
          },
          instance
        )
      );
    },
    onError: (error) => {
      console.error(`Error setting center`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });
  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
  };
};

export default useSetManualCenter;
