import { openRoutes, protectedRoutes } from './router.tsx';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import MantineTheme from './config/MantineTheme.ts';
import PWAProvider from './pwa/providers/PWAProvider.tsx';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import useMatomo from './matomo/useMatomo.ts';
import '@mantine/notifications/styles.css';

interface AppProps {
  pca: PublicClientApplication;
}

export default function App({ pca }: AppProps) {
  useMatomo(pca);

  return (
    <MsalProvider instance={pca}>
      <MantineProvider theme={MantineTheme}>
        <Notifications />
        <AuthenticatedTemplate>
          <PWAProvider>
            <Routes>
              {protectedRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element}>
                  {route.children &&
                    route.children.map((childRoute, childIndex) => (
                      <Route key={childIndex} path={childRoute.path} element={childRoute.element} />
                    ))}
                </Route>
              ))}
            </Routes>
          </PWAProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            {openRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </UnauthenticatedTemplate>
      </MantineProvider>
    </MsalProvider>
  );
}
