import {
  Badge,
  Button,
  Group,
  Loader,
  Paper,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ProjectSettings } from '../components/Project/ProjectSettings/ProjectSettings';
import Reports from '../components/Project/SupervisionReport/Reports';
import { formatDate, formatDateTime } from '../utils/utils.tsx';
import { ProjectOverview } from '../components/Project/ProjectOverview/ProjectOverview';
import { IconChevronLeft } from '@tabler/icons-react';
import { useMsal } from '@azure/msal-react';
import { EditProjectInformationModal } from '../components/Project/CreateProjectModal/EditProjectInformationModal.tsx';
import useProjectDevices from '../data/hooks/Device/useProjectDevicesHook.ts';
import useProject from '../data/hooks/Project/useProjectHook.ts';
import useEditProject from '../data/hooks/Project/useEditProjectHook.ts';
import { ManageDevices } from '../components/Project/ManageDevices/ManageDevices.tsx';
import useCountries from '../data/hooks/Country/useCountriesHook.ts';
import { ProjectHistory } from '../components/Project/ProjectHistory/ProjectHistory.tsx';
import { sortDevices } from '../services/helpers.tsx';
import { APIProvider } from '@vis.gl/react-google-maps';
import useDepots from '../data/hooks/Depots/useDepots.tsx';
import { UpdateWorkZone } from '../models/Project.ts';

export default function ProjectPage() {
  const { projectId } = useParams();
  const { instance } = useMsal();
  const [activeTab, setActiveTab] = useState<string | null>('overview');
  const navigate = useNavigate();

  // Function to get query parameter by name
  const getQueryParam = (param: string) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const initialSelectedDevice = getQueryParam('ref');

  if (!projectId) {
    console.error('Error: project id required');
    //return <FetchError message="Project ID is required to display the project details." navigatePath="/home" />
    return <div>Project not found</div>;
  }

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const { project, isFetching: projectLoading } = useProject({
    instance,
    workZoneId: projectId,
  });

  const { depotsNameMap } = useDepots(instance);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [projectId],
  });

  const { mutate: editProject } = useEditProject(instance);
  const { countries } = useCountries({ instance });

  if (projectLoading) {
    return <Loader mx="50%" my="15%" size="md" />;
  }

  if (!project) {
    return null;
  }

  const handleSetProjectInformation = (project: UpdateWorkZone) => {
    if (project.id) {
      editProject(project);
    } else {
      console.error('Error: project id required');
    }
  };

  return (
    <>
      <Stack bg="white" px="xl" pt="md" pb="xl">
        <Group gap="xl" justify={'space-between'}>
          <Button
            w="fit-content"
            size="xs"
            leftSection={<IconChevronLeft />}
            variant="outline"
            onClick={() => navigate('/home')}
          >
            Back
          </Button>
          <Tooltip
            label="To edit this project go to your work order system"
            position={'bottom-end'}
            offset={5}
            disabled={!project.externalId}
          >
            <Button
              variant="outline"
              size="xs"
              disabled={project.externalId !== null}
              onClick={() => setOpenEditModal(true)}
            >
              Edit
            </Button>
          </Tooltip>
        </Group>
        <Group>
          <Title order={3}>{project?.name}</Title>
          {project.isClosed ? (
            <Badge
              c="black"
              bg={'#f1f3f5'}
              style={{ border: '1px solid black', textTransform: 'none' }}
              w="fit-content"
            >
              <Text size="sm" c="black" fw={500}>
                Closed
              </Text>
            </Badge>
          ) : null}
        </Group>
        <Group gap="xl">
          <div>
            <Text size="sm" fw={500}>
              External ID
            </Text>
            <Text size="sm">{project.externalId ?? '-'}</Text>
          </div>
          <div>
            <Text size="sm" fw={500}>
              Country
            </Text>
            <Text size="sm">
              {countries?.find((p) => p.code == project.countryCode)?.name ?? '-'}
            </Text>
          </div>
          <div>
            <Text size="sm" fw={500}>
              Depot
            </Text>
            <Text size="sm">{depotsNameMap.get(project.depotId) ?? '-'}</Text>
          </div>
          <div>
            <Text size="sm" fw={500}>
              Customer
            </Text>
            <Text size="sm">{project.customerName ? project.customerName : '-'}</Text>
          </div>
          <div>
            <Text size="sm" fw={500}>
              Start
            </Text>
            <Text size="sm">{formatDate(project.startDate)}</Text>
          </div>
          <div>
            <Text size="sm" fw={500}>
              End
            </Text>
            <Text size="sm">{formatDate(project.endDate)}</Text>
          </div>
          {project && project.isClosed && (
            <div>
              <Text size="sm" fw={500}>
                Closed
              </Text>
              <Text size="sm">
                {project.closedAt ? formatDateTime(project.closedAt) : 'Closed'}
              </Text>
            </div>
          )}
        </Group>
      </Stack>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List px="md" bg="white">
          <Tabs.Tab value="overview">
            <Text>Overview</Text>
          </Tabs.Tab>
          <Tabs.Tab value="report">
            <Text>Report</Text>
          </Tabs.Tab>
          <Tabs.Tab value="settings" disabled={project.isClosed}>
            <Text>Notification settings</Text>
          </Tabs.Tab>
          <Tabs.Tab value="connect-device" disabled={project.isClosed}>
            <Text>Manage devices</Text>
          </Tabs.Tab>
          <Tabs.Tab value="history">
            <Text>History</Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="overview">
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
            <ProjectOverview
              devices={sortDevices(projectDevices[project.id])}
              workZone={project}
              initialSelectedDevice={initialSelectedDevice}
            />
          </APIProvider>
        </Tabs.Panel>

        <Tabs.Panel mx="md" value="report">
          <Paper p="md" mt="md">
            <Reports project={project}></Reports>
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel mx="md" value="settings">
          <Paper p="md" mt="md">
            <ProjectSettings project={project} />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel mx="md" value="connect-device">
          <Paper p="md" mt="md">
            <ManageDevices project={project} />
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel mx="md" value="history">
          <Paper p="md" mt="md">
            <ProjectHistory project={project} />
          </Paper>
        </Tabs.Panel>
      </Tabs>
      <EditProjectInformationModal
        isOpen={openEditModal}
        project={{
          ...project,
          id: projectId ?? null,
        }}
        close={() => setOpenEditModal(false)}
        onSubmit={(project) => {
          handleSetProjectInformation(project);
          setOpenEditModal(false);
        }}
      />
    </>
  );
}
