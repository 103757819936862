import { IPublicClientApplication } from '@azure/msal-browser';
import { api } from '../../../services/api.ts';
import { QueryParameters } from '../../../services/QueryParameters.ts';
import { ArrowboardsReportDto, SupervisionReportDto } from '../../../models/SupervisionReport.ts';
import { useQuery } from '@tanstack/react-query';
import QueryKeys from '../../queryKeys.ts';
import { ArrowboardsReportFilters, SupervisionReportFilters } from '../../filters.ts';
import fetchDataSingleItem, { APIResponseSingleItem } from '../../api/fetchDataSingleItem.ts';

const resource = 'supervisionReport';

export class SupervisionReportParams extends QueryParameters {
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval: string;

  constructor(workZoneId: string, startTime: string, endTime: string, interval: string) {
    super();
    this.workZoneId = workZoneId;
    this.startTime = startTime;
    this.endTime = endTime;
    this.interval = interval;
  }
}

export async function getSupervisionReport(
  instance: IPublicClientApplication,
  queryParams?: SupervisionReportParams
): Promise<SupervisionReportDto> {
  const data = await api.get(instance, resource, queryParams != null ? queryParams.toRecord() : {});
  return (await data.json()) as SupervisionReportDto;
}

interface Params {
  instance: IPublicClientApplication;
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval: string;
}

const path = `/supervisionReport`;
const arrowboardPath = `/supervisionReport/arrowboard`;
const smartCablePath = `/supervisionReport/smartcable`;

const useGetSupervisionReport = ({
  instance,
  workZoneId,
  startTime,
  endTime,
  interval,
}: Params) => {
  const pageNumber = 1;
  const pageSize = 1000;

  const {
    data: dataIntellitags,
    isLoading: isLoadingIntellitags,
    isError: isErrorIntellitags,
  } = useQuery<APIResponseSingleItem<SupervisionReportDto>>({
    queryKey: QueryKeys.SupervisionReports.list(pageNumber, pageSize, {
      type: 'intellitags',
      workZoneId,
      startTime,
      endTime,
      interval,
    } as SupervisionReportFilters),
    queryFn: () =>
      fetchDataSingleItem(
        path,
        {
          workZoneId,
          startTime,
          endTime,
          interval,
        },
        instance
      ),
    enabled: !!workZoneId,
  });

  const {
    data: dataArrowboards,
    isLoading: isLoadingArrowboards,
    isError: isErrorArrowboards,
  } = useQuery<APIResponseSingleItem<ArrowboardsReportDto>>({
    queryKey: QueryKeys.SupervisionReports.list(pageNumber, pageSize, {
      type: 'arrowboards',
      workZoneId,
      startTime,
      endTime,
    } as ArrowboardsReportFilters),
    queryFn: () =>
      fetchDataSingleItem(
        arrowboardPath,
        {
          workZoneId,
          startTime,
          endTime,
        },
        instance
      ),
    enabled: !!workZoneId,
  });

  const {
    data: dataSmartCables,
    isLoading: isLoadingSmartCables,
    isError: isErrorSmartCables,
  } = useQuery<APIResponseSingleItem<SupervisionReportDto>>({
    queryKey: QueryKeys.SupervisionReports.list(pageNumber, pageSize, {
      type: 'smartcables',
      workZoneId,
      startTime,
      endTime,
      interval,
    } as SupervisionReportFilters),
    queryFn: () => {
      return fetchDataSingleItem(
        smartCablePath,
        {
          workZoneId,
          startTime,
          endTime,
          interval,
        },
        instance
      );
    },
    enabled: !!workZoneId,
  });

  return {
    supervisionReport: {
      intellitags: dataIntellitags?.item,
      arrowboards: dataArrowboards?.item,
      smartcables: dataSmartCables?.item,
    },
    isLoading: isLoadingIntellitags || isLoadingArrowboards || isLoadingSmartCables,
    isError: isErrorIntellitags || isErrorArrowboards || isErrorSmartCables,
  };
};

export default useGetSupervisionReport;
