import '@mantine/core/styles.css';
import {
  Stack,
  Title,
  Loader,
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Table,
  TextInput,
  rem,
} from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { IconArrowRight, IconSearch } from '@tabler/icons-react';
import { StatusBadge, BatteryBadge } from '../components/Badge';
import { BatteryStatus } from '../models/enums/DeviceEnums';
import { formatDateTimeString } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import useInfDeviceList from '../data/hooks/Device/useInfDeviceListHook';
import { NoDevicesFound } from '../data/hooks/Device/NoDevicesFound/NoDevicesFound';

export default function DevicesPage() {
  const navigate = useNavigate();
  const crumbs = [
    { title: 'Home', href: '/home' },
    { title: 'Devices', href: '/devices' },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const { instance } = useMsal();
  const { devices, isLoading: loading, search, handleSearchChange } = useInfDeviceList(instance);

  const rows = devices.map((device) => (
    <Table.Tr key={device.id}>
      <Table.Td>{device.currentName}</Table.Td>
      <Table.Td>{device.referenceId}</Table.Td>
      <Table.Td>{device.workZoneName}</Table.Td>
      <Table.Td>
        <StatusBadge status={device.alarmStatus} size="md" />
      </Table.Td>
      <Table.Td>
        <Group justify="space-between">
          {device.batteryVoltage}
          <BatteryBadge
            batteryStatus={device.batteryStatus == BatteryStatus.OK ? null : device.batteryStatus}
            size="md"
          />
        </Group>
      </Table.Td>
      <Table.Td>{formatDateTimeString(device.latestUpdate)}</Table.Td>
      <Table.Td ta="end">
        <Button
          variant="transparent"
          rightSection={<IconArrowRight />}
          onClick={() => navigate(`/devices/edit/${device.id}`)}
        >
          View
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Stack p={'xl'}>
        <Breadcrumbs separator=">" separatorMargin={'md'} mt={'sm'}>
          {crumbs}
        </Breadcrumbs>
        <Title order={3}>Devices</Title>
        <Group gap={'xs'} align="top" mb={'sm'}>
          <TextInput
            placeholder="Search by any field"
            mb="md"
            w={'450px'}
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
          />
        </Group>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Additional info</Table.Th>
              <Table.Th>Reference Id</Table.Th>
              <Table.Th>Project</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Battery Health</Table.Th>
              <Table.Th>Latest Update</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          {rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}
        </Table>
        {loading && (
          <Group w={'100%'} mt={'xl'} justify="center">
            <Loader />
          </Group>
        )}
        {rows.length == 0 && !loading && <NoDevicesFound />}
      </Stack>
    </>
  );
}
