import { Button, Group, Stack } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import IntellitagDashboard from './IntellitagDashboard/IntellitagDashboard';
import { WorkZone } from '../../../../models/Project';
import ArrowboardDashboard from './ArrowBoardDashboard/ArrowBoardDashboard';
import SmartCableDashboard from './SmartCableDashboard/SmartCableDashboard';
import { ArrowboardsReportDto, SupervisionReportDto } from '../../../../models/SupervisionReport';
import ExportReportModal from './ExportReportModal';
import { useState, useRef, useEffect } from 'react';
import { Employee } from '../../../../models/Employee';
import { useMsal } from '@azure/msal-react';
import { getEmployees } from '../../../../services/auth';
import { notifications } from '@mantine/notifications';
import { ReportType, ReportTypeOrNull } from '../../../../models/enums/ReportType';
import { PdfExportRef } from '../../../../models/PdfExport';

interface Props {
  projectName: WorkZone['name'];
  projectId: WorkZone['id'];
  reportData: {
    intellitags?: SupervisionReportDto;
    arrowboards?: ArrowboardsReportDto;
    smartcables?: SupervisionReportDto;
  };
  depotName: string;
  intervalInHours: string;
  dateValue: [Date | null, Date | null];
  startDate: Date;
  endDate: Date;
  projectContactPerson?: {
    name: string;
    email: string;
    phone: string;
  };
  countryCode: string;
}

export default function ReportOverview({
  projectName,
  projectId,
  reportData,
  depotName,
  intervalInHours,
  dateValue,
  startDate,
  endDate,
  projectContactPerson,
  countryCode,
}: Props) {
  const { instance } = useMsal();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [exportContactPerson, setExportContactPerson] = useState<
    | {
        name: string;
        email: string;
        phone: string;
      }
    | undefined
  >(undefined);
  const [pendingExport, setPendingExport] = useState<ReportTypeOrNull>(null);
  const intellitagsRef = useRef<PdfExportRef>(null);
  const arrowboardsRef = useRef<PdfExportRef>(null);
  const smartcablesRef = useRef<PdfExportRef>(null);

  useEffect(() => {
    const exportPdf = async () => {
      if (!pendingExport || !reportData) return;

      try {
        await handlePdfExport(pendingExport);
      } catch (error) {
        console.error('Failed to export PDF:', error);
      }
    };

    exportPdf();
  }, [pendingExport, reportData]);

  const fetchEmployees = async (query: string): Promise<Employee[]> => {
    return await getEmployees(instance, query);
  };

  const handleExport = (
    type: ReportType,
    contactPerson: { name: string; email: string; phone: string }
  ) => {
    setIsExporting(true);
    setExportContactPerson(contactPerson);
    setPendingExport(type);
  };

  const handlePdfExport = async (type: ReportType) => {
    try {
      switch (type) {
        case ReportType.Intellitags:
          await intellitagsRef.current?.exportPDF();
          break;
        case ReportType.Arrowboards:
          await arrowboardsRef.current?.exportPDF();
          break;
        case ReportType.SmartCables:
          await smartcablesRef.current?.exportPDF();
          break;
      }
    } catch (error) {
      console.error('Error exporting PDF:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to export PDF report',
        color: 'red',
      });
    } finally {
      setIsExporting(false);
      setPendingExport(null);
    }
  };

  return (
    <>
      {reportData.intellitags?.rows.length ||
      reportData.arrowboards?.length ||
      reportData.smartcables?.rows.length ? (
        <Stack gap="xl">
          <Group justify="space-between">
            <div />
            <Button loading={isExporting} onClick={() => setIsExportModalOpen(true)}>
              Export Report
            </Button>
          </Group>

          {reportData.intellitags?.rows.length ? (
            <IntellitagDashboard
              ref={intellitagsRef}
              intervalInHours={intervalInHours ? Number.parseInt(intervalInHours) : null}
              data={reportData.intellitags}
              reportStartDate={dateValue[0] || startDate}
              reportEndDate={dateValue[1] || endDate}
              projectId={projectId}
              projectName={projectName}
              depot={depotName}
              contactPerson={exportContactPerson}
              countryCode={countryCode}
            />
          ) : null}

          {reportData.arrowboards?.length ? (
            <ArrowboardDashboard
              ref={arrowboardsRef}
              projectId={projectId}
              projectName={projectName}
              depot={depotName}
              reportStartDate={dateValue[0] || startDate}
              reportEndDate={dateValue[1] || endDate}
              data={reportData.arrowboards}
              contactPerson={exportContactPerson}
              countryCode={countryCode}
            />
          ) : null}

          {reportData.smartcables?.rows.length ? (
            <SmartCableDashboard
              ref={smartcablesRef}
              intervalInHours={intervalInHours ? Number.parseInt(intervalInHours) : null}
              data={reportData.smartcables}
              reportStartDate={dateValue[0] || startDate}
              reportEndDate={dateValue[1] || endDate}
              projectId={projectId}
              projectName={projectName}
              contactPerson={exportContactPerson}
              depot={depotName}
              countryCode={countryCode}
            />
          ) : null}
        </Stack>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
          <IconAlertTriangle size={48} color="#FFC107" />
          <h3>No data available for the selected period</h3>
          <p>
            Please try selecting a different date range or check if the data for this project is
            available during the selected period.
          </p>
        </div>
      )}

      <ExportReportModal
        isOpen={isExportModalOpen}
        close={() => setIsExportModalOpen(false)}
        onExport={handleExport}
        hasIntellitagsData={!!reportData.intellitags?.rows.length}
        hasArrowboardsData={!!reportData.arrowboards?.length}
        hasSmartCablesData={!!reportData.smartcables?.rows.length}
        projectContactPerson={projectContactPerson}
        fetchEmployees={fetchEmployees}
      />
    </>
  );
}
