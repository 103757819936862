import { BADGE_STATES } from '../../components/Badge/Badge';
import { BatteryStatus } from '../../models/enums/DeviceEnums';

export default function getBadgeStateFromBatteryStatus(batteryStatus: BatteryStatus) {
  const BatteryStatusBadgeStateMap = {
    [BatteryStatus.OUT_OF_BATTERY]: BADGE_STATES.OFFLINE,
    [BatteryStatus.CRITICAL]: BADGE_STATES.CRITICAL,
    [BatteryStatus.LOW]: BADGE_STATES.WARNING,
    [BatteryStatus.OK]: BADGE_STATES.OK,
    [BatteryStatus.DISCONNECTED]: BADGE_STATES.OFFLINE,
  };

  if (batteryStatus == null) return BADGE_STATES.OFFLINE;
  return BatteryStatusBadgeStateMap[batteryStatus];
}
