import { ARROW_DIRECTION, ARROW_DIRECTION_NAMES } from '../../../../models/Device';
import { TwoColumnText } from '../../../../shared/TwoColumnText';

type Props = {
  arrowDirection: ARROW_DIRECTION;
};

export const ArrowboardInfo = ({ arrowDirection }: Props) => {
  return (
    <>
      <TwoColumnText
        width="100%"
        first="Arrow Direction"
        second={ARROW_DIRECTION_NAMES[arrowDirection]}
      />
    </>
  );
};
