import { Device } from '../models/Device';
import { EquipmentType, PoweredEquipment } from '../models/enums/DeviceEnums';
import { api } from './api';
import { QueryParameters } from './QueryParameters';
import { IPublicClientApplication } from '@azure/msal-browser';

const resource = 'device';

export class DeviceParams extends QueryParameters {
  imei?: string;
  deviceId?: string;
  workZoneId?: string;
  referenceId?: string;

  constructor(referenceId?: string, imei?: string, workZoneId?: string, deviceId?: string) {
    super();
    this.workZoneId = workZoneId;
    this.imei = imei;
    this.referenceId = referenceId;
    this.deviceId = deviceId;
  }
}

export async function getDeviceById(
  instance: IPublicClientApplication,
  id: string
): Promise<Device> {
  const data = await api.get(instance, `${resource}/${id}`, {});
  return data.json();
}

export async function getDeviceByReferenceId(
  instance: IPublicClientApplication,
  referenceId: string
): Promise<Device> {
  const data = await api.get(instance, `${resource}/referenceId/${referenceId}`, {});
  return data.json();
}

/**
 * @deprecated Should use assignToProject instead
 */
export async function setDeviceWorkzoneId(
  instance: IPublicClientApplication,
  workZoneId: string | null,
  deviceId: string,
  equipmentType: EquipmentType | null,
  currentName: string | null,
  attachmentRef: string | null
) {
  await api.post(instance, `${resource}/set-workzone`, {
    deviceId,
    workZoneId,
    equipmentType,
    currentName,
    attachmentRef,
  });
}

export async function assignToProject({
  instance,
  deviceId,
  projectId,
  equipmentType,
  poweredEquipment,
  currentName,
  attachmentRef,
}: {
  instance: IPublicClientApplication;
  deviceId: string;
  projectId: string | null;
  equipmentType: EquipmentType | null;
  poweredEquipment: PoweredEquipment | null;
  currentName: string | null;
  attachmentRef: string | null;
}) {
  await api.post(instance, `${resource}/assign-to-project`, {
    deviceId,
    projectId,
    equipmentType,
    poweredEquipment,
    currentName,
    attachmentRef,
  });
}

export async function getDevices(
  instance: IPublicClientApplication,
  queryParams?: DeviceParams
): Promise<Device[]> {
  const data = await api.get(instance, resource, queryParams != null ? queryParams.toRecord() : {});
  return (await data.json()) as Device[];
}

export async function unassignDevicesFromWorkZone(
  instance: IPublicClientApplication,
  workZoneId?: string
) {
  await api.post(instance, `${resource}/unassign-workzone`, {
    workZoneId,
  });
}
