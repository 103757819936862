import { SimpleGrid, Stack } from '@mantine/core';

import { getAllSigns, getCategoryFromSignName, getSignCategories } from '../../../utils/signs';
import SignItem from './components/SignItem';
import SignCategory from './components/SignCategory';
import { Sign } from '../../../models/Sign';

type Props = {
  handleClick: (sign: Sign | null) => void;
  selected: Sign | null;
  searchString: string;
  countryCode: string;
};

export default function SignList({ selected, handleClick, searchString, countryCode }: Props) {
  const countrySigns = getAllSigns(countryCode);
  const categories = getSignCategories(countryCode);
  const signList = Object.keys(countrySigns)
    .filter((sign) => sign.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
    .map((key) => {
      return (
        <SignItem
          active={selected?.value === countrySigns[key]}
          handleClick={handleClick}
          key={countrySigns[key]}
          name={key}
          value={countrySigns[key]}
        />
      );
    });

  const categorisedSignList = Object.keys(countrySigns).map((sign) => ({
    name: sign,
    category: getCategoryFromSignName({ countryCode, signName: sign }),
  }));

  return (
    <Stack pb={120} style={{ overflow: 'auto' }}>
      {!categories || searchString ? (
        <SimpleGrid cols={4}>{signList}</SimpleGrid>
      ) : (
        Object.keys(categories).map((categoryKey) => (
          <SignCategory title={categories[categoryKey] || ''} key={categoryKey}>
            {categorisedSignList
              .filter((sign) => sign.category === categoryKey)
              .map((sign) => (
                <SignItem
                  active={selected?.value === countrySigns[sign.name]}
                  handleClick={handleClick}
                  key={countrySigns[sign.name]}
                  name={sign.name}
                  value={countrySigns[sign.name]}
                />
              ))}
          </SignCategory>
        ))
      )}
    </Stack>
  );
}
