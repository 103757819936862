import { Point } from 'geojson';
import { AlarmStatus, BatteryStatus, EquipmentType } from './enums/DeviceEnums';
import { ARROW_DIRECTION, ARROW_DIRECTION_NAMES } from './Device.ts';

export interface DeviceLog {
  deviceId: string;
  workZoneId: string;
  timestamp: string;
  name: string;
  position: Point;
  alarmStatus: AlarmStatus;
  currentConfigId: string;
  requestedConfigId: string;
  batteryVoltage: number;
  externalBatteryVoltage: number | undefined;
  batteryStatus: BatteryStatus;
  attachmentRef: string;
  currentEquipmentType: EquipmentType;
  workZoneName: string;
  additionalProperties: string;
}

export function GetArrowDirectionFromDeviceLog(deviceLog: DeviceLog) {
  if (deviceLog.additionalProperties != null) {
    try {
      const parsedProperties = JSON.parse(deviceLog.additionalProperties);
      const arrowDirection = parsedProperties?.ArrowDirection as ARROW_DIRECTION;
      return ARROW_DIRECTION_NAMES[arrowDirection] ?? 'Unknown';
    } catch {
      return 'Invalid Data';
    }
  }
  return '-';
}
