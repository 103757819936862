import '../styles/Root.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import { NavbarSegmented } from '../components/Navbar/NavbarSegmented';
import logo from '../assets/R_L1_gul_neg_RGB.svg';
import '@mantine/dropzone/styles.css';
import { useState } from 'react';

export default function Root() {
  const [opened, { toggle }] = useDisclosure();
  const [minimized, setMinimized] = useState(false);

  return (
    <>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: minimized ? 80 : 300,
          breakpoint: 'md',
          collapsed: { mobile: !opened },
        }}
        padding={'0'}
        styles={(theme) => ({
          main: { backgroundColor: theme.colors.sandBg[0] },
          navbar: { backgroundColor: 'white' },
          header: {
            backgroundColor: theme.colors.deepGreen[9],
            borderColor: theme.colors.deepGreen[9],
            zIndex: 200,
          },
        })}
      >
        <AppShell.Header>
          <Group justify="space-between" mx={'16px'} h={'60px'}>
            <a href="/projects">
              <img className="logo" src={logo} width={'150px'}></img>
            </a>
            <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" color="white" />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar zIndex={302} p="md" pt={0}>
          <NavbarSegmented toggleNavbar={toggle} toggleMinimize={() => setMinimized(!minimized)} />
        </AppShell.Navbar>
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </>
  );
}
