import { Table, Tooltip, Button, Text } from '@mantine/core';
import classes from './AccessManagement.module.css';
import { Country } from '../../models/Country';

const CountryTable = (props: {
  countries: Country[];
  removeCountry: (country: string) => void;
}) => {
  const rows = props.countries.map((country) => (
    <Table.Tr key={country.code}>
      <Table.Td>{country.name}</Table.Td>
      <Table.Td ta="end">
        <Tooltip label="Remove access to country" position="left">
          <Button
            size="xs"
            c="red"
            bg="rgba(255,0,0,0.1)"
            onClick={() => props.removeCountry(country.code)}
          >
            Remove
          </Button>
        </Tooltip>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table highlightOnHover bg={'white'} className={classes.table}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Code</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {props.countries.length > 0 ? (
          rows
        ) : (
          <Table.Tr>
            <Table.Td colSpan={2} style={{ textAlign: 'center', padding: '20px' }}>
              <Text size="sm">No country accesses</Text>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};

export default CountryTable;
