import { Fragment, forwardRef, useState } from 'react';
import { Table, Text, Title, ScrollArea, Group, Stack, Checkbox, Switch } from '@mantine/core';
import circleAlert from '../../../../../assets/report/StatusIcons/alert-circle.png';
import circleCheck from '../../../../../assets/report/StatusIcons/circle-check.png';
import circleDotted from '../../../../../assets/report/StatusIcons/circle-dotted.png';
import squareGray from '../../../../../assets/report/StatusIcons/square-gray.png';
import { useMobileDevice } from '../../../../../hooks/useMobileDevice';
import { IconPlugOff } from '@tabler/icons-react';
import { PdfExportRef } from '../../../../../models/PdfExport';
import { useDashboard } from '../../hooks/useDashboard';
import { SmartCableDashboardProps } from '../../../../../models/DashboardTypes';
import { createPDF, PdfProps } from '../../utils/ExportPdfSmartCable';
import {
  SmartCableEvent,
  SmartCableReportDto,
  SMART_CABLE_EVENT_TYPE,
  DeviceRow,
  LegendItem,
} from '../../../../../models/SupervisionReport';
import {
  BatteryStatus,
  PoweredEquipment,
  PoweredEquipmentNames,
} from '../../../../../models/enums/DeviceEnums';
import { DashboardComponent } from '../../../../../models/Dashboard';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { ReportType } from '../../../../../models/enums/ReportType';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

// Helper function to convert DeviceRow to SmartCableRow
function convertToSmartCableRow(
  row: DeviceRow,
  startDate: string,
  endDate: string
): SmartCableReportDto['rows'][0] {
  return {
    referenceId: row.referenceId,
    name: row.name,
    attachmentRef: row.attachmentRef,
    events: row.entries.flatMap((entry) =>
      entry.logs
        .filter((log) => {
          const logDate = dayjs(log.timeStamp);
          const start = dayjs(startDate);
          const end = dayjs(endDate);
          return (
            (logDate.isAfter(start) || logDate.isSame(start)) &&
            (logDate.isBefore(end) || logDate.isSame(end))
          );
        })
        .map((log) => {
          // Default to Battery OK if we have voltage and no alarm
          let eventType = SMART_CABLE_EVENT_TYPE.BATTERY_OK;

          // Map backend BatteryStatus to frontend event type
          switch (log.batteryStatus) {
            case BatteryStatus.OUT_OF_BATTERY: // 4
              eventType = SMART_CABLE_EVENT_TYPE.OUT_OF_BATTERY;
              break;
            case BatteryStatus.DISCONNECTED: // 5
              eventType = SMART_CABLE_EVENT_TYPE.DISCONNECTED;
              break;
            default: // 1, 2, 3
              eventType = SMART_CABLE_EVENT_TYPE.BATTERY_OK;
          }

          return {
            timestamp: log.timeStamp,
            eventType,
          };
        })
    ),
  };
}

const SmartCableDashboard = forwardRef<PdfExportRef, SmartCableDashboardProps>((props, ref) => {
  const isMobile = useMobileDevice();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showSnoozed, setShowSnoozed] = useState(true);
  const imageMap = new Map<string, HTMLImageElement>();

  const filteredRows = props.data?.rows.filter((_, idx) => !selectedRows.includes(idx)) ?? [];

  const smartCableRows = filteredRows.map((row) =>
    convertToSmartCableRow(
      row,
      props.reportStartDate.toISOString(),
      props.reportEndDate.toISOString()
    )
  );

  useDashboard<SmartCableReportDto, PdfProps>(
    ref,
    {
      ...props,
      data: { rows: smartCableRows },
    } as DashboardComponent<SmartCableReportDto>,
    async (pdfProps: PdfProps) => {
      await createPDF(pdfProps);
    },
    {
      rows: smartCableRows,
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      imageMap,
      depot: props.depot,
      countryCode: props.countryCode,
    },
    ReportType.SmartCables
  );

  const getStatusForDay = (events: SmartCableEvent[], date: string) => {
    const dayStart = dayjs.utc(date).startOf('day');
    const dayEnd = dayjs.utc(date).endOf('day');

    const eventsInDay = events.filter((event) => {
      const eventTime = dayjs.utc(event.timestamp);
      const isInDay = eventTime.isSameOrAfter(dayStart) && eventTime.isSameOrBefore(dayEnd);
      return isInDay;
    });

    if (eventsInDay.length === 0) {
      return <img src={circleDotted} alt="No Logs" style={{ width: '20px', height: '20px' }} />;
    }

    // Get the latest event in the day
    const latestEvent = eventsInDay.reduce((latest, current) =>
      dayjs(current.timestamp).isAfter(dayjs(latest.timestamp)) ? current : latest
    );

    switch (latestEvent.eventType) {
      case SMART_CABLE_EVENT_TYPE.OUT_OF_BATTERY:
        return (
          <img src={circleAlert} alt="Out of Battery" style={{ width: '20px', height: '20px' }} />
        );
      case SMART_CABLE_EVENT_TYPE.DISCONNECTED:
        return <IconPlugOff stroke={1.5} size={20} />;
      case SMART_CABLE_EVENT_TYPE.BATTERY_OK:
        return <img src={circleCheck} alt="Battery OK" style={{ width: '20px', height: '20px' }} />;
      default:
        return '0';
    }
  };

  const getPoweredEquipmentText = (equipment?: PoweredEquipment) => {
    if (!equipment) return '-';
    return PoweredEquipmentNames[equipment] || '-';
  };

  // Get unique days from intervals
  const uniqueDays =
    props.data?.intervals
      .map((interval) => dayjs(interval).format('YYYY-MM-DD'))
      .filter((date, index, self) => self.indexOf(date) === index) ?? [];

  // Generate day columns
  const dayColumns = uniqueDays.map((day) => (
    <Table.Th key={day} ta="center" style={{ minWidth: '60px' }}>
      {dayjs(day).format('ddd')}
      <br />
      {dayjs(day).format('MM/DD')}
    </Table.Th>
  ));

  return (
    <Stack>
      <Group justify="space-between" align="end">
        <Title order={3}>Smart Cables</Title>
        <Group>
          <Text c="gray.7" size="xs">
            Interval: 24h
          </Text>
          {[
            { text: 'Battery OK', img: circleCheck },
            { text: 'Out of Battery', img: circleAlert },
            {
              text: 'Disconnected',
              icon: <IconPlugOff size={20} />,
            },
            { text: 'No Logs', img: circleDotted },
            { text: 'Snoozed', img: squareGray },
          ].map((s: LegendItem, index) => (
            <Fragment key={index}>
              {s.icon ? (
                <Group gap="xs" align="center">
                  {s.icon}
                  <Text c="gray.7" size="xs">
                    {s.text}
                  </Text>
                </Group>
              ) : (
                <Group gap="xs" align="center">
                  <img width="20px" src={s.img} alt="" style={s.style || {}} />
                  <Text c="gray.7" size="xs">
                    {s.text}
                  </Text>
                </Group>
              )}
            </Fragment>
          ))}
          <Switch
            label="Show snoozed"
            checked={showSnoozed}
            onChange={(event) => setShowSnoozed(event.currentTarget.checked)}
          />
        </Group>
      </Group>

      <ScrollArea.Autosize mah="55vh">
        <Table
          withRowBorders
          highlightOnHover
          withTableBorder
          withColumnBorders
          bg="white"
          stickyHeader
          id="pdf"
        >
          <Table.Thead bg="deepGreen.7" c="white">
            <Table.Tr>
              <Table.Th ta="center" miw="10px">
                Hide
              </Table.Th>
              {isMobile ? null : (
                <Table.Th ta="center" miw="120px">
                  Reference Id
                </Table.Th>
              )}
              <Table.Th ta="center" miw="120px">
                Additional info
              </Table.Th>
              <Table.Th ta="center" miw="60px">
                Powered Equipment
              </Table.Th>
              {dayColumns}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {props.data?.rows.map((row, rowIndex) => (
              <Table.Tr
                key={rowIndex}
                style={{
                  opacity: selectedRows.includes(rowIndex) ? 0.5 : 1,
                }}
              >
                <Table.Td ta="center">
                  <Checkbox
                    checked={selectedRows.includes(rowIndex)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSelectedRows(
                        event.currentTarget.checked
                          ? [...selectedRows, rowIndex]
                          : selectedRows.filter((idx) => idx !== rowIndex)
                      )
                    }
                  />
                </Table.Td>
                {isMobile ? null : <Table.Td ta="center">{row.referenceId}</Table.Td>}
                <Table.Td ta="center">{row.name}</Table.Td>
                <Table.Td ta="center">{getPoweredEquipmentText(row.poweredEquipment)}</Table.Td>
                {uniqueDays.map((day, idx) => (
                  <Table.Td
                    key={day}
                    ta="center"
                    style={{
                      background:
                        showSnoozed && row.entries[idx]?.logs?.some((log) => log.workZoneSnoozed)
                          ? '#CED4DA'
                          : 'transparent',
                    }}
                  >
                    {getStatusForDay(
                      convertToSmartCableRow(
                        row,
                        props.reportStartDate.toISOString(),
                        props.reportEndDate.toISOString()
                      ).events,
                      day
                    )}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea.Autosize>
    </Stack>
  );
});

export default SmartCableDashboard;
