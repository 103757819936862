import '@mantine/core/styles.css';
import { Stack, Title, Text, List, Paper, Center, Anchor } from '@mantine/core';
import { useMobileDevice } from '../hooks/useMobileDevice.tsx';

export default function PrivacyPolicy() {
  const isMobile = useMobileDevice();

  return (
    <>
      <Center maw={'fit-content'}>
        <Paper
          style={{
            maxWidth: '100%', // Ensure the Paper does not exceed the viewport width
            overflowX: 'hidden', // Prevent horizontal scrolling
            wordBreak: 'break-word', // Break long words
            overflowWrap: 'break-word', // Wrap text to prevent horizontal overflow
          }}
        >
          <Stack p={'xl'}>
            {/* Main Title */}
            <Title order={1} size="h3" mb="lg">
              Privacy Policy
            </Title>

            {/* Introduction Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Introduction
              </Title>
              <Text>
                This Privacy Policy explains how we, as the data controller, collect, use, and
                protect personal data in compliance with the General Data Protection Regulation
                (GDPR) and applicable Swedish data protection laws.
              </Text>
              <Text mt="sm">
                By using our system, you agree to the terms outlined in this Privacy Policy.
              </Text>
            </div>

            {/* Data We Collect Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Data We Collect
              </Title>
              <Text>We collect and process the following types of personal data:</Text>
              <List withPadding mt="sm">
                <List.Item>
                  <Text fw="bold">User Registration Data:</Text>
                  <List withPadding>
                    <List.Item>
                      For Ramudden Group users logged in via Ramudden SSO Login:
                    </List.Item>
                    <List withPadding>
                      <List.Item>Name</List.Item>
                      <List.Item>Email address</List.Item>
                      <List.Item>Phone number</List.Item>
                    </List>
                    <List.Item>For external users registered by an admin:</List.Item>
                    <List withPadding>
                      <List.Item>Name</List.Item>
                      <List.Item>Phone number</List.Item>
                    </List>
                  </List>
                </List.Item>
                <List.Item>
                  <Text fw="bold">Notification Contacts:</Text>
                  <List withPadding>
                    <List.Item>Name</List.Item>
                    <List.Item>Phone number</List.Item>
                    <List.Item>Email address</List.Item>
                  </List>
                </List.Item>
              </List>
              <Text mt="sm">
                This information is provided by project members or administrators to facilitate
                notifications about project events.
              </Text>
            </div>

            {/* Purposes of Data Processing Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Purposes of Data Processing
              </Title>
              <Text>We process personal data for the following purposes:</Text>
              <List type="ordered" withPadding mt="sm">
                <List.Item>
                  <Text fw="bold">User Management:</Text> To create, manage, and authenticate user
                  accounts in the system.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Communication:</Text> To contact users for project-related
                  purposes.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Notifications:</Text> To send alerts to designated notification
                  contacts in the event of specific project-related occurrences.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Compliance:</Text> To comply with legal obligations and
                  demonstrate accountability under GDPR.
                </List.Item>
              </List>
            </div>

            {/* Legal Basis for Processing Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Legal Basis for Processing
              </Title>
              <Text>We process personal data based on the following legal grounds:</Text>
              <List withPadding mt="sm">
                <List.Item>
                  <Text fw="bold">Performance of a Contract:</Text> To provide access to our system
                  and deliver notifications as part of project-related services.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Legitimate Interest:</Text> For efficient user and notification
                  management.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Consent:</Text> Notification contacts can be added by system users
                  or administrators. It is the responsibility of the person adding the contact to
                  ensure they have the necessary consent to provide the contact’s information for
                  notifications. Recipients can opt out of notifications or request deletion of
                  their data at any time.
                </List.Item>
                <List.Item>
                  <Text fw="bold">Legal Obligation:</Text> To comply with applicable laws and
                  regulations.
                </List.Item>
              </List>
            </div>

            {/* Data Sharing Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Data Sharing
              </Title>
              <Text>
                We do not share personal data with third parties except when required by law or with
                the user’s explicit consent. Internal access to data is restricted to authorized
                personnel who require it to perform their job duties.
              </Text>
            </div>

            {/* Data Retention Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Data Retention
              </Title>
              <Text>
                Personal data will be retained for as long as necessary to fulfill the purposes
                outlined in this policy or as required by law. Specifically:
              </Text>
              <List withPadding mt="sm">
                <List.Item>
                  User data will be retained for the duration of their system access.
                </List.Item>
                <List.Item>
                  Notification contacts will be retained until removed by the user or administrator.
                </List.Item>
              </List>
            </div>

            {/* Data Security Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Data Security
              </Title>
              <Text>
                We implement appropriate technical and organizational measures to ensure the
                security of personal data, including:
              </Text>
              <List withPadding mt="sm">
                <List.Item>Encryption of sensitive data in transit.</List.Item>
                <List.Item>Regular access control reviews.</List.Item>
                <List.Item>Secure storage systems.</List.Item>
              </List>
              <Text mt="sm">
                <Text fw="bold">Note:</Text> Sensitive data is not encrypted at rest; however, we
                apply robust physical and logical security measures to protect data from
                unauthorized access.
              </Text>
            </div>

            {/* Rights of Data Subjects Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Rights of Data Subjects
              </Title>
              <Text>Under GDPR, data subjects have the following rights:</Text>
              <List withPadding mt="sm">
                <List.Item>
                  Right to Access: Request access to the personal data we hold about you.
                </List.Item>
                <List.Item>
                  Right to Rectification: Request correction of inaccurate or incomplete data.
                </List.Item>
                <List.Item>
                  Right to Erasure: Request deletion of personal data, where applicable.
                </List.Item>
                <List.Item>
                  Right to Restrict Processing: Request limited processing of your data under
                  specific circumstances.
                </List.Item>
                <List.Item>
                  Right to Data Portability: Receive a copy of your data in a structured,
                  machine-readable format.
                </List.Item>
                <List.Item>
                  Right to Object: Object to data processing based on legitimate interests.
                </List.Item>
                <List.Item>
                  Right to Lodge a Complaint: File a complaint with the Swedish Data Protection
                  Authority (Integritetsskyddsmyndigheten, IMY).
                </List.Item>
              </List>
            </div>

            {/* Consent Management Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Consent Management
              </Title>
              <Text>
                For external notification contacts, consent will be obtained before their
                information is added to the system. Contacts can withdraw consent at any time by
                contacting us at the details provided below.
              </Text>
            </div>

            {/* Contact Information Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Contact Information
              </Title>
              <Text>
                If you have questions or wish to exercise your rights under GDPR, please contact us:
              </Text>
              <List withPadding mt="sm">
                {isMobile ? (
                  <>
                    <List.Item>
                      <Text fw="bold" component="span">
                        Email: <br />
                      </Text>
                      <Anchor href="mailto:portal.myramudden@ramuddenglobal.com">
                        portal.myramudden@
                        <br />
                        ramuddenglobal.com
                      </Anchor>
                    </List.Item>
                    <List.Item>
                      <Text fw="bold">Address:</Text> Birger Jarlsgatan 13
                      <br />
                      111 45 Stockholm
                    </List.Item>
                  </>
                ) : (
                  <>
                    <List.Item>
                      <Text fw="bold" component="span">
                        Email:
                      </Text>{' '}
                      <Anchor href="mailto:portal.myramudden@ramuddenglobal.com">
                        portal.myramudden@ramuddenglobal.com
                      </Anchor>
                    </List.Item>
                    <List.Item>
                      <Text fw="bold" component="span">
                        Address:
                      </Text>{' '}
                      Birger Jarlsgatan 13, 111 45 Stockholm
                    </List.Item>
                  </>
                )}
              </List>
            </div>

            {/* Updates to this Policy Section */}
            <div>
              <Title order={3} size="h4" mt="lg" mb="sm">
                Updates to this Policy
              </Title>
              <Text>
                We reserve the right to update this Privacy Policy as necessary to comply with
                legal, technical, or business developments. The latest version will always be
                accessible within the system.
              </Text>
            </div>

            {/* Consent Declaration */}
            <div>
              <Text mt="lg" fw="bold">
                Consent Declaration:
              </Text>
              <Text mt="sm">
                By using the system, you acknowledge that you have read and understood this Privacy
                Policy and consent to the processing of your personal data as outlined above.
              </Text>
            </div>
          </Stack>
        </Paper>
      </Center>
    </>
  );
}
