import { Loader, Select } from '@mantine/core';
import { Device } from '../../../../models/Device';
import { useState } from 'react';
import { DeviceInfo } from './DeviceInfo';
import useSearchUnassignedDevice from '../../../../data/hooks/Device/useSearchUnassignedDevices.ts';
import { useMsal } from '@azure/msal-react';

type Props = {
  onSelect: (device: Device) => void;
};

export const SelectDeviceInput = ({ onSelect }: Props) => {
  const { instance } = useMsal();
  const [searchString, setSearchString] = useState<string>('');
  const [selected, setSelected] = useState<Device | null>(null);

  const {
    devices,
    handleSearch: deviceSearch,
    isFetching: devicesFetching,
  } = useSearchUnassignedDevice(instance);

  return (
    <>
      <Select
        mb={'md'}
        label="Add device"
        ta={'left'}
        onChange={(id) => {
          const device = devices.find((device) => device.id === id);
          if (!device) setSelected(null);
          if (device) {
            setSelected(device);
            onSelect(device);
          }
        }}
        data={Object.values(devices).map((d) => ({
          value: d.id,
          label: d.referenceId,
        }))}
        searchable
        onSearchChange={(value) => {
          setSearchString(value);
          deviceSearch(value);
        }}
        clearable
        rightSection={devicesFetching && <Loader size={18} />}
        placeholder={'Search for device by reference id'}
        nothingFoundMessage={
          searchString.trim().length > 0 ? 'No device with that reference ID found...' : undefined
        }
      />
      {selected && <DeviceInfo device={selected} />}
    </>
  );
};
