import { Group, Text, MantineStyleProp, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

export enum BADGE_STATES {
  OK = 'ok',
  WARNING = 'warning',
  CRITICAL = 'critical',
  OFFLINE = 'offline',
}

interface GenericBadgeProps {
  state: BADGE_STATES;
  size: 'sm' | 'md';
  label: string;
  value?: string;
  icon?: ReactNode;
  fullWidth?: boolean;
}

export default function GenericBadge({
  icon,
  size,
  label,
  value,
  state,
  fullWidth,
}: GenericBadgeProps) {
  const theme = useMantineTheme();

  const STATES_STYLING = {
    ok: {
      backgroundColor: 'white',
      border: `${size === 'sm' ? 1 : 2}px solid ${theme.colors.successGreen[0]}`,
    },
    warning: {
      backgroundColor: '#ffae004d',
      border: `${size === 'sm' ? 1 : 2}px solid ${theme.colors.warningYellow[0]}`,
    },
    critical: {
      backgroundColor: '#e5340033',
      border: `${size === 'sm' ? 1 : 2}px solid ${theme.colors.dangerRed[0]}`,
    },
    offline: {
      backgroundColor: theme.colors.lightGrey[0],
      border: `${size === 'sm' ? 1 : 2}px solid ${theme.colors.greyText[0]}`,
    },
  };

  return (
    <Group
      px={size}
      py={size === 'sm' ? 'xs' : 'sm'}
      gap="sm"
      w={fullWidth ? '100%' : 'fit-content'}
      style={
        {
          ...STATES_STYLING[state],
          borderRadius: '50px',
        } as MantineStyleProp
      }
    >
      {icon}
      <Text size={size} c="black" fw={600}>
        {label}
      </Text>
      {value && (
        <Text size={size} c="black">
          {value}
        </Text>
      )}
    </Group>
  );
}
