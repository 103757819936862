import { Message } from '../providers/PWAProvider';

export function networkListener(
  setMessage: (message: Message | null) => void,
  setOffline: (offline: boolean) => void
) {
  // Called when internet is connected
  window.addEventListener('online', () => {
    setMessage({
      text: 'You are now back online and can continue scanning',
      title: 'Network connection re-established',
      type: 'info',
    });
    setOffline(false);
  });

  // Called when internet is disconnected:
  window.addEventListener('offline', () => {
    setMessage({
      text: "You're no longer connected to internet. Check you network connection and try again.",
      title: 'No network connection',
      type: 'error',
      indismissable: true,
    });
    setOffline(true);
  });
}

export function removeNetworkListener(setMessage: (message: Message | null) => void) {
  window.removeEventListener('online', () => setMessage);
  window.removeEventListener('offline', () => setMessage);
}
