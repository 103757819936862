import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  valueOveride?: string;
  onChange: (value: { label: string; id: string } | null) => void;
  fetchData: (query: string, signal: AbortSignal) => Promise<{ label: string; id: string }[]>;
}

export function AsyncInput({ label, placeholder, valueOveride, onChange, fetchData }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  useEffect(() => {
    if (valueOveride) {
      setValue(valueOveride);
    }
  }, [valueOveride]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ label: string; id: string }[] | null>(null);
  const [value, setValue] = useState('');
  const [empty, setEmpty] = useState(false);
  const abortController = useRef<AbortController>();

  const handleSearch = useDebouncedCallback(async (query: string) => {
    fetchOptions(query);
  }, 500);

  const fetchOptions = (query: string) => {
    if (query == '') {
      setValue('');
      onChange(null);
      setLoading(false);
      combobox.closeDropdown();
      return;
    }
    abortController.current?.abort();
    abortController.current = new AbortController();

    fetchData(query, abortController.current.signal)
      .then((result) => {
        setData(result);
        setLoading(false);
        setEmpty(result.length === 0);
        abortController.current = undefined;
      })
      .catch(() => {});
  };

  const options = (data || []).map((item) => (
    <Combobox.Option value={item.label} key={item.id}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        setValue(optionValue);
        onChange(data ? data.filter((item) => item.label == optionValue)[0] : null);
        combobox.closeDropdown();
      }}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            handleSearch(event.currentTarget.value);
            setLoading(true);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            combobox.openDropdown();
            if (data === null) {
              handleSearch(value);
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={loading && <Loader size={18} />}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>No results found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
