import {
  IconArrowAutofitContent,
  IconCurling,
  IconHelpHexagon,
  IconRouter,
  IconShadow,
  IconPlugConnected,
  IconSolarPanel2,
} from '@tabler/icons-react';
import { DeviceType } from '../../../../models/Device';

type Props = {
  size?: string;
  deviceType: DeviceType;
};

export const DeviceDefaultImage = ({ deviceType, size = '40px' }: Props) => {
  const map = {
    [DeviceType.Intellitag]: (
      <IconRouter size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.ArrowBoard]: <IconArrowAutofitContent size={size} stroke={1.5} />,
    [DeviceType.SmartCable]: (
      <IconPlugConnected size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.PSA]: <IconCurling size={size} stroke={1.5} />,
    [DeviceType.SmartSolar]: <IconSolarPanel2 size={size} stroke={1.5} />,
    [DeviceType.BeaconTracker]: (
      <IconShadow size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.Unknown]: <IconHelpHexagon size={size} stroke={1.5} />,
  };
  return map[deviceType] || map[DeviceType.Unknown];
};
