import { useEffect, useState } from 'react';
import { ActionIcon, Group, Text } from '@mantine/core';

import { getBrowser, getMobileOperatingSystem, getPWAStatus } from '../../utils/platform';
import styles from './Guide.module.css';
import { IconDotsVertical, IconShare2, IconSquarePlus, IconX } from '@tabler/icons-react';

export default function Guide() {
  const [mobileOs, setMobileOs] = useState('');
  const [showGuide, setShowGuide] = useState(false);
  const [guideSeen, setGuideSeen] = useState(false);

  const fetchGuideSeen = () => {
    const seen = localStorage.getItem('guide-seen');
    setGuideSeen(seen === 'true');
  };

  const closeGuide = () => {
    setShowGuide(false);
    localStorage.setItem('guide-seen', 'true');
  };

  useEffect(() => {
    fetchGuideSeen();
    const os = getMobileOperatingSystem();

    if (os === 'unknown' || getPWAStatus() === 'standalone') return;

    setMobileOs(os);
    setShowGuide(true);
  }, []);

  const getIcon = () => {
    const browser = getBrowser();
    if (mobileOs === 'ios' && (browser === 'safari' || browser === 'chrome')) return <IconShare2 />;

    if (mobileOs === 'android') return <IconDotsVertical />;
  };

  const getText = () => {
    // Cover standard cases, otherwise show default instructions
    if (mobileOs === 'ios' || mobileOs === 'android') {
      return (
        <Text c="white" flex={8}>
          Save app to Home Screen by pressing {getIcon()} and then <b>Add to Home Screen</b>.
        </Text>
      );
    }
    return <Text c="white">Save this app to your Home Screen for a native experience</Text>;
  };

  if (!showGuide || guideSeen) return null;

  return (
    <Group
      flex={1}
      pos="fixed"
      right={20}
      left={20}
      bg="deepGreen.9"
      className={styles.guide}
      p={20}
    >
      <IconSquarePlus color="white" size="50" stroke={1.2} />
      <Text c="white" flex={8}>
        {getText()}
      </Text>

      <ActionIcon pos="absolute" size="lg" onClick={closeGuide} top={-5} right={-5}>
        <IconX />
      </ActionIcon>
    </Group>
  );
}
