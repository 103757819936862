import { Group } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { PWAContext } from '../../providers/PWAProvider';

import { useLocation, useNavigate } from 'react-router-dom';
import { getPWAStatus } from '../../utils/platform';
import MainPageHeader from './components/MainPageHeader';
import SubPageHeader from './components/SubPageHeader';

export default function AppHeader() {
  const { device, setDevice, project } = useContext(PWAContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mainRoutes = [
    '/pwa/projects',
    `/pwa/projects/${project?.id}`,
    '/pwa/health-check',
    '/pwa/help',
  ];

  useEffect(() => {
    if (
      pathname === '/pwa/projects' ||
      pathname === '/pwa/projects/scanner' ||
      pathname === `/pwa/projects/${project?.id}` ||
      pathname === '/pwa/health-check'
    )
      setDevice(null);
  }, [pathname, project?.id, setDevice]);

  return (
    <Group
      mt={getPWAStatus() === 'standalone' ? 0 : 'md'}
      px={20}
      py="md"
      w="100%"
      justify="space-between"
    >
      {mainRoutes.includes(pathname) ? (
        <MainPageHeader />
      ) : (
        <SubPageHeader
          onBackClick={() =>
            pathname === '/pwa/projects/scanner'
              ? navigate(`/pwa/projects/${project?.id}`)
              : navigate(-1)
          }
          device={device}
        />
      )}
    </Group>
  );
}
