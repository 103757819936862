import { useState } from 'react';
import { Loader, Stack } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { DatePickerInput } from '@mantine/dates';
import { WorkZone } from '../../../models/Project';
import ReportOverview from './components/ReportOverview.tsx';
import useGetSupervisionReport from '../../../data/hooks/SupervisionReport/useGetSupervisionReportHook.ts';
import { supervisionReportIntervals } from '../../../config/supervisionReportIntervals.ts';
import { getEndOfDay } from '../../../utils/utils.tsx';
import useDepots from '../../../data/hooks/Depots/useDepots.tsx';

export const DEFAULT_NUMBER_OF_DAYS = 7;

interface Props {
  project: WorkZone;
}

export default function Reports({ project }: Props) {
  const { instance } = useMsal();

  const { depotsNameMap } = useDepots(instance);
  const { startDate, endDate } = getInitialReportDates(project);
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([startDate, endDate]);

  const intervalInHours = supervisionReportIntervals[project.countryCode] ?? '08:00:00';
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - DEFAULT_NUMBER_OF_DAYS);
  sevenDaysAgo.setHours(0, 0, 0, 0);
  today.setHours(23, 59, 59, 999);

  const { supervisionReport, isLoading: supervisionReportsLoading } = useGetSupervisionReport({
    instance: instance,
    workZoneId: project.id,
    startTime:
      dateValue[0] && dateValue[1] ? dateValue[0].toISOString() : sevenDaysAgo.toISOString(),
    endTime:
      dateValue[0] && dateValue[1] ? getEndOfDay(dateValue[1]).toISOString() : today.toISOString(),
    interval: intervalInHours,
  });

  return (
    <Stack py="md">
      <DatePickerInput
        maw="300px"
        type="range"
        label="Select date range"
        placeholder="Pick date range"
        value={dateValue}
        onChange={(val) => setDateValue(val)}
        maxDate={new Date()}
        allowSingleDateInRange
      />
      {supervisionReportsLoading ? (
        <Loader mt="xl" mx="auto" />
      ) : (
        <ReportOverview
          projectName={project.name}
          projectId={project.id}
          reportData={supervisionReport}
          depotName={depotsNameMap.get(project.depotId)}
          intervalInHours={intervalInHours}
          dateValue={dateValue}
          startDate={startDate}
          endDate={endDate}
          countryCode={project.countryCode}
        />
      )}
    </Stack>
  );
}

const getInitialReportDates = (project: WorkZone): { startDate: Date; endDate: Date } => {
  const defaultDays = DEFAULT_NUMBER_OF_DAYS;

  // Set endDate based on whether the project is closed
  const endDate = project.isClosed && project.closedAt ? new Date(project.closedAt) : new Date();

  // Set startDate relative to endDate
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - defaultDays);
  startDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds

  return { startDate, endDate };
};
