import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DeviceRow } from '../../../../models/SupervisionReport';
import {
  AlarmStatus,
  EquipmentType,
  PoweredEquipment,
  PoweredEquipmentNames,
} from '../../../../models/enums/DeviceEnums';
import circleAlert from '../../../../assets/report/StatusIcons/alert-circle.png';
import circleCheck from '../../../../assets/report/StatusIcons/circle-check.png';
import { PDF_CONSTANTS, addHeader } from './ExportPdfCommon';
import { formatDate, formatTimeByCountry } from '../../../../utils/utils';
import { ReportType } from '../../../../models/enums/ReportType';

const ALARM_TABLE_FONT_SIZE = 8;
const ALARM_ICON_SIZE = 6;
const ALARM_TABLE_CELL_HEIGHT = 12;
const MAX_IMAGE_HEIGHT = 15; // Maximum height in PDF units
const MAX_IMAGE_WIDTH = 15; // Maximum width in PDF units

interface AlarmEvent {
  alarmReported: string;
  alarmAddressed: string;
}

interface DeviceAlarmEvents {
  referenceId: string;
  name: string;
  attachmentRef: string;
  currentEquipmentType: EquipmentType;
  poweredEquipment?: PoweredEquipment;
  events: AlarmEvent[];
}

interface TableRow {
  referenceId: string;
  name: string;
  attachment: string | PoweredEquipment;
  alarmReported: string;
  alarmAddressed: string;
}

function processAlarmEvents(rows: DeviceRow[]): DeviceAlarmEvents[] {
  const deviceEvents = new Map<string, DeviceAlarmEvents>();

  rows.forEach((row) => {
    let lastAlarmStatus: AlarmStatus | null = null;

    // Initialize device entry if not exists
    if (!deviceEvents.has(row.referenceId)) {
      deviceEvents.set(row.referenceId, {
        referenceId: row.referenceId,
        name: row.name,
        attachmentRef: row.attachmentRef,
        currentEquipmentType: row.currentEquipmentType,
        events: [],
      });
    }

    const device = deviceEvents.get(row.referenceId);
    if (!device) return;

    // Process all logs chronologically
    const allLogs = row.entries
      .flatMap((entry) => entry.logs)
      .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());

    allLogs.forEach((log, index) => {
      // For first log, just set the initial status
      if (index === 0) {
        lastAlarmStatus = log.alarmStatus;
        // If first log is alarming, create an event
        if (log.alarmStatus === AlarmStatus.Alarming) {
          device.events.push({
            alarmReported: log.timeStamp,
            alarmAddressed: '',
          });
        }
        return;
      }

      // For subsequent logs, check for status changes
      if (log.alarmStatus !== lastAlarmStatus) {
        if (log.alarmStatus === AlarmStatus.Alarming) {
          // Sign fell down - create new event with only reported time
          device.events.push({
            alarmReported: log.timeStamp,
            alarmAddressed: '',
          });
        } else if (log.alarmStatus === AlarmStatus.OK && lastAlarmStatus === AlarmStatus.Alarming) {
          // Find the latest unaddressed alarm event for this device
          const lastUnaddressedEvent = device.events
            .reverse()
            .find((event) => event.alarmAddressed === '');
          device.events.reverse(); // Restore original order

          if (lastUnaddressedEvent) {
            // Update the existing event with the addressing time
            lastUnaddressedEvent.alarmAddressed = log.timeStamp;
          }
        }
      }
      lastAlarmStatus = log.alarmStatus;
    });
  });

  // Convert map to array and sort devices by reference ID
  return Array.from(deviceEvents.values())
    .filter((device) => device.events.length > 0)
    .map((device) => ({
      ...device,
      // Sort events in descending order by alarmReported date
      events: device.events.sort(
        (a, b) => new Date(b.alarmReported).getTime() - new Date(a.alarmReported).getTime()
      ),
    }))
    .sort((a, b) => a.referenceId.localeCompare(b.referenceId));
}

function formatTimeWithDate(
  dateString: string,
  countryCode: string,
  reportType?: ReportType
): string {
  if (!dateString) return '-';

  const date = new Date(dateString);
  const day = date.toLocaleDateString('en-US', { weekday: 'short' });
  const dayMonth = date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
  const time = formatTimeByCountry(dateString, countryCode);

  if (reportType === ReportType.SmartCables) {
    return `${day}, ${dayMonth}`;
  }
  return `${day}, ${dayMonth}, ${time}`;
}

export interface AddAlarmEventsTableProps {
  doc: jsPDF;
  rows: DeviceRow[];
  projectName: string;
  startDate: Date;
  endDate: Date;
  imageMap: Map<string, HTMLImageElement>;
  reportType: ReportType;
  countryCode: string;
}

export function addAlarmEventsTable({
  doc,
  rows,
  projectName,
  startDate,
  endDate,
  imageMap,
  reportType,
  countryCode,
}: AddAlarmEventsTableProps): void {
  const deviceAlarms = processAlarmEvents(rows);
  if (deviceAlarms.length === 0) return;

  // Store header positions for redrawing later
  const headerPositions: {
    x: number;
    y: number;
    width: number;
    height: number;
    text: string;
  }[] = [];

  // Add a new page for alarm events
  doc.addPage();

  // Add header to new page
  addHeader(doc, {
    projectName,
    title: 'Alarm Events',
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
  });

  // Calculate column widths based on total table width
  const tableWidth = doc.internal.pageSize.getWidth() - PDF_CONSTANTS.SIDE_MARGIN * 2;

  // Total proportion is 0.75, so multiply each by (1/0.75) to make it span full width
  const scaleFactor = 1 / 0.75;
  const colWidth = {
    refId: tableWidth * 0.15 * scaleFactor, // 20% of available width
    info: tableWidth * 0.2 * scaleFactor, // 26.7% of available width
    attach: tableWidth * 0.1 * scaleFactor, // 13.3% of available width
    reported: tableWidth * 0.15 * scaleFactor, // 20% of available width
    addressed: tableWidth * 0.15 * scaleFactor, // 20% of available width
  };

  // Create table body with grouped events
  const tableBody: TableRow[] = [];

  deviceAlarms.forEach((device) => {
    if (device.events.length > 0) {
      // Add first row with device info and first event
      tableBody.push({
        referenceId: device.referenceId,
        name: device.name,
        attachment:
          reportType === ReportType.SmartCables
            ? device.poweredEquipment !== undefined && device.poweredEquipment !== null
              ? PoweredEquipmentNames[device.poweredEquipment]
              : '-'
            : device.attachmentRef ||
              (device.currentEquipmentType === EquipmentType.Barrier ? 'Barrier' : 'Sign'),
        alarmReported: formatTimeWithDate(device.events[0].alarmReported, countryCode, reportType),
        alarmAddressed: formatTimeWithDate(
          device.events[0].alarmAddressed,
          countryCode,
          reportType
        ),
      });

      // Add remaining events with empty device info
      device.events.slice(1).forEach((event) => {
        tableBody.push({
          referenceId: '',
          name: '',
          attachment: '',
          alarmReported: formatTimeWithDate(event.alarmReported, countryCode, reportType),
          alarmAddressed: formatTimeWithDate(event.alarmAddressed, countryCode, reportType),
        });
      });
    }
  });

  // Add alarm events table
  let tableStartY = 0;
  let tableEndY = 0;

  const tableHeaders = [
    'Reference ID',
    'Additional info',
    reportType === ReportType.SmartCables ? 'Powered Equipment' : 'Attachment',
    'Alarm reported',
    'Alarm addressed',
  ];

  autoTable(doc, {
    head: [tableHeaders],
    body: tableBody.map((row) => [
      row.referenceId,
      row.name,
      row.attachment,
      row.alarmReported,
      row.alarmAddressed,
    ]),
    margin: {
      left: PDF_CONSTANTS.SIDE_MARGIN,
      right: PDF_CONSTANTS.SIDE_MARGIN,
      top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG + 5,
    },
    styles: {
      cellPadding: 4,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT,
      lineWidth: 0,
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
    },
    theme: 'plain',
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
    columnStyles: {
      0: { cellWidth: colWidth.refId, halign: 'center', valign: 'middle' },
      1: {
        cellWidth: colWidth.info,
        overflow: 'ellipsize' as const,
        halign: 'center',
        valign: 'middle',
      },
      2: { cellWidth: colWidth.attach, halign: 'center', valign: 'middle' },
      3: { cellWidth: colWidth.reported, halign: 'center', valign: 'middle' },
      4: { cellWidth: colWidth.addressed, halign: 'center', valign: 'middle' },
    },
    headStyles: {
      fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
      textColor: '#FFFFFF',
      fontStyle: 'bold',
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT * 0.8,
      cellPadding: 2,
      halign: 'center',
      valign: 'middle',
    },
    bodyStyles: {
      textColor: '#000000',
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT * 0.8,
      cellPadding: 2,
      halign: 'center',
      valign: 'middle',
    },
    didParseCell: (data) => {
      // Only clear text for attachment cells when not SmartCables
      if (
        data.column.index === 2 &&
        data.cell.section === 'body' &&
        reportType !== ReportType.SmartCables
      ) {
        data.cell.text = [];
      }
      // Clear text for alarm cells since we handle it manually
      if (data.column.index >= 3 && data.cell.section === 'body') {
        data.cell.text = [];
      }
    },
    didDrawCell: (data) => {
      // Store header positions for redrawing later
      if (data.cell.section === 'head') {
        headerPositions.push({
          x: data.cell.x,
          y: data.cell.y,
          width: data.cell.width,
          height: data.cell.height,
          text: data.cell.raw as string,
        });
      }

      // Add attachment image
      if (data.column.index === 2 && data.cell.section === 'body' && data.cell.raw) {
        const image = imageMap.get(data.cell.raw as string);
        if (!image) return;

        const ratio = image.naturalWidth / image.naturalHeight;

        // Calculate dimensions while maintaining aspect ratio and respecting maximums
        let width, height;
        if (ratio > 1) {
          // Wider than tall
          width = Math.min(MAX_IMAGE_WIDTH, data.cell.width * 0.8);
          height = width / ratio;
        } else {
          // Taller than wide
          height = Math.min(MAX_IMAGE_HEIGHT, data.cell.height * 0.8);
          width = height * ratio;
        }

        // Center in cell
        const x = data.cell.x + (data.cell.width - width) / 2;
        const y = data.cell.y + (data.cell.height - height) / 2;

        if (!isNaN(x) && !isNaN(y) && !isNaN(width) && !isNaN(height)) {
          doc.addImage(image, x, y, width, height);
        } else {
          console.error('Invalid dimensions for attachment image');
        }
      }

      // Store table boundaries
      if (data.row.index === 0 && data.column.index === 0) {
        tableStartY = data.cell.y;
      }

      // Handle alternating backgrounds and icons for alarm columns
      if (data.cell.section === 'body') {
        if (data.column.index >= 3) {
          // Draw alternating background only for alarm columns
          const fillColor = data.row.index % 2 === 0 ? [248, 248, 248] : [255, 255, 255];
          doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);

          // Leave a gap at both top and bottom
          const gapSize = 0.5;
          doc.rect(
            data.cell.x,
            data.cell.y + gapSize,
            data.cell.width,
            data.cell.height - gapSize * 2,
            'F'
          );

          const row = data.row.raw as string[];
          if (row[3] || row[4]) {
            // Add alarm icons with proper vertical centering
            const img = new Image();
            img.src = data.column.index === 3 ? circleAlert : circleCheck;
            const x = data.cell.x + 8;
            const y = data.cell.y + (data.cell.height - ALARM_ICON_SIZE) / 2;
            doc.addImage(img, x, y, ALARM_ICON_SIZE, ALARM_ICON_SIZE);

            // Add text with padding, vertically centered
            doc.setFontSize(ALARM_TABLE_FONT_SIZE);
            doc.setTextColor(0, 0, 0);
            doc.text(
              row[data.column.index],
              data.cell.x + ALARM_ICON_SIZE + 12,
              data.cell.y + data.cell.height / 2,
              { align: 'left', baseline: 'middle' }
            );
          }

          // Clear the default text rendering since we handle it manually
          data.cell.text = [];
        }
      }

      // Add single horizontal line between device groups
      if (data.cell.section === 'body' && data.column.index === 4) {
        let currentRow = 0;
        for (const device of deviceAlarms) {
          currentRow += device.events.length;
          if (data.row.index === currentRow - 1 && currentRow < tableBody.length) {
            // Draw a single horizontal line after the device group
            doc.setDrawColor(220, 220, 220); // Lighter gray color
            doc.setLineWidth(0.1); // Slightly thicker line
            doc.line(
              PDF_CONSTANTS.SIDE_MARGIN, // Start from left margin
              data.cell.y + data.cell.height,
              doc.internal.pageSize.getWidth() - PDF_CONSTANTS.SIDE_MARGIN, // End at right margin
              data.cell.y + data.cell.height
            );
            break;
          }
        }
      }

      // If this is the last cell, draw border and headers
      if (
        data.cell.section === 'body' &&
        data.column.index === 4 &&
        data.row.index === tableBody.length - 1
      ) {
        tableEndY = data.cell.y + data.cell.height;

        // Draw border around the entire table
        doc.setDrawColor(220, 220, 220);
        doc.setLineWidth(0.1);
        doc.rect(PDF_CONSTANTS.SIDE_MARGIN, tableStartY, tableWidth, tableEndY - tableStartY);

        // Redraw all headers
        headerPositions.forEach((header) => {
          // Draw header background
          doc.setFillColor(PDF_CONSTANTS.COLORS.GREEN_PRIMARY); // Use the same green color
          doc.rect(header.x, header.y, header.width, header.height, 'F');

          // Draw header text
          doc.setFontSize(ALARM_TABLE_FONT_SIZE);
          doc.setTextColor(255, 255, 255);
          doc.setFont('helvetica', 'bold');
          doc.text(header.text, header.x + header.width / 2, header.y + header.height / 2, {
            align: 'center',
            baseline: 'middle',
          });
        });
      }
    },
  });
}
