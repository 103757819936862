import { useContext, useState } from 'react';
import { Select, Button, Group, Loader } from '@mantine/core';

import { PWAContext } from '../../../providers/PWAProvider';
import { useMsal } from '@azure/msal-react';
import useSearchDevice from '../../../../data/hooks/Device/useSearchDevices';

interface Props {
  buttonLabel: string;
  handleConnection: (refId: string) => void;
  isLoading: boolean;
  trackingId: string;
}

export default function ManualConnection({
  buttonLabel,
  handleConnection,
  isLoading,
  trackingId,
}: Props) {
  const { instance } = useMsal();

  const { offline } = useContext(PWAContext);

  const [searchString, setSearchString] = useState<string>('');
  const [selectedReferenceId, setSelectedReferenceId] = useState<string | null>(null);

  const {
    devices,
    handleSearch: deviceSearch,
    isFetching: devicesFetcing,
    isLoading: devicesLoading,
  } = useSearchDevice(instance);

  const handleConfigure = () => {
    if (selectedReferenceId) {
      if (window._paq) {
        window._paq.push(['trackEvent', trackingId, 'Manual connection']);
      }
      handleConnection(selectedReferenceId);
      setSelectedReferenceId(null);
    }
  };

  return (
    <Group>
      <Select
        ta="left"
        flex={1}
        size="md"
        value={selectedReferenceId}
        data={Object.values(devices).map((d) => ({
          value: d.id,
          label: d.referenceId,
        }))}
        searchable
        searchValue={selectedReferenceId ? selectedReferenceId : searchString}
        onSearchChange={(value) => {
          setSearchString(value);
          deviceSearch(value);
        }}
        onChange={(_value, options) => {
          if (options) {
            setSelectedReferenceId(options.label);
          }
        }}
        onClear={() => {
          setSelectedReferenceId(null), setSearchString('');
        }}
        clearable
        rightSection={devicesFetcing && <Loader size={18} />}
        placeholder="Enter reference ID"
        nothingFoundMessage={
          searchString.trim().length > 1 && !devicesFetcing && !devicesLoading
            ? 'No device with that reference ID found...'
            : undefined
        }
        disabled={offline}
      />

      <Button
        size="md"
        disabled={offline || !selectedReferenceId}
        data-testid="btn-configure"
        onClick={handleConfigure}
        loading={isLoading}
      >
        {buttonLabel}
      </Button>
    </Group>
  );
}
