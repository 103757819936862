import arrow_left from '../assets/ArrowBoardIcons/arrow_board_left.png';
import arrow_right from '../assets/ArrowBoardIcons/arrow_board_right.png';
import arrow_left_right from '../assets/ArrowBoardIcons/arrow_board_double_arrow.png';
import arrow_bar from '../assets/ArrowBoardIcons/arrow_board_bar_only.png';
import arrow_off from '../assets/ArrowBoardIcons/arrow_board_off.png';
import arrow_unknown from '../assets/ArrowBoardIcons/arrow_board_unkown.png';
import psa_blue from '../assets/PSA/psa_blue.png';
import psa_red from '../assets/PSA/psa_red.png';
import psa_orange from '../assets/PSA/psa_orange.png';
import psa_yellow from '../assets/PSA/psa_yellow.png';
import SequentialLights from '../assets/PoweredEquipment/sequential_lights.png';
import Buffer from '../assets/PoweredEquipment/buffer.png';
import TrafficLight from '../assets/PoweredEquipment/traffic-light.png';
import WarningLight from '../assets/PoweredEquipment/warning-light.png';

import { ARROW_DIRECTION, PSAModel } from '../models/Device';
import { PoweredEquipment } from '../models/enums/DeviceEnums';
import { IconPlugConnected } from '@tabler/icons-react';

export const getArrowDirectionImage = (direction: ARROW_DIRECTION | null): string | null => {
  if (!direction) return null;
  const map = {
    [ARROW_DIRECTION.Left]: arrow_left,
    [ARROW_DIRECTION.Right]: arrow_right,
    [ARROW_DIRECTION.LeftRight]: arrow_left_right,
    [ARROW_DIRECTION.Bar]: arrow_bar,
    [ARROW_DIRECTION.Off]: arrow_off,
    [ARROW_DIRECTION.Unknown]: arrow_unknown,
  };
  return map[direction];
};

export const POWERED_EQUIPMENT_IMAGE_MAP = {
  [PoweredEquipment.Buffer]: Buffer,
  [PoweredEquipment.Other]: IconPlugConnected,
  [PoweredEquipment.SequentialLight]: SequentialLights,
  [PoweredEquipment.TrafficLight]: TrafficLight,
  [PoweredEquipment.WarningLight]: WarningLight,
  [PoweredEquipment.Unknown]: IconPlugConnected,
};

export const getPoweredEquipmentImage = (equipment: PoweredEquipment) => {
  if (!equipment) return null;
  return POWERED_EQUIPMENT_IMAGE_MAP[equipment];
};

export const getPSAImage = (deviceModel: string): string | undefined => {
  if (!deviceModel) return;
  const map = {
    [PSAModel.PsaBlue]: psa_blue,
    [PSAModel.PsaRed]: psa_red,
    [PSAModel.PsaOrange]: psa_orange,
    [PSAModel.PsaYellow]: psa_yellow,
  };
  return map[deviceModel as PSAModel];
};

export const getImageUrlForSign = (roadSign?: string, addCorsParameter?: boolean) =>
  roadSign
    ? `https://globalplatformprodblob.blob.core.windows.net/roadsigns-20240710/${roadSign}.png${addCorsParameter ? `?r=${Math.floor(Math.random() * 100000)}` : ''}`
    : null;
