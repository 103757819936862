import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EquipmentType, PoweredEquipment } from '../../../models/enums/DeviceEnums';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Params {
  projectId: string;
  deviceId: string;
  equipmentType: EquipmentType | null;
  currentName: string | null;
  attachmentRef: string | null;
  poweredEquipment: PoweredEquipment | null;
}

const path = `/device/assign-to-project`;

const useAssignDeviceToProject = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: (params: Params) => {
      return postData<Params>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error assigning device to project`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
    },
  });

  return {
    mutate,
    mutateAsync,
    isPending,
    isError,
    isSuccess,
  };
};

export default useAssignDeviceToProject;
