import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CreateWorkZone } from '../../../models/Project';

const path = `/workZone`;

const useCreateProject = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError } = useMutation({
    mutationFn: (params: CreateWorkZone) => {
      return postData<CreateWorkZone>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error creating project`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
  };
};

export default useCreateProject;
