import { Point } from 'geojson';
import {
  AlarmStatus,
  BatteryStatus,
  EquipmentType,
  LifeCycleStatus,
  PoweredEquipment,
} from './enums/DeviceEnums';

export interface BaseDevice {
  id: string;
  referenceId: string;
  deviceType: DeviceType;
  currentName: string;
  position: Point;
  batteryVoltage: number;
  batteryStatus: BatteryStatus;
  latestUpdate: string;
  workZoneName: string;
  workZoneId: string;
  currentConfigId: string;
  requestedConfigId: string;
  currentConfigTimestamp: Date;
  requestedConfigTimestamp: Date;
  estimatedBatteryEndDate: string;
  imei: string;
  alarmStatus: AlarmStatus;
  assignedAtTimestamp: Date;
  lifeCycleStatus: LifeCycleStatus;
  availableForAssignment: boolean;
  deviceModel: string;
}

export interface Intellitag {
  currentEquipmentType: EquipmentType | null;
  attachmentRef: string;
  inPowerSavingMode: boolean;
}

export interface ArrowBoard {
  arrowDirection: ARROW_DIRECTION;
  externalBatteryVoltage: number;
}

export interface SmartCable {
  poweredEquipment: PoweredEquipment;
  externalBatteryVoltage: number;
}

export interface SmartSolar {
  poweredEquipment: PoweredEquipment;
  externalBatteryVoltage: number;
}

export interface PortableSiteAlarm {}

export enum DeviceType {
  Unknown = 0,
  Intellitag = 1,
  ArrowBoard = 2,
  SmartCable = 3,
  PSA = 4,
  SmartSolar = 5,
  BeaconTracker = 9999,
}

export enum PSAModel {
  PsaRed = 'PsaRed',
  PsaYellow = 'PsaYellow',
  PsaBlue = 'PsaBlue',
  PsaOrange = 'PsaOrange',
}

export enum ARROW_DIRECTION {
  Unknown = 0,
  Off = 1,
  Left = 2,
  Right = 3,
  Bar = 4,
  LeftRight = 5,
}

export const DeviceTypeNames = {
  [DeviceType.Intellitag]: 'Intellitag',
  [DeviceType.ArrowBoard]: 'Arrowboard',
  [DeviceType.SmartCable]: 'Smart Cable',
  [DeviceType.PSA]: 'Portable Site Alarm',
  [DeviceType.SmartSolar]: 'Smart Solar',
  [DeviceType.BeaconTracker]: 'Beacon Tracker',
  [DeviceType.Unknown]: 'Unknown',
};

export const ARROW_DIRECTION_NAMES = {
  [ARROW_DIRECTION.Unknown]: 'Unknown',
  [ARROW_DIRECTION.Off]: 'Off',
  [ARROW_DIRECTION.Left]: 'Left',
  [ARROW_DIRECTION.Right]: 'Right',
  [ARROW_DIRECTION.Bar]: 'Bar',
  [ARROW_DIRECTION.LeftRight]: 'Left & Right',
};

export interface Device
  extends BaseDevice,
    Intellitag,
    ArrowBoard,
    SmartCable,
    SmartSolar,
    PortableSiteAlarm {}
