export default function BarrierSvg() {
  return (
    <svg
      width="247"
      height="79"
      viewBox="0 0 247 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="223" height="47" transform="translate(11 6.5)" fill="#BFBFBF" />
      <rect x="31" y="22.5" width="53" height="15" fill="#8E8E8E" />
      <rect x="161" y="22.5" width="53" height="15" fill="#8E8E8E" />
      <path d="M0 79L10.5 52H233L246.5 79H0Z" fill="#BFBFBF" />
      <path d="M25.5 0L11 7H233L218 0H25.5Z" fill="#8E8E8E" />
    </svg>
  );
}
