import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { WorkZone } from '../../../models/Project';
import { prepareHeaders } from '../../api/apiHelpers';

interface Params {
  instance: IPublicClientApplication;
  workZoneId: string;
}
const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = `/workZone`;

const useProject = ({ instance, workZoneId }: Params) => {
  const { data, isFetching, isError, error } = useQuery<WorkZone>({
    queryKey: ['projects', workZoneId],
    throwOnError: true,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await fetch(`${baseUrl}${path}/${workZoneId}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    },
  });

  return {
    project: data,
    isFetching,
    isError,
    error,
  };
};

export default useProject;
