import { IPublicClientApplication } from '@azure/msal-browser';
import { Employee } from '../models/Employee.ts';
import { api } from './api.ts';
import { UserInformationDto } from '../models/User.ts';

const employees_resource = 'Employees';
const user_search_resource = 'User/search';

export async function getEmployees(
  instance: IPublicClientApplication,
  nameStartsWith: string
): Promise<Employee[]> {
  const data = await api.get(instance, employees_resource, {
    nameStartsWith: nameStartsWith,
  });
  return (await data.json()) as Employee[];
}

export async function getUsers(
  instance: IPublicClientApplication,
  nameContains: string
): Promise<UserInformationDto[]> {
  const data = await api.get(instance, user_search_resource, {
    nameContains: nameContains,
  });
  return (await data.json()) as UserInformationDto[];
}
