import { Stack, Card, Group, Text, Divider, Alert } from '@mantine/core';
import { BatteryBadge, StatusBadge } from '../Badge';
import { useMsal } from '@azure/msal-react';
import { BatteryStatus } from '../../models/enums/DeviceEnums';
import useInfDeviceLogList from '../../data/hooks/DeviceLog/useInfDeviceLogListHook';
import React from 'react';
import useDevice from '../../data/hooks/Device/useDeviceHook.ts';
import { DeviceType } from '../../models/Device.ts';
import { ArrowBoardDeviceLogTableRow } from './ArrowBoard/ArrowBoardDeviceLogTableRow.tsx';
import { IntelliTagDeviceLogTableRow } from './IntelliTag/IntelliTagDeviceLogTableRow.tsx';
import { SmartCableDeviceLogTableRow } from './SmartCable/SmartCableDeviceLogTableRow.tsx';
import { SmartSolarDeviceLogTableRow } from './SmartSolar/SmartSolarDeviceLogTableRow.tsx';

interface DeviceLogsTableProps {
  deviceId: string;
}

const DeviceLogsTable = (props: DeviceLogsTableProps) => {
  const { instance } = useMsal();
  const deviceId = props.deviceId;
  const { device: device } = useDevice({ instance, deviceId });
  const { deviceLogs, isError: error } = useInfDeviceLogList({
    instance,
    deviceId: props.deviceId,
  });

  return (
    <div>
      <Stack gap="md">
        {error ? (
          <Alert title="Error" color="red">
            {error.toString()}
          </Alert>
        ) : (
          deviceLogs.map((log, index) => (
            <Card key={index} shadow="sm" padding="sm">
              <Group
                p="apart"
                style={{
                  width: '100%',
                  marginBottom: 5,
                  justifyContent: 'space-between',
                }}
              >
                <Text>{new Date(log.timestamp).toLocaleString()}</Text>
                <Group gap={'xs'}>
                  <StatusBadge status={log.alarmStatus} />
                  <BatteryBadge
                    batteryStatus={log.batteryStatus == BatteryStatus.OK ? null : log.batteryStatus}
                  />
                </Group>
              </Group>
              <Divider />
              <Text m="xs" size="sm">
                <strong>Project: </strong>
                {log.workZoneName ?? '-'}
              </Text>
              {device?.deviceType === DeviceType.ArrowBoard && (
                <>{ArrowBoardDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.Intellitag && (
                <>{IntelliTagDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.SmartCable && (
                <>{SmartCableDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.SmartSolar && (
                <>{SmartSolarDeviceLogTableRow(log)}</>
              )}

              {!device?.deviceType && (
                <Group p="apart" gap="m" m="xs">
                  <Text size="sm">
                    <strong>Battery (V): </strong>
                    {log.batteryVoltage ?? '-'}
                  </Text>
                </Group>
              )}
            </Card>
          ))
        )}
      </Stack>
    </div>
  );
};

export default React.memo(DeviceLogsTable);
