import { Stack } from '@mantine/core';
import Row from './Row/Row';
import {
  EquipmentType,
  PoweredEquipment,
  PoweredEquipmentNames,
} from '../../models/enums/DeviceEnums';
import PwaPageHeading from './PwaPageHeading';
import { DeviceType, DeviceTypeNames } from '../../models/Device';

interface Props {
  projectName: string;
  referenceID: string;
  deviceType: DeviceType | undefined;
  equipment?: { type: EquipmentType | null; name?: string; value?: string };
  poweredEquipment: PoweredEquipment | null;
  additionalInfo?: string;
}

export default function DeviceSummary({
  projectName,
  referenceID,
  deviceType,
  equipment,
  poweredEquipment,
  additionalInfo,
}: Props) {
  return (
    <Stack gap="sm">
      <PwaPageHeading>Device summary</PwaPageHeading>

      <Stack
        style={{
          border: '1px solid #8E8E8E',
          borderRadius: 4,
          overflow: 'auto',
        }}
        p={24}
      >
        <Row label="Project" text={projectName} />
        {deviceType && <Row label="Device type" text={DeviceTypeNames[deviceType]} />}
        {equipment?.type && (
          <Row
            label="Equipment type"
            text={EquipmentType[equipment?.type]}
            signCode={equipment?.value}
          />
        )}
        {poweredEquipment && (
          <Row
            label="Powered equipment"
            text={PoweredEquipmentNames[poweredEquipment]}
            signCode={equipment?.value}
          />
        )}
        {additionalInfo && <Row label="Additional info" text={additionalInfo} />}
        <Row label="Reference ID" text={referenceID} />
      </Stack>
    </Stack>
  );
}
