import { Modal, Radio, Stack, TextInput, Divider, Tabs } from '@mantine/core';
import { IconUsers, IconUserPlus } from '@tabler/icons-react';
import { useMobileDevice } from '../../../../hooks/useMobileDevice.tsx';
import { ConfirmButtonGroup } from '../../../../shared/ConfirmButtonGroup/ConfirmButtonGroup.tsx';
import { useState } from 'react';
import { SearchEmployee } from '../../../SearchEmployee/SearchEmployee';
import { Employee } from '../../../../models/Employee';
import { ReportType, getReportDisplayName } from '../../../../models/enums/ReportType';

interface Props {
  isOpen: boolean;
  close: () => void;
  onExport: (type: ReportType, contactPerson: ContactPerson) => void;
  hasIntellitagsData: boolean;
  hasArrowboardsData: boolean;
  hasSmartCablesData: boolean;
  projectContactPerson?: ContactPerson;
  fetchEmployees: (query: string) => Promise<Employee[]>;
}

interface ContactPerson {
  name: string;
  email: string;
  phone: string;
}

export const ExportReportModal = ({
  isOpen,
  close,
  onExport,
  hasIntellitagsData,
  hasArrowboardsData,
  hasSmartCablesData,
  projectContactPerson,
  fetchEmployees,
}: Props) => {
  const isMobile = useMobileDevice();
  const getInitialReportType = (): ReportType => {
    if (hasIntellitagsData) return ReportType.Intellitags;
    if (hasArrowboardsData) return ReportType.Arrowboards;
    return ReportType.SmartCables;
  };

  const [selectedDashboard, setSelectedDashboard] = useState<ReportType>(getInitialReportType());
  const [contactType, setContactType] = useState<'internal' | 'external'>('internal');
  const [internalContact, setInternalContact] = useState<ContactPerson>({
    name: '',
    email: '',
    phone: '',
  });
  const [externalContact, setExternalContact] = useState<ContactPerson>(
    projectContactPerson || {
      name: '',
      email: '',
      phone: '',
    }
  );

  const handleRadioChange = (value: string) => {
    setSelectedDashboard(value as ReportType);
  };

  const handleExport = () => {
    const contactPerson = contactType === 'internal' ? internalContact : externalContact;
    onExport(selectedDashboard, contactPerson);
    close();
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={close}
      fullScreen={isMobile}
      size="md"
      centered
      styles={{
        content: {
          minHeight: '500px',
        },
        body: {
          paddingBottom: 0,
        },
      }}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Export pdf report</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Stack gap="md" pb="80px">
              <Radio.Group
                value={selectedDashboard}
                onChange={handleRadioChange}
                name="dashboard"
                label="Select dashboard to export"
              >
                <Stack>
                  <Radio
                    value={ReportType.Intellitags}
                    label={getReportDisplayName(ReportType.Intellitags)}
                    disabled={!hasIntellitagsData}
                  />
                  <Radio
                    value={ReportType.Arrowboards}
                    label={getReportDisplayName(ReportType.Arrowboards)}
                    disabled={!hasArrowboardsData}
                  />
                  <Radio
                    value={ReportType.SmartCables}
                    label={getReportDisplayName(ReportType.SmartCables)}
                    disabled={!hasSmartCablesData}
                  />
                </Stack>
              </Radio.Group>

              <Divider label="Contact Information" labelPosition="center" />

              <Tabs
                value={contactType}
                onChange={(value) => setContactType(value as 'internal' | 'external')}
              >
                <Tabs.List>
                  <Tabs.Tab value="internal" leftSection={<IconUsers size={16} />}>
                    Internal Contact
                  </Tabs.Tab>
                  <Tabs.Tab value="external" leftSection={<IconUserPlus size={16} />}>
                    External Contact
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="internal">
                  <Stack mt="md" style={{ minHeight: '250px' }}>
                    <SearchEmployee
                      width="100%"
                      onChange={(employee) => {
                        setInternalContact({
                          name: employee.name ?? '',
                          email: employee.email ?? '',
                          phone: employee.phone ?? '',
                        });
                      }}
                      onClose={() => {}}
                      fetchEmployees={fetchEmployees}
                    />
                    {internalContact.name && (
                      <Stack gap="xs">
                        <TextInput
                          label="Contact Person"
                          value={internalContact.name}
                          onChange={(e) =>
                            setInternalContact((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                        />
                        <TextInput
                          placeholder="Phone"
                          value={internalContact.phone}
                          onChange={(e) =>
                            setInternalContact((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                        />
                        <TextInput
                          placeholder="Email"
                          value={internalContact.email}
                          onChange={(e) =>
                            setInternalContact((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                        />
                      </Stack>
                    )}
                  </Stack>
                </Tabs.Panel>

                <Tabs.Panel value="external">
                  <Stack mt="md" gap="xs" style={{ minHeight: '250px' }}>
                    <TextInput
                      label="Contact Person"
                      placeholder="Name"
                      value={externalContact.name}
                      onChange={(e) => {
                        setExternalContact((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }}
                    />
                    <TextInput
                      placeholder="Phone"
                      value={externalContact.phone}
                      onChange={(e) => {
                        setExternalContact((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }));
                      }}
                    />
                    <TextInput
                      placeholder="Email"
                      value={externalContact.email}
                      onChange={(e) => {
                        setExternalContact((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </Stack>
                </Tabs.Panel>
              </Tabs>
            </Stack>

            <div
              style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'var(--mantine-color-body)',
                padding: 'var(--mantine-spacing-md)',
                borderTop: '1px solid var(--mantine-color-gray-3)',
                marginLeft: 'calc(var(--mantine-spacing-md) * -1)',
                marginRight: 'calc(var(--mantine-spacing-md) * -1)',
                marginBottom: 'calc(var(--mantine-spacing-md) * -1)',
              }}
            >
              <ConfirmButtonGroup
                confirmBtnText="Export"
                onAbort={close}
                onConfirm={handleExport}
              />
            </div>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ExportReportModal;
