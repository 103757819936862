import { Group, Stack, Text } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { ReactNode } from 'react';

interface Props {
  to: string;
  title: string;
  emptyText: string;
  children: ReactNode;
}

export default function StatusRow({ to, title, emptyText, children }: Props) {
  function Row() {
    return (
      <Group justify="space-between">
        <Stack flex={1} gap="xs">
          <Text fw={600}>{title}</Text>
          {children || <Text size="sm">{emptyText}</Text>}
        </Stack>
        <IconArrowNarrowRight size={42} color={children ? 'black' : 'lightGrey'} />
      </Group>
    );
  }

  return (
    <>
      {children ? (
        <NavLink
          to={to}
          style={{
            textDecoration: 'none',
            color: 'var(--mantine-color-black)',
          }}
        >
          <Row />
        </NavLink>
      ) : (
        <Row />
      )}
    </>
  );
}
