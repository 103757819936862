import {
  Box,
  Skeleton,
  Text,
  Group,
  Avatar,
  Checkbox,
  ActionIcon,
  Stack,
  Paper,
  Button,
  Tooltip,
} from '@mantine/core';
import { IPublicClientApplication } from '@azure/msal-browser';
import { NotificationContact, TimeFrame } from '../../../models/NotificationContact';
import { ProjectTimeFrame } from '../ProjectTimeFrame/ProjectTimeFrame';
import useDeleteNotificationContact from '../../../data/hooks/NotificationContact/useDeleteNotificationContactHook.ts';
import useToggleEmailNotifications from '../../../data/hooks/NotificationContact/useToggleEmailNotifications.ts';
import useToggleSmsNotifications from '../../../data/hooks/NotificationContact/useToggleSmsNotifications.ts';
import useAddNotificationContactTimeFrame from '../../../data/hooks/NotificationContact/useAddNotificationContactTimeFrameHook.ts';
import useUpdateNotificationContactTimeFrame from '../../../data/hooks/NotificationContact/useUpdateNotificationContactTimeFrameHook.ts';
import useRemoveNotificationContactTimeFrame from '../../../data/hooks/NotificationContact/useDeleteNotificationContactTimeFrameHook.ts';
import { IconChevronDown, IconChevronUp, IconPlus, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { AddTimeFrameModal } from '../AddTimeFrame/AddTimeFrameModal';
import useToggleBatteryNotifications from '../../../data/hooks/NotificationContact/useToggleBatteryNotifications.ts';

const Contact = ({
  contact,
  onAddTimeFrame,
  onUpdateTimeFrame,
  onDeleteTimeFrame,
  onToggleSms,
  onToggleEmail,
  onDelete,
  onToggleBatteryWarning,
}: {
  contact: NotificationContact;
  onAddTimeFrame: (timeFrame: TimeFrame) => void;
  onUpdateTimeFrame: (existingTimeFrame: TimeFrame, newTimeFrame: TimeFrame) => void;
  onDeleteTimeFrame: (timeFrame: TimeFrame) => void;
  onToggleSms: () => void;
  onToggleEmail: () => void;
  onDelete: () => void;
  onToggleBatteryWarning: () => void;
}) => {
  const [showTimeFrames, setShowTimeFrames] = useState(true);
  const [showPreferences, setShowPreferences] = useState(true);
  const [addTimeFrameOpened, { open: openAddTimeFrame, close: closeAddTimeFrame }] =
    useDisclosure(false);
  const nameParts = contact.name.split(' ');
  const initials = contact.external
    ? 'NN'
    : nameParts[0]?.[0] && nameParts[1]?.[0]
      ? nameParts[0][0] + nameParts[1][0]
      : 'NN';

  return (
    <Paper radius="md" withBorder p="md">
      <Stack gap="md">
        {/* Add Time Frame Modal */}
        <AddTimeFrameModal
          timeFrame={{
            start: '00:00:00',
            end: '23:59:00',
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          }}
          onSubmit={onAddTimeFrame}
          opened={addTimeFrameOpened}
          open={openAddTimeFrame}
          close={closeAddTimeFrame}
          editModal={false}
          onDelete={() => {}}
        />

        {/* Contact Info */}
        <Group justify="space-between">
          <Group>
            <Avatar color={contact.external ? 'gray.5' : 'blue'} radius="xl" size="md">
              {initials}
            </Avatar>
            <Box>
              <Text fw={500} size="lg">
                {contact.name}
              </Text>
              <Stack gap={4}>
                <Text size="sm" c="dimmed">
                  {contact.phone}
                </Text>
                {contact.email && (
                  <Text size="sm" c="dimmed">
                    {contact.email}
                  </Text>
                )}
              </Stack>
            </Box>
          </Group>
          <Tooltip label="Remove contact">
            <ActionIcon variant="light" color="red" size="lg" onClick={onDelete}>
              <IconTrash size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>

        {/* Time Frames Section */}
        <Stack gap="xs">
          <Group justify="space-between">
            <Group
              gap="xs"
              onClick={() => setShowTimeFrames(!showTimeFrames)}
              style={{ cursor: 'pointer' }}
            >
              <ActionIcon variant="transparent" color="gray" size="md">
                {showTimeFrames ? <IconChevronDown size={20} /> : <IconChevronUp size={20} />}
              </ActionIcon>
              <Text fw={500} size="md">
                Time Frames
              </Text>
            </Group>
            <Button
              variant="outline"
              size="sm"
              leftSection={<IconPlus size={16} />}
              onClick={openAddTimeFrame}
            >
              Add Time Frame
            </Button>
          </Group>

          {showTimeFrames && (
            <Stack gap="xs" pl={32}>
              {contact.timeFrames.map((timeFrame, index) => (
                <ProjectTimeFrame
                  key={index}
                  timeFrame={timeFrame}
                  onUpdate={(existingTimeFrame: TimeFrame, newTimeFrame) =>
                    onUpdateTimeFrame(existingTimeFrame, newTimeFrame)
                  }
                  onDelete={() => onDeleteTimeFrame(timeFrame)}
                />
              ))}
            </Stack>
          )}
        </Stack>

        {/* Notification Preferences Section */}
        <Stack gap="xs">
          <Group justify="space-between">
            <Group
              gap="xs"
              onClick={() => setShowPreferences(!showPreferences)}
              style={{ cursor: 'pointer' }}
            >
              <ActionIcon variant="transparent" color="gray" size="md">
                {showPreferences ? <IconChevronDown size={20} /> : <IconChevronUp size={20} />}
              </ActionIcon>
              <Text fw={500} size="md">
                Notification Preferences
              </Text>
            </Group>
          </Group>

          {showPreferences && (
            <Stack gap="md" pl={32}>
              <Group gap="md">
                <Checkbox
                  checked={contact.smsEnabled}
                  disabled={!contact.phone}
                  onChange={onToggleSms}
                  label="SMS"
                  size="sm"
                />
                <Checkbox
                  checked={contact.emailEnabled}
                  disabled={!contact.email}
                  onChange={onToggleEmail}
                  label="Email"
                  size="sm"
                />
              </Group>
              {contact.external && (
                <Checkbox
                  label="Send battery warnings"
                  checked={contact.batteryAlarmNotificationEnabled}
                  onChange={onToggleBatteryWarning}
                  size="sm"
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export const ContactsList = (props: {
  contactsLoading: boolean;
  contacts: NotificationContact[];
  instance: IPublicClientApplication;
  external: boolean;
}) => {
  const { mutate: deleteNotificationContact } = useDeleteNotificationContact(props.instance);
  const { mutate: toggleEmailNotification } = useToggleEmailNotifications(props.instance);
  const { mutate: toggleSmsNotification } = useToggleSmsNotifications(props.instance);
  const { mutate: addTimeFrame } = useAddNotificationContactTimeFrame(props.instance);
  const { mutate: updateTimeFrame } = useUpdateNotificationContactTimeFrame(props.instance);
  const { mutate: deleteTimeFrame } = useRemoveNotificationContactTimeFrame(props.instance);
  const { mutate: toggleBatteryWarning } = useToggleBatteryNotifications(props.instance);

  const filteredContacts = props.contacts.filter((contact) => contact.external === props.external);

  if (props.contactsLoading) {
    return (
      <>
        {[0, 1].map((index) => (
          <Paper key={index} radius="md" withBorder p="md">
            <Stack gap="md">
              <Group>
                <Skeleton height={40} circle />
                <Box>
                  <Skeleton height={12} width={120} radius="xl" mb={8} />
                  <Skeleton height={10} width={160} radius="xl" />
                </Box>
              </Group>
              <Stack gap="xs">
                <Group justify="space-between">
                  <Skeleton height={20} width={100} />
                  <Skeleton height={24} width={120} />
                </Group>
                <Skeleton height={30} />
                <Skeleton height={30} />
              </Stack>
            </Stack>
          </Paper>
        ))}
      </>
    );
  }

  if (filteredContacts.length === 0) {
    return (
      <Text mt={'sm'} size="sm">
        No contacts added to this project
      </Text>
    );
  }

  return (
    <Stack gap="md">
      {filteredContacts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((contact) => (
          <Contact
            key={contact.id}
            contact={contact}
            onAddTimeFrame={(timeFrame) =>
              addTimeFrame({
                timeFrame,
                NotificationContactId: contact.id,
              })
            }
            onUpdateTimeFrame={(existingTimeFrame, newTimeFrame) =>
              updateTimeFrame({
                ExistingTimeFrame: existingTimeFrame,
                UpdatedTimeFrame: newTimeFrame,
                NotificationContactId: contact.id,
              })
            }
            onDeleteTimeFrame={(timeFrame) =>
              deleteTimeFrame({
                timeFrame,
                NotificationContactId: contact.id,
              })
            }
            onToggleSms={() => toggleSmsNotification({ NotificationContactId: contact.id })}
            onToggleEmail={() => toggleEmailNotification({ NotificationContactId: contact.id })}
            onDelete={() => deleteNotificationContact({ id: contact.id })}
            onToggleBatteryWarning={() =>
              toggleBatteryWarning({ NotificationContactId: contact.id })
            }
          />
        ))}
    </Stack>
  );
};
