import { notifications } from '@mantine/notifications';

const showErrorNotification = (title: string, message: string) => {
  notifications.show({
    color: 'red',
    autoClose: 5000,
    position: 'bottom-center',
    title,
    message,
  });
};

export const handleError = (error: any) => {
  const message = error.detail || 'An unknown error occurred';
  const title = error.title || 'Error';
  const status = error.status || 'Unknown';

  switch (status) {
    case 401:
      // Handled by the auth provider
      break;
    case 403:
      showErrorNotification('403 Forbidden', 'You do not have permission to access this resource');
      break;

    case 404:
      showErrorNotification('404 Not Found', 'The requested page or resource was not found');
      break;

    case 500:
      showErrorNotification('500 Internal Server Error', 'Something went wrong on the server');
      break;

    default:
      showErrorNotification(`${status} ${title}`, message);
      break;
  }
};
