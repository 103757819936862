import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Params {
  id: string;
}

const path = `/workZone/unSnooze`;

const useUnSnoozeProject = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending } = useMutation({
    mutationFn: (params: Params) => {
      return postData(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error unsnoozing project`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      queryClient.invalidateQueries({ queryKey: ['devices'] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
  };
};

export default useUnSnoozeProject;
