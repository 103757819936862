import {
  Paper,
  Title,
  Table,
  Badge,
  Group,
  Stack,
  Button,
  Collapse,
  Text,
  Card,
} from '@mantine/core';
import { ProvisionData } from '../../models/ProvisionData';
import { useDisclosure } from '@mantine/hooks';
import {
  IconEye,
  IconEyeOff,
  IconCheck,
  IconAlertCircle,
  IconCalendarEvent,
  IconUser,
} from '@tabler/icons-react';

interface ProvisionTableProps {
  provision: ProvisionData;
}

const ProvisionTable: React.FC<ProvisionTableProps> = ({ provision }) => {
  const hasConnectedCount = provision.provisions.filter((device) => device.hasConnected).length;
  const hasNotConnectedCount = provision.provisions.length - hasConnectedCount;
  const [opened, { toggle }] = useDisclosure(false);

  // Sort devices with hasConnected false at the top
  const sortedDevices = [...provision.provisions].sort((a, b) => {
    if (a.hasConnected === b.hasConnected) return 0;
    return a.hasConnected ? 1 : -1;
  });

  const date = new Date(provision.createdAt);

  // Option 1: Using Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
  const formattedDate = formatter.format(date);

  return (
    <Paper p="md">
      <Stack miw={'300px'} mb={'sm'}>
        <Group justify="space-between">
          <Title order={3}>The {provision.name} Provision</Title>
          <Button
            onClick={toggle}
            variant="light"
            leftSection={opened ? <IconEyeOff size={16} /> : <IconEye size={16} />}
          >
            {opened ? 'Hide Devices' : 'Show Devices'}
            <Badge ml="sm" variant="outlined" color="teal" size="sm">
              {provision.provisions.length}
            </Badge>
          </Button>
        </Group>
        <Group>
          {hasNotConnectedCount === 0 ? (
            <Badge color="green" size="lg" variant="filled" leftSection={<IconCheck size={14} />}>
              All devices connected
            </Badge>
          ) : (
            <Badge color="red" size="lg" leftSection={<IconAlertCircle size={14} />}>
              {hasNotConnectedCount} Not Connected
            </Badge>
          )}
        </Group>
      </Stack>
      <Collapse in={opened} transitionDuration={300}>
        <Card pt="md" pb="md">
          <Group justify="left" mb="xs">
            <Group gap="xs">
              <IconUser size={16} />
              <Text fw={500}>Created By</Text>
            </Group>
            <Text>{provision.createdBy}</Text>
          </Group>
          <Group justify="left" mt="xs">
            <Group gap="xs">
              <IconCalendarEvent size={16} />
              <Text fw={500}>Created At</Text>
            </Group>
            <Text>{formattedDate}</Text>
          </Group>
        </Card>
        {sortedDevices.length > 0 ? (
          <Table highlightOnHover bg={'white'}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Id</Table.Th>
                <Table.Th>Imei</Table.Th>
                <Table.Th>ReferenceId</Table.Th>
                <Table.Th>ModelRef</Table.Th>
                <Table.Th>DeviceType</Table.Th>
                <Table.Th>HasConnected</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {sortedDevices.map((device) => {
                return (
                  <Table.Tr key={device.id}>
                    <Table.Td>{device.id}</Table.Td>
                    <Table.Td>{device.imei}</Table.Td>
                    <Table.Td>{device.referenceId}</Table.Td>
                    <Table.Td>{device.modelRef}</Table.Td>
                    <Table.Td>{device.deviceType}</Table.Td>
                    <Table.Td>
                      {device.hasConnected ? (
                        <Badge color="green">Connected</Badge>
                      ) : (
                        <Badge color="red">Not Connected</Badge>
                      )}
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        ) : (
          <Text>No devices available.</Text>
        )}
      </Collapse>
    </Paper>
  );
};

export default ProvisionTable;
