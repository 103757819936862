import {
  DeviceFilters,
  DeviceLogFilters,
  NotificationContactFilters,
  ProjectFilters,
  SupervisionReportFilters,
} from './filters';

const QueryKeys = {
  Devices: {
    all: ['devices'] as const,
    lists: () => [...QueryKeys.Devices.all, 'list'] as const,
    list: (pageNumber: number, pageSize: number, filters: DeviceFilters = {}) =>
      [...QueryKeys.Devices.lists(), { pageNumber, pageSize, ...filters }] as const,
    details: () => [...QueryKeys.Devices.all, 'detail'] as const,
    detail: (id: string) => [...QueryKeys.Devices.details(), id] as const,
    infiniteList: (filters: DeviceFilters = {}) =>
      [...QueryKeys.Devices.lists(), 'infinite', { ...filters }] as const,
  },
  DeviceLogs: {
    all: ['device-logs'] as const,
    lists: () => [...QueryKeys.DeviceLogs.all, 'list'] as const,
    list: (pageNumber: number, pageSize: number, filters: DeviceLogFilters = {}) =>
      [...QueryKeys.DeviceLogs.lists(), { pageNumber, pageSize, ...filters }] as const,
    details: () => [...QueryKeys.DeviceLogs.all, 'detail'] as const,
    detail: (id: string) => [...QueryKeys.DeviceLogs.details(), id] as const,
    infiniteList: (filters: DeviceLogFilters = {}) =>
      [...QueryKeys.DeviceLogs.lists(), 'infinite', { ...filters }] as const,
  },
  Projects: {
    all: ['projects'] as const,
    lists: () => [...QueryKeys.Projects.all, 'list'] as const,
    list: (pageNumber: number, pageSize: number, filters: ProjectFilters = {}) =>
      [...QueryKeys.Projects.lists(), { pageNumber, pageSize, ...filters }] as const,
    details: () => [...QueryKeys.Projects.all, 'detail'] as const,
    detail: (id: string) => [...QueryKeys.Projects.details(), id] as const,
    infiniteList: (filters: ProjectFilters = {}) =>
      [...QueryKeys.Projects.lists(), 'infinite', { ...filters }] as const,
  },
  ProjectHistoryLogs: {
    all: ['project-history-logs'] as const,
  },
  NotificationContacts: {
    all: ['notification-contacts'] as const,
    lists: () => [...QueryKeys.NotificationContacts.all, 'list'] as const,
    list: (pageNumber: number, pageSize: number, filters: NotificationContactFilters = {}) =>
      [...QueryKeys.NotificationContacts.lists(), { pageNumber, pageSize, ...filters }] as const,
    details: () => [...QueryKeys.NotificationContacts.all, 'detail'] as const,
    detail: (id: string) => [...QueryKeys.NotificationContacts.details(), id] as const,
    infiniteList: (filters: NotificationContactFilters = {}) =>
      [...QueryKeys.NotificationContacts.lists(), 'infinite', { ...filters }] as const,
  },
  SupervisionReports: {
    all: ['supervision-reports'] as const,
    lists: () => [...QueryKeys.SupervisionReports.all, 'list'] as const,
    list: (pageNumber: number, pageSize: number, filters: SupervisionReportFilters = {}) =>
      [...QueryKeys.SupervisionReports.lists(), { pageNumber, pageSize, ...filters }] as const,
    details: () => [...QueryKeys.SupervisionReports.all, 'detail'] as const,
    detail: (id: string) => [...QueryKeys.SupervisionReports.details(), id] as const,
    infiniteList: (filters: SupervisionReportFilters = {}) =>
      [...QueryKeys.SupervisionReports.lists(), 'infinite', { ...filters }] as const,
  },
  UserInformation: {
    all: ['user-information'] as const,
  },
  ProvisionData: {
    all: ['provision-data'] as const,
  },
};

export default QueryKeys;
