import { Group, Paper, Stack, Text } from '@mantine/core';
import { StatusGroup } from '../../Status';
import { Device } from '../../../models/Device';
import classes from './DeviceListItemLight.module.css';
import { getAttachmentText } from '../../../utils/signs.ts';
import { formatDateTimeString } from '../../../utils/utils.tsx';

type Props = {
  device: Device;
  isSnoozed?: boolean;
  countryCode: string;
  onClick: () => void;
};

export function DeviceListItemLight({ device, isSnoozed, countryCode, onClick }: Props) {
  const attachmentText = getAttachmentText(device, countryCode);
  return (
    <Paper
      className={classes.item}
      fz="md"
      mb="xs"
      px="md"
      py="md"
      bg="#f2f2f2"
      key={device.id}
      onClick={onClick}
      shadow="xs"
      style={{}}
    >
      <Group w="100%" justify="space-between">
        <Stack gap={0} flex={1}>
          <Group gap={4}>
            <Text>
              <b>{attachmentText}</b>
              {attachmentText && device.currentName && ' - '}
              {device.currentName || ''}
            </Text>
          </Group>
          <Text opacity={0.5} fw={300} size="xs">
            {device.referenceId}
          </Text>
          <Text c={'gray.6'} ta={'left'} size="xs">
            Latest update: {formatDateTimeString(device.latestUpdate) ?? '-'}
          </Text>
        </Stack>
        <Group>
          <Stack gap={10} align="flex-end">
            <StatusGroup
              alarmStatus={device.alarmStatus}
              batteryStatus={device.batteryStatus}
              isSnoozed={isSnoozed}
            />
          </Stack>
        </Group>
      </Group>
    </Paper>
  );
}
