import {
  ActionIcon,
  Button,
  Center,
  CloseButton,
  Group,
  Input,
  InputWrapper,
  Table,
  Text,
} from '@mantine/core';
import { Device, DeviceTypeNames } from '../../../models/Device';
import { useState } from 'react';
import { DeviceImageWrapper } from '../../Map/InfoCard/DeviceInfo/DeviceImageWrapper';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { useMobileDevice } from '../../../hooks/useMobileDevice';

type Props = {
  devices: Device[];
  showRemoveBtn?: boolean;
  onRemove: (id: string) => void;
  onAdd: () => void;
};

export const ConnectedDevices = ({ devices, showRemoveBtn = true, onRemove, onAdd }: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  const isMobile = useMobileDevice();
  const filteredDevices = devices.filter((device) =>
    [device.currentName, device.referenceId, device.imei].some((field) =>
      field?.toLowerCase().includes(searchString.toLowerCase())
    )
  );

  const rows = filteredDevices.map((d) => (
    <Table.Tr key={d.id}>
      <Table.Td>
        <DeviceImageWrapper baseDevice={d} />
      </Table.Td>
      {!isMobile && <Table.Td>{DeviceTypeNames[d.deviceType]}</Table.Td>}
      <Table.Td>{d.referenceId}</Table.Td>
      <Table.Td>{d.currentName}</Table.Td>
      <Table.Td ta={'right'}>
        {showRemoveBtn && (
          <ActionIcon variant="transparent" onClick={() => onRemove(d.id)}>
            <IconTrash color="rgb(222, 45, 45, 0.8)" />
          </ActionIcon>
        )}
      </Table.Td>
    </Table.Tr>
  ));
  return (
    <>
      <Group align="end" wrap="nowrap" justify="space-between">
        <InputWrapper flex={2} maw={'450px'} ta={'left'} label="Connected Devices">
          <Input
            placeholder="Search device by Serial number, IMEI or Additional info"
            value={searchString}
            onChange={(event) => setSearchString(event.currentTarget.value)}
            rightSectionPointerEvents="all"
            rightSection={
              <CloseButton aria-label="Clear input" onClick={() => setSearchString('')} />
            }
          />
        </InputWrapper>
        <Button leftSection={<IconPlus />} onClick={onAdd}>
          Add device
        </Button>
      </Group>
      <Table w={'100%'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            {!isMobile && <Table.Th>Device Type</Table.Th>}
            <Table.Th>Reference Id</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        {rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}
      </Table>
      {!rows.length && (
        <Center>
          <Text c="dimmed">No devices have been added to this project yet</Text>
        </Center>
      )}
    </>
  );
};
