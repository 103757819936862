import { useMutation } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Params {
  DeviceProvisionings: DeviceProvisioning[];
}

interface DeviceProvisioning {
  imei: string;
  referenceId: string;
  modelRef: number;
  deviceType: number;
}

const path = `/device/bulk-provisioning`;

const useBulkProvisionDevices = (instance: IPublicClientApplication) => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (params: Params) => {
      return postData<Params>(path, params, instance);
    },
    onError: (error) => {
      console.error('Bulk provision failed:', error);
    },
  });

  return {
    mutateAsync,
    isPending,
  };
};

export default useBulkProvisionDevices;
