import { Group, Stack, Text, Title } from '@mantine/core';
import { ReactNode } from 'react';

interface Props {
  label?: string;
  rightSection?: ReactNode;
  children: string;
}

export default function PwaPageHeading({ label, rightSection, children }: Props) {
  return (
    <Group justify={rightSection ? 'space-between' : ''}>
      <Stack gap={0}>
        {label && <Text size="sm">{label}</Text>}
        <Title order={1} size="h4">
          {children}
        </Title>
      </Stack>
      {rightSection}
    </Group>
  );
}
