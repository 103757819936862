import { ForwardedRef, useImperativeHandle } from 'react';
import { PdfBaseProps, PdfExportRef } from '../../../../models/PdfExport';
import { DashboardComponent } from '../../../../models/Dashboard';
import { ReportType, getReportDisplayName } from '../../../../models/enums/ReportType';

export function usePdfExport<D, P extends PdfBaseProps>(
  ref: ForwardedRef<PdfExportRef>,
  props: DashboardComponent<D>,
  createPDF: (pdfProps: P) => Promise<void>,
  additionalPdfProps: Omit<P, keyof PdfBaseProps>,
  type: ReportType
) {
  useImperativeHandle(ref, () => ({
    exportPDF: async () => {
      window._paq?.push([
        'trackEvent',
        'SupervisionReport',
        `Export ${getReportDisplayName(type)}`,
        props.projectId,
      ]);

      const imagePromises = document.querySelectorAll('img').length;
      if (imagePromises > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      try {
        await createPDF({
          ...additionalPdfProps,
          projectName: props.projectName,
          depot: props.depot,
          reportStartDate: props.reportStartDate,
          reportEndDate: props.reportEndDate,
          contactPerson: props.contactPerson ?? {
            name: '',
            email: '',
            phone: '',
          },
        } as P);
      } catch (error) {
        console.error('Error creating PDF:', error);
        throw error;
      }
    },
  }));
}
