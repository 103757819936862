import { Avatar, Button, Group, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { UserInformationDto } from '../../models/User.ts';

const UserInfo = (props: { user: UserInformationDto; clearSelectedUser: () => void }) => {
  const nameParts = props.user.displayName.split(' ');
  const initials = nameParts[0][0] + nameParts[1][0];
  return (
    <Group wrap="nowrap" pr={'sm'} w={'100%'}>
      <Avatar color={'blue'} size={'36px'}>
        {initials}
      </Avatar>
      <div style={{ width: '80%' }}>
        <Text size="sm">{props.user.displayName}</Text>
        <Text truncate={'end'} size="xs" c={'gray.6'}>
          {props.user.userEmail}
        </Text>
        <Text size="xs" c={'gray.6'}>
          {props.user.userId}
        </Text>
      </div>
      <Button size="xs" color="red" onClick={props.clearSelectedUser}>
        <IconX />
      </Button>
    </Group>
  );
};

export default UserInfo;
