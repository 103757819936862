import { ARROW_DIRECTION } from './Device.ts';
import {
  AlarmStatus,
  BatteryStatus,
  EquipmentType,
  PoweredEquipment,
} from './enums/DeviceEnums.ts';

export interface DeviceEntry {
  logs: DeviceLog[];
}

export interface DeviceRow {
  referenceId: string;
  name: string;
  entries: DeviceEntry[];
  attachmentRef: string;
  currentEquipmentType: EquipmentType;
  poweredEquipment?: PoweredEquipment;
}

export enum ARROWBOARD_EVENT_TYPE {
  OnlineStatus = 0,
  ArrowChange = 1,
}

export enum ONLINE_STATUS {
  Offline = 0,
  Online = 1,
}

export const ONLINE_STATUS_NAMES = {
  [ONLINE_STATUS.Offline]: 'Offline',
  [ONLINE_STATUS.Online]: 'Online',
};

export interface ArrowboardEvent {
  timestamp: string;
  eventType: ARROWBOARD_EVENT_TYPE;
  eventValue: ONLINE_STATUS | ARROW_DIRECTION;
}

export interface ArrowboardRow {
  name: string;
  referenceId: string;
  events: ArrowboardEvent[];
}

interface DeviceLog {
  batteryVoltage: number;
  alarmStatus: AlarmStatus;
  timeStamp: string;
  workZoneId: string;
  batteryStatus: BatteryStatus;
  workZoneSnoozed: boolean;
}

export interface SupervisionReportDto {
  intervals: string[];
  rows: DeviceRow[];
}

export type ArrowboardsReportDto = ArrowboardRow[];

export enum SMART_CABLE_EVENT_TYPE {
  BATTERY_OK = 'BATTERY_OK', // BatteryStatus.OK = 1, LOW = 2, CRITICAL = 3
  OUT_OF_BATTERY = 'OUT_OF_BATTERY', // BatteryStatus.OUT_OF_BATTERY = 4
  DISCONNECTED = 'DISCONNECTED', // BatteryStatus.DISCONNECTED = 5
  NO_LOGS = 'NO_LOGS', // No logs found
}

export interface SmartCableEvent {
  timestamp: string;
  eventType: SMART_CABLE_EVENT_TYPE;
}

export interface SmartCableRow {
  referenceId: string;
  name: string;
  attachmentRef: string;
  events: SmartCableEvent[];
}

export interface SmartCableReportDto {
  rows: SmartCableRow[];
}

export interface LegendItem {
  text: string;
  img?: string;
  icon?: React.ReactNode | string; // ReactNode for UI, string for PDF
  style?: React.CSSProperties;
}
