import { getAccessToken } from '../auth';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Api {
  get: (
    instance: IPublicClientApplication,
    endpoint: string,
    queryParams: Record<string, string>
  ) => Promise<Response>;
  post: (instance: IPublicClientApplication, endpoint: string, body?: any) => Promise<Response>;
  formPost: (instance: IPublicClientApplication, endpoint: string, body?: any) => Promise<Response>;
  put: (instance: IPublicClientApplication, endpoint: string, body?: any) => Promise<Response>;
  delete: (
    instance: IPublicClientApplication,
    endpoint: string,
    queryParams: Record<string, string>
  ) => Promise<Response>;
}

const prepareHeaders = async (instance: IPublicClientApplication, isFormData: boolean = false) => {
  const headers = new Headers();
  if (!isFormData) {
    headers.set('Content-Type', 'application/json');
  }
  const accessToken = await getAccessToken(instance);
  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }
  return headers;
};
const basePath = import.meta.env.VITE_API_BASE_PATH;

const api: Api = {
  get: async (instance, endpoint, queryParams) => {
    const filteredParams: Record<string, string> = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const data = await fetch(`${basePath}/${endpoint}?${queryString}`, {
      mode: 'cors',
      headers: await prepareHeaders(instance),
    });
    return data as Response;
  },
  post: async (instance, endpoint, body) =>
    fetch(`${basePath}/${endpoint}`, {
      method: 'POST',
      body: body && JSON.stringify(body),
      headers: await prepareHeaders(instance),
    }),
  formPost: async (instance, endpoint, body: FormData) => {
    return fetch(`${basePath}/${endpoint}`, {
      method: 'POST',
      body: body,
      headers: await prepareHeaders(instance, true),
    });
  },
  put: async (instance, endpoint, body) =>
    fetch(`${basePath}/${endpoint}`, {
      method: 'PUT',
      body: body && JSON.stringify(body),
      headers: await prepareHeaders(instance),
    }),
  delete: async (instance, endpoint, queryParams) => {
    const filteredParams: Record<string, string> = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const data = await fetch(`${basePath}/${endpoint}?${queryString}`, {
      method: 'DELETE',
      headers: await prepareHeaders(instance),
    });
    return data as Response;
  },
};

export { api };
