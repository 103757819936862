import { Point } from 'geojson';
import { WorkZone } from '../models/Project';
import { Mapbounds } from '../shared/types';
import { Device } from '../models/Device.ts';

export function getWorkZoneMiddlePoint(workZone: WorkZone): Point | null {
  const centerPoint: Point | null = workZone.center;
  if (centerPoint == null) {
    return null;
  }

  return {
    type: 'Point',
    coordinates: [centerPoint.coordinates[1], centerPoint.coordinates[0]],
  };
}

export function isBoundsWithinPreviousBounds(
  rect1: Mapbounds | undefined,
  rect2: Mapbounds | undefined
) {
  if (rect1 == undefined || rect2 == undefined) return false;

  const minLng = rect2.southWestLongitude;
  const maxLng = rect2.northEastLongitude;
  const minLat = rect2.southWestLatitude;
  const maxLat = rect2.northEastLatitude;

  const isWithinLng = rect1.southWestLongitude >= minLng && rect1.northEastLongitude <= maxLng;
  const isWithinLat = rect1.southWestLatitude >= minLat && rect1.northEastLatitude <= maxLat;

  return isWithinLat && isWithinLng;
}

export function sortDevices(list: Device[]): Device[] {
  return list.sort((a, b) => {
    // First, sort by alarm status
    if (a.alarmStatus !== b.alarmStatus) {
      return b.alarmStatus - a.alarmStatus;
    }

    // If alarm status is the same, sort by battery status
    if (a.batteryStatus !== b.batteryStatus) {
      return b.batteryStatus - a.batteryStatus;
    }

    // If both alarm and battery status are the same, sort by latest update
    if (a.latestUpdate !== b.latestUpdate) {
      return Date.parse(b.latestUpdate) - Date.parse(a.latestUpdate);
    }

    // Finally, sort by assignedAtTimestamp if all other criteria are equal
    return new Date(b.assignedAtTimestamp).valueOf() - new Date(a.assignedAtTimestamp).valueOf();
  });
}
