import { useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

const trackingEnabled = import.meta.env.VITE_MATOMO_TRACKING_ENABLED;

const useMatomo = (pca: PublicClientApplication) => {
  useEffect(() => {
    const activeAccount = pca.getActiveAccount();

    if (trackingEnabled === 'false') {
      return;
    }
    if (!window._paq) {
      window._paq = [];
      if (activeAccount) {
        window._paq.push(['setUserId', activeAccount.username]);
      }
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      (function () {
        const u = '//wa-gp-matomo.azurewebsites.net/';
        window._paq.push(['setTrackerUrl', u + 'matomo.php']);
        window._paq.push(['setSiteId', '1']);
        const d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = u + 'matomo.js';
        if (s.parentNode) {
          s.parentNode.insertBefore(g, s);
        } else {
          console.error('Script parent node is null');
        }
      })();
    }
  }, []);
};
export default useMatomo;
