import { useMsal } from '@azure/msal-react';
import { useMobileDevice } from '../../../hooks/useMobileDevice.tsx';
import { useState } from 'react';
import { EquipmentType, PoweredEquipment } from '../../../models/enums/DeviceEnums.ts';
import { Sign } from '../../../models/Sign.ts';
import useAssignDeviceToProject from '../../../data/hooks/Device/useAssignDeviceToProjectHook.ts';
import useUnassignDeviceFromProject from '../../../data/hooks/Device/useUnassignDeviceFromProject.ts';
import { getAllSigns } from '../../../utils/signs.ts';
import {
  Button,
  Divider,
  Group,
  InputWrapper,
  Modal,
  Paper,
  Stack,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { Device, DeviceType } from '../../../models/Device.ts';
import { PopoverConfirm } from '../../Popover/PopoverConfirm.tsx';
import { IntellitagInput } from './IntellitagInput.tsx';
import { SmartCableInput } from './SmartCableInput.tsx';
import { SmartSolarInput } from './SmartSolarInput.tsx';

interface Props {
  device: Device;
  countryCode: string;
  showModal: boolean;
  close: () => void;
  onSubmit: () => void;
}

export const EditDeviceInformationModal = ({ device, countryCode, showModal, close }: Props) => {
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(
    device.currentEquipmentType ?? EquipmentType.Barrier
  );
  const [selectedSign, setSelectedSign] = useState<Sign | null>(null);
  const [poweredEquipment, setPoweredEquipment] = useState<PoweredEquipment>(
    device.poweredEquipment
  );
  const [additionalInformation, setAdditionalInformation] = useState<string | null>(null);
  const [openedUnassignPopover, setOpenedUnassignPopover] = useState<boolean>(false);

  const { mutateAsync: mutateAssign, isPending: deviceAssignPending } =
    useAssignDeviceToProject(instance);

  const { mutateAsync: mutateUnassign, isPending: deviceUnassignPending } =
    useUnassignDeviceFromProject(instance);

  const getName = () => {
    if (additionalInformation && additionalInformation.length > 0) {
      return additionalInformation;
    } else {
      return device.currentName;
    }
  };

  const handleEditDeviceInformation = () => {
    const selectedRoadSign: string | null =
      getAllSigns(countryCode)[selectedSign?.name ?? ''] ?? null;

    mutateAssign({
      projectId: device.workZoneId,
      deviceId: device.id,
      equipmentType: device.deviceType == DeviceType.Intellitag ? equipmentType : null,
      currentName: getName(),
      attachmentRef: device.deviceType == DeviceType.Intellitag ? selectedRoadSign : null,
      poweredEquipment:
        device.deviceType == DeviceType.SmartCable || device.deviceType == DeviceType.SmartSolar
          ? poweredEquipment
          : null,
    }).then(() => close());
  };

  const handleUnassignDevice = () => {
    mutateUnassign({
      deviceId: device.id,
    }).then(() => close());
  };

  return (
    <Modal.Root opened={showModal} onClose={close} fullScreen={isMobile} size="900px" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Edit Device Information</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Group gap={'lg'} align="top">
            <div style={{ minWidth: '50%', width: '100%' }}>
              <div style={{ marginTop: '8px' }}>
                <Paper p={'md'} shadow="xs">
                  <Title order={4} ta={'left'} mb={'lg'}>
                    {device.referenceId}
                  </Title>
                  <Stack gap={'lg'}>
                    <TextInput label="Project" value={device.workZoneName} disabled readOnly />
                    <InputWrapper
                      ta={'left'}
                      label="Additional Information"
                      description="Information regarding placement or name of device (optional)"
                    >
                      <Textarea
                        value={additionalInformation ?? undefined}
                        onChange={(event) => setAdditionalInformation(event.currentTarget.value)}
                        minRows={3}
                        autosize
                      />
                    </InputWrapper>
                  </Stack>
                </Paper>
              </div>
            </div>
            <Group wrap={isMobile ? 'wrap' : 'nowrap'} align="top" />
          </Group>
          {device.deviceType == DeviceType.Intellitag && (
            <IntellitagInput
              countryCode={countryCode}
              equipmentType={equipmentType}
              setEquipmentType={setEquipmentType}
              selectedSign={selectedSign}
              setSelectedSign={setSelectedSign}
            ></IntellitagInput>
          )}
          {device.deviceType == DeviceType.SmartCable && (
            <SmartCableInput
              poweredEquipment={poweredEquipment}
              setPoweredEquipment={setPoweredEquipment}
            />
          )}
          {device.deviceType == DeviceType.SmartSolar && (
            <SmartSolarInput
              poweredEquipment={poweredEquipment}
              setPoweredEquipment={setPoweredEquipment}
            />
          )}
          <Divider my="sm" />
          <Group p="center">
            <PopoverConfirm
              opened={openedUnassignPopover}
              setOpened={(next) => setOpenedUnassignPopover(next)}
              text={`Are you sure you want to disconnect device ${device.currentName} from the project?`}
              btnText={`Unassign Device`}
              width={320}
              onClick={handleUnassignDevice}
            />
          </Group>
          <Divider my="sm" />
          <Group gap={'xs'} justify="end">
            <Button variant="outline" onClick={close} mr="sm" mt="10px">
              Cancel
            </Button>
            <Button
              onClick={handleEditDeviceInformation}
              mr="sm"
              mt="10px"
              disabled={deviceAssignPending || deviceUnassignPending}
            >
              Save
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
