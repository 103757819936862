import { Stack, Text } from '@mantine/core';
import { IconScan } from '@tabler/icons-react';

export default function NoDeviceMessage() {
  return (
    <Stack flex={1} justify="center" align="center">
      <IconScan size={80} color="black" />
      <Text content="center" size="lg">
        Scan to check device health
      </Text>
    </Stack>
  );
}
