import { Navigate, useLocation } from 'react-router-dom';
import Root from './routes/Root';
import Home from './routes/Home';
import DevicesPage from './routes/DeviceListPage';
import Map from './routes/Map';
import Error from './routes/Error';
import Login from './routes/Login';
import { EditDevicePage } from './routes/EditDevicePage';
import { BulkProvisionDevices } from './shared/BulkProvisionDevices';
import Redirect from './routes/Redirect';
import AccessManagement from './routes/AccessManagement';
import { ErrorBoundary } from './components/Error/ErrorBoundary';
import ProjectPage from './routes/ProjectPage';
import { BatteryStatus } from './models/enums/DeviceEnums';
import { AfterLoginRedirect } from './routes/AfterLogin';
import { AccessDenied } from './routes/AccessDenied';
import PrivacyPolicy from './routes/PrivacyPolicy.tsx';
import CreateProject from './routes/CreateProject';
import ProjectSetup from './routes/ProjectSetup';

import { PwaWrapper } from './pwa/components/PwaWrapper';
import { PwaScanPage } from './pwa/pages/PwaScanPage.tsx';
import { PwaHelpPage } from './pwa/pages/PwaHelpPage.tsx';
import { PwaSummaryPage } from './pwa/pages/PwaSummaryPage.tsx';
import { PwaDeviceListPage } from './pwa/pages/PwaDeviceListPage.tsx';
import { PwaDisconnectPage } from './pwa/pages/PwaDisconnectPage.tsx';
import { PwaProjectListPage } from './pwa/pages/PwaProjectListPage.tsx';
import { PwaBatteryBlockerPage } from './pwa/pages/PwaBatteryBlockerPage.tsx';
import { PwaIntellitagEquipmentPage } from './pwa/pages/PwaIntellitagEquipmentPage.tsx';
import { PwaProjectOverviewPage } from './pwa/pages/PwaProjectOverviewPage/PwaProjectOverviewPage.tsx';
import { PwaNotAvailableForAssignmentBlockerPage } from './pwa/pages/PwaNotAvailableForAssignmentBlockerPage.tsx';
import { PwaHealthCheckPage } from './pwa/pages/PwaHealthCheckPage/PwaHealthCheckPage.tsx';
import { PwaPairPoweredEquipmentPage } from './pwa/pages/PwaPairPoweredEquipmentPage.tsx';

const ErrorBoundaryWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  return <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>;
};

export const protectedRoutes = [
  {
    path: '/after-login',
    element: <AfterLoginRedirect />,
  },
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Redirect />,
      },
      {
        path: '/access-denied',
        element: <AccessDenied />,
      },
      {
        path: '/projects',
        element: (
          <ErrorBoundaryWrapper>
            <Home />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/create',
        element: (
          <ErrorBoundaryWrapper>
            <CreateProject />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/map',
        element: (
          <ErrorBoundaryWrapper>
            <Map />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/devices',
        element: (
          <ErrorBoundaryWrapper>
            <DevicesPage />
          </ErrorBoundaryWrapper>
        ),
        children: [],
      },
      {
        path: '/devices/edit/:deviceId',
        element: (
          <ErrorBoundaryWrapper>
            <EditDevicePage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/bulk-provision-devices',
        element: (
          <ErrorBoundaryWrapper>
            <BulkProvisionDevices />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/:projectId',
        element: (
          <ErrorBoundaryWrapper>
            <ProjectPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/:projectId/setup',
        element: (
          <ErrorBoundaryWrapper>
            <ProjectSetup />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/access-management',
        element: <AccessManagement />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        path: '*',
        element: <Navigate to="/redirect" state={{ from: window.location.pathname }} />,
      },
    ],
  },
  {
    path: '/pwa/projects/:projectId',
    element: (
      <PwaWrapper>
        <PwaProjectOverviewPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/all-devices',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="All Devices" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/alarms',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="Alarms" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/battery',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="Battery" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects',
    element: (
      <PwaWrapper>
        <PwaProjectListPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/pair-intellitag',
    element: (
      <PwaWrapper>
        <PwaIntellitagEquipmentPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/pair-powered-equipment',
    element: (
      <PwaWrapper>
        <PwaPairPoweredEquipmentPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/disconnect',
    element: (
      <PwaWrapper>
        <PwaDisconnectPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/summary',
    element: (
      <PwaWrapper>
        <PwaSummaryPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/critical-battery',
    element: (
      <PwaWrapper>
        <PwaBatteryBlockerPage type={BatteryStatus.CRITICAL} />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/out-of-battery',
    element: (
      <PwaWrapper>
        <PwaBatteryBlockerPage type={BatteryStatus.OUT_OF_BATTERY} />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/not-available-for-assignment',
    element: (
      <PwaWrapper>
        <PwaNotAvailableForAssignmentBlockerPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/help',
    element: (
      <PwaWrapper>
        <PwaHelpPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/scanner',
    element: (
      <PwaWrapper>
        <PwaScanPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/health-check',
    element: (
      <PwaWrapper>
        <PwaHealthCheckPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/privacy-policy',
    element: (
      <PwaWrapper>
        <PrivacyPolicy />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa',
    element: <Navigate to="/pwa/projects" />,
  },
  {
    path: '*',
    element: <Navigate to="/redirect" />,
  },
];

export const openRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '*',
    element: <Navigate to="/redirect" state={{ from: window.location.pathname }} />,
  },
];
