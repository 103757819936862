import { Group, Text } from '@mantine/core';
import { DeviceLog } from '../../../models/DeviceLog.ts';

export const SmartCableDeviceLogTableRow = (log: DeviceLog) => {
  return (
    <>
      <Group p="apart" gap="m" m="xs">
        <Text size="sm">
          <strong>External Battery (V): </strong>
          {log.externalBatteryVoltage ?? '-'}
        </Text>
        <Text size="sm">
          <strong>Internal Battery (V): </strong>
          {log.batteryVoltage ?? '-'}
        </Text>
      </Group>
    </>
  );
};
