import { Button, Group, Stack, Stepper, Title, Paper } from '@mantine/core';
import { useState } from 'react';
import { IconChevronLeft } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useGetNotificationContactsInWorkZone from '../data/hooks/NotificationContact/useGetNotificationContactsInWorkZoneHook';
import useCreateNotificationContact from '../data/hooks/NotificationContact/useCreateNotificationContactHook';
import { useMediaQuery } from 'react-responsive';
import { getEmployees } from '../services/auth';
import { NotificationsStep } from '../components/Project/ProjectSetup/NotificationsStep';
import {
  ProductSetupStep,
  ProductSettings,
} from '../components/Project/ProjectSetup/ProductSetupStep';
import { SummaryStep } from '../components/Project/ProjectSetup/SummaryStep';
import { Employee } from '../models/Employee';

export default function ProjectSetup() {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { instance } = useMsal();
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [showInput, setShowInput] = useState<boolean>(false);
  const [productSettings, setProductSettings] = useState<ProductSettings>({
    intellitags: false,
    arrowboards: false,
  });

  const { notificationContacts, isLoading: notificationContactsLoading } =
    useGetNotificationContactsInWorkZone({
      instance,
      projectId: projectId!,
    });

  const { mutate: createNotificationContact, isPending: createNotificationContactPending } =
    useCreateNotificationContact(instance);

  const contactsLoading = notificationContactsLoading || createNotificationContactPending;

  const fetchEmployees = async (query: string): Promise<Employee[]> => {
    return await getEmployees(instance, query);
  };

  const steps = [
    {
      label: 'Notifications',
      description: 'Contact information',
      path: 'notifications',
    },
    { label: 'Setup', description: 'Product setup', path: 'setup' },
    { label: 'Summary', description: 'Review project', path: 'summary' },
  ];

  return (
    <Stack h="100%" bg="white" px="xl" pt="md" pb="xl">
      <Group justify="space-between">
        <Button
          w="fit-content"
          size="xs"
          leftSection={<IconChevronLeft />}
          variant="outline"
          onClick={() => navigate(`/projects/${projectId}`)}
        >
          Back to Project
        </Button>
      </Group>
      <Title order={2}>Project Setup</Title>

      <Stepper active={active} onStepClick={setActive}>
        {steps.map((step, index) => (
          <Stepper.Step key={index} label={step.label} description={step.description} />
        ))}
      </Stepper>

      <Paper shadow="xs" p="xl" radius="sm" withBorder>
        {active === 0 && (
          <NotificationsStep
            isMobile={isMobile}
            showInput={showInput}
            instance={instance}
            notificationContacts={notificationContacts}
            contactsLoading={contactsLoading}
            onCreateContact={(data) => {
              createNotificationContact({
                ...data,
                workZoneId: projectId!,
              });
            }}
            onToggleInput={setShowInput}
            fetchEmployees={fetchEmployees}
          />
        )}
        {active === 1 && (
          <ProductSetupStep settings={productSettings} onUpdate={setProductSettings} />
        )}
        {active === 2 && (
          <SummaryStep
            notificationContacts={notificationContacts}
            productSettings={productSettings}
          />
        )}
      </Paper>

      <Group justify="flex-end" mt="auto">
        {active > 0 && (
          <Button variant="default" onClick={() => setActive(active - 1)}>
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            if (active < steps.length - 1) {
              setActive(active + 1);
            } else {
              navigate(`/projects/${projectId}`);
            }
          }}
        >
          {active === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Group>
    </Stack>
  );
}
