export enum BatteryStatus {
  OK = 1,
  LOW = 2,
  CRITICAL = 3,
  OUT_OF_BATTERY = 4,
  DISCONNECTED = 5,
}

export const BatteryStatusNames = {
  [BatteryStatus.OK]: 'OK',
  [BatteryStatus.LOW]: 'LOW',
  [BatteryStatus.CRITICAL]: 'CRITICAL',
  [BatteryStatus.OUT_OF_BATTERY]: 'OUT_OF_BATTERY',
  [BatteryStatus.DISCONNECTED]: 'DISCONNECTED',
};

export enum AlarmStatus {
  OK = 1,
  Alarming = 2,
}

export enum LifeCycleStatus {
  Unassigned = 1,
  Assigned = 2,
  Deprovisioned = 3,
}

export const AlarmStatusNames = {
  [AlarmStatus.OK]: 'OK',
  [AlarmStatus.Alarming]: 'Alarming',
};

export enum EquipmentType {
  Sign = 1,
  Barrier = 2,
}

export const EquipmentTypeNames = {
  [EquipmentType.Sign]: 'Sign',
  [EquipmentType.Barrier]: 'Barrier',
};

export enum PoweredEquipment {
  Unknown = 0,
  TrafficLight = 1,
  Buffer = 2,
  SequentialLight = 3,
  WarningLight = 4,
  Other = 999,
}

export const PoweredEquipmentNames = {
  [PoweredEquipment.Unknown]: 'Unknown',
  [PoweredEquipment.TrafficLight]: 'Traffic Light',
  [PoweredEquipment.Buffer]: 'Buffer',
  [PoweredEquipment.SequentialLight]: 'Sequential Lights',
  [PoweredEquipment.WarningLight]: 'Warning Light',
  [PoweredEquipment.Other]: 'Other',
};

export enum PowerSource {
  SmartCable = 'smart cable',
  SmartSolar = 'smart solar',
}
