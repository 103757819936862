import { Image, Loader, LoadingOverlay, Stack } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { getUserPermissions } from '../auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import logo from '../assets/ramudden_logo.svg';

export const AfterLoginRedirect = () => {
  const route = localStorage.getItem('preLoginRoute')?.toString() ?? '/project';
  const { instance } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    getUserPermissions(instance)
      .then(() => {
        navigate(route);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <LoadingOverlay
        visible={true}
        loaderProps={{
          children: (
            <Stack align="center">
              <Image w={300} src={logo} />
              <Loader />
            </Stack>
          ),
        }}
      />
    </>
  );
};
