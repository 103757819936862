import { Menu, Button } from '@mantine/core';
import { IconBellOff } from '@tabler/icons-react';

const durations = ['1h', '2h', '4h', '8h', '12h'];

const getDateOffset = (hours: string | null): Date => {
  const today = new Date();
  if (hours == null) {
    return today;
  }
  const offset = Number(hours.replace('h', ''));
  today.setHours(today.getHours() + offset);
  return today;
};

const SnoozeButton = (props: {
  projectId: string;
  snoozeProject: (params: { id: string; snoozedUntil: Date }) => void;
}) => {
  return (
    <Menu position="bottom-start" width={220} withinPortal>
      <Menu.Target>
        <Button w={220} leftSection={<IconBellOff size={18} stroke={1.5} />} pr={12}>
          Pause Alarms
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {durations.map((duration) => (
          <Menu.Item
            key={duration}
            onClick={() => {
              props.snoozeProject({
                id: props.projectId,
                snoozedUntil: getDateOffset(duration),
              });
            }}
          >
            {duration}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default SnoozeButton;
