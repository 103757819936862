import { ActionIcon, Menu, Text } from '@mantine/core';
import { IconDots, IconFileCertificate, IconLogout } from '@tabler/icons-react';
import { useAuthUtils } from '../../../../shared/authUtils';
import { useNavigate } from 'react-router-dom';

export default function HeaderMenu() {
  const { handleLogout } = useAuthUtils();
  const navigate = useNavigate();

  return (
    <Menu shadow="md" width={200} position="bottom-end">
      <Menu.Target>
        <ActionIcon variant="transparent" color="black">
          <IconDots size={42} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown p="sm">
        <Menu.Item
          leftSection={<IconFileCertificate size={24} />}
          onClick={() => navigate('/pwa/privacy-policy')}
        >
          <Text ml="xs" size="lg">
            Privacy Policy
          </Text>
        </Menu.Item>
        <Menu.Item leftSection={<IconLogout size={24} />} onClick={handleLogout}>
          <Text ml="xs" size="lg">
            Logout
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
