import { prepareHeaders } from './apiHelpers';
import { IPublicClientApplication } from '@azure/msal-browser';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;

const postData = async <ParamType>(
  path: string,
  data: ParamType,
  instance: IPublicClientApplication
): Promise<Response> => {
  return fetch(`${baseUrl}${path}`, {
    mode: 'cors',
    method: 'POST',
    headers: await prepareHeaders(instance),
    body: data && JSON.stringify(data),
  });
};

export default postData;
