export const BarrierMarkerComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
    >
      <div
        style={{
          position: 'relative',
          top: '0',
          width: '70px',
          borderBottom: '3px solid rgb(140, 149, 157)',
          borderLeft: '3px solid transparent',
          borderRight: '3px solid transparent',
          borderRadius: '2px',
          zIndex: '11',
        }}
      ></div>
      <div
        style={{
          width: '70px',
          height: '25px',
          backgroundColor: 'rgb(178, 185, 190)',
          position: 'relative',
          zIndex: '10',
          borderRadius: '1px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '5px',
            width: '15px',
            height: '4px',
            backgroundColor: 'gray',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '5px',
            width: '15px',
            height: '4px',
            backgroundColor: 'gray',
          }}
        ></div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          width: '76px',
          height: '20px',
          borderBottom: '20px solid rgb(178, 185, 190)',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderRadius: '2px',
        }}
      ></div>
    </div>
  );
};
