import { useContext, useEffect, useState } from 'react';
import {
  CloseButton,
  InputBase,
  Group,
  LoadingOverlay,
  Flex,
  Stack,
  Text,
  Center,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import BottomSection from '../components/BottomSection/BottomSection';
import SignList from '../components/SignList/SignList';
import { PWAContext } from '../providers/PWAProvider';
import SignRecognition from '../components/SignRecognition';
import SignRecognitionModal from '../components/SignRecognition/SignRecognitionModal';
import PwaPageHeading from '../components/PwaPageHeading';
import { Sign } from '../../models/Sign';
import EquipmentTypeSelector from '../components/EquipmentTypeSelector/EquipmentTypeSelector';
import { EquipmentType } from '../../models/enums/DeviceEnums';
import BarrierSvg from '../../components/svg/BarrierSvg';

export const PwaIntellitagEquipmentPage = () => {
  const { device, sign, setSign, project, equipmentType } = useContext(PWAContext);
  const [searchString, setSearchString] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [identifiedSign, setIdentifiedSign] = useState<Sign | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextStep = () => navigate('/pwa/projects/summary');

  const handleSignIdentified = (sign: Sign) => {
    setShowModal(true);
    setIdentifiedSign(sign);
  };

  const handleConfirm = () => {
    setSign(identifiedSign);
    setShowModal(false);
    nextStep();
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Flex h="100%" direction="column" pos="relative">
        <Stack mb="md">
          <PwaPageHeading label={`Reference ID: ${device?.referenceId}`}>
            Select equipment
          </PwaPageHeading>
          <EquipmentTypeSelector />
        </Stack>

        {equipmentType === EquipmentType.Barrier ? (
          <Center h="100%">
            <BarrierSvg />
          </Center>
        ) : (
          <Stack style={{ overflow: 'auto' }}>
            <Stack gap="xs">
              <Text fw={500}>Select sign</Text>
              <Group gap={10}>
                <div
                  style={{ flex: 1 }}
                  onClick={() => {
                    if (window._paq) {
                      window._paq.push(['trackEvent', 'Pair device', 'Search clicked']);
                    }
                  }}
                >
                  <InputBase
                    size="md"
                    rightSection={
                      searchString !== '' && (
                        <CloseButton
                          size="sm"
                          onMouseDown={(event) => event.preventDefault()}
                          onClick={() => {
                            setSearchString('');
                          }}
                          aria-label="Clear value"
                        />
                      )
                    }
                    value={searchString}
                    onChange={(event) => setSearchString(event.currentTarget.value)}
                    flex={1}
                    placeholder="Search sign"
                  />
                </div>
                <div
                  onClick={() => {
                    if (window._paq) {
                      window._paq.push([
                        'trackEvent',
                        'Pair device',
                        'AI Button Clicked',
                        device?.referenceId,
                      ]);
                    }
                  }}
                >
                  <SignRecognition
                    isLoading={isLoading}
                    onImageRecognized={handleSignIdentified}
                    setIsLoading={setIsLoading}
                  />
                </div>
              </Group>
            </Stack>

            <SignList
              handleClick={setSign}
              selected={sign}
              searchString={searchString}
              countryCode={project?.countryCode ?? ''}
            />
          </Stack>
        )}

        <BottomSection
          disabled={equipmentType !== EquipmentType.Barrier && (!sign || showModal)}
          gradient
          label={`Continue ${sign?.name ? ` with ${sign?.name} ` : ''}`}
          handleClick={nextStep}
        />
      </Flex>

      <SignRecognitionModal
        identifiedSign={identifiedSign}
        onConfirm={handleConfirm}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};
