import { useEffect, useState } from 'react';
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core';

const MAX_DISPLAYED_VALUES = 2;

type Props = {
  data: string[] | undefined;
  values?: string[];
  width?: string;
  onChange: (depots: string[]) => void;
};

export function CustomMultiSelect({
  data = [],
  values: defaultValues = [],
  onChange,
  width = '300px',
}: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [value, setValue] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => {
    const list = value.includes(val) ? value.filter((v) => v !== val) : [...value, val];
    setValue(list);
    onChange(list);
    setSearch('');
  };

  useEffect(() => {
    if (defaultValues) {
      setValue(defaultValues);
    }
  }, [defaultValues]);

  const handleValueRemove = (val: string) => {
    const list = value.filter((v) => v !== val);
    setValue(list);
    onChange(list);
  };

  const values = value
    .slice(
      0,
      MAX_DISPLAYED_VALUES === value.length ? MAX_DISPLAYED_VALUES : MAX_DISPLAYED_VALUES - 1
    )
    .map((item) => (
      <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {item}
      </Pill>
    ));

  const options = data
    .filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div style={{ width: width }}>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
        <Combobox.DropdownTarget>
          <PillsInput pointer onClick={() => combobox.openDropdown()}>
            <Pill.Group>
              {value.length > 0 ? (
                <>
                  {values}
                  {value.length > MAX_DISPLAYED_VALUES && (
                    <Pill>+{value.length - (MAX_DISPLAYED_VALUES - 1)} more</Pill>
                  )}
                </>
              ) : null}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={values.length > 0 ? '' : 'Pick or search depot'}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown mah={200} style={{ overflowY: 'auto' }}>
          <Combobox.Options>
            {options.length > 0 ? options : <Combobox.Empty>Nothing found...</Combobox.Empty>}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
}
