import dayjs from 'dayjs';
import {
  ARROWBOARD_EVENT_TYPE,
  ArrowboardsReportDto,
  ONLINE_STATUS,
  ONLINE_STATUS_NAMES,
} from '../../../../models/SupervisionReport';
import { ARROW_DIRECTION_NAMES } from '../../../../models/Device';
import { PdfBaseProps } from '../../../../models/PdfExport';
import {
  PDF_CONSTANTS,
  createPdfDocument,
  addHeader,
  createDocumentName,
  addCoverPage,
  addLastPage,
} from './ExportPdfCommon';
import { formatTimeByCountry } from '../../../../utils/utils';

// CONSTANTS
const EVENT_MARKER_COLOR = '#F16238';

export interface PdfProps extends PdfBaseProps {
  rows: ArrowboardsReportDto;
}

export async function createPDF(props: PdfProps): Promise<void> {
  return new Promise<void>((resolve) => {
    const startDateString = dayjs(props.reportStartDate).format('DD MMM YYYY');
    const endDateString = dayjs(props.reportEndDate).format('DD MMM YYYY');

    const doc = createPdfDocument();
    const PAGE_WIDTH = doc.internal.pageSize.getWidth();
    const LEFT_POS = PDF_CONSTANTS.SIDE_MARGIN;
    const RIGHT_POS = PAGE_WIDTH - PDF_CONSTANTS.SIDE_MARGIN;

    function addEventLine(device: ArrowboardsReportDto[0], index: number) {
      const EVENT_LINE_HEIGHT = 40;
      const Y_POS =
        PDF_CONSTANTS.TOP_MARGIN +
        PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG +
        20 +
        index * EVENT_LINE_HEIGHT;
      const TRIANGL_SIZE = 4;
      const LINE_X1 = LEFT_POS + 40;
      const LINE_X2 = RIGHT_POS - 8;

      // TITLE
      doc.setFontSize(16);
      doc.setTextColor('black');
      doc.text(device.referenceId, LEFT_POS, Y_POS, { baseline: 'middle' });

      // LINE
      doc.setLineWidth(1);
      doc.line(LINE_X1 + TRIANGL_SIZE / 2, Y_POS, LINE_X2, Y_POS);

      // EVENTS
      device.events.forEach((event, index) => {
        const LINE_LENGTH = PAGE_WIDTH - (PDF_CONSTANTS.SIDE_MARGIN + 40);

        let EVENT_X_POS;
        if (index === 0) EVENT_X_POS = LINE_X1;
        else if (index === device.events.length - 1) EVENT_X_POS = LINE_X2 - TRIANGL_SIZE / 2;
        else
          EVENT_X_POS = LINE_X1 + (LINE_LENGTH / device.events.length) * index - TRIANGL_SIZE / 2;

        const EVENT_X1 = EVENT_X_POS;
        const EVENT_X2 = EVENT_X_POS + TRIANGL_SIZE / 2;
        const EVENT_X3 = EVENT_X_POS + TRIANGL_SIZE;
        const EVENT_Y = Y_POS + TRIANGL_SIZE / 2;
        doc.setFillColor(EVENT_MARKER_COLOR);
        doc.triangle(EVENT_X1, EVENT_Y, EVENT_X2, EVENT_Y - TRIANGL_SIZE, EVENT_X3, EVENT_Y, 'F');
        doc.setFontSize(12);
        doc.text(
          event.eventType === ARROWBOARD_EVENT_TYPE.OnlineStatus
            ? ONLINE_STATUS_NAMES[event.eventValue as ONLINE_STATUS]
            : ARROW_DIRECTION_NAMES[event.eventValue],
          EVENT_X1 + TRIANGL_SIZE / 2,
          EVENT_Y - TRIANGL_SIZE - 4,
          {
            align: 'center',
          }
        );

        // Format date and time
        const dateTime = dayjs(event.timestamp);
        const time = formatTimeByCountry(event.timestamp, props.countryCode);
        const day = dateTime.date();
        const month = dateTime.format('MMM');

        doc.text(
          `${day} ${month}\n${time}`,
          EVENT_X1 + TRIANGL_SIZE / 2,
          EVENT_Y + TRIANGL_SIZE + 4,
          {
            align: 'center',
          }
        );
      });
    }

    // Generate PDF
    addCoverPage(doc, {
      projectName: props.projectName,
      depot: props.depot,
      startDate: startDateString,
      endDate: endDateString,
    });
    addHeader(doc, {
      projectName: props.projectName,
      title: 'Arrowboards',
      startDate: startDateString,
      endDate: endDateString,
    });

    // Add page number at the bottom
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.BODY);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Page: ${1}`,
      PAGE_WIDTH - PDF_CONSTANTS.SIDE_MARGIN - 20,
      pageHeight - PDF_CONSTANTS.SIDE_MARGIN,
      { align: 'right' }
    );

    props.rows.forEach((device, index) => addEventLine(device, index));
    addLastPage(doc, props.contactPerson);

    doc.save(createDocumentName(props.projectName, startDateString, endDateString, 'Arrowboards'));
    resolve();
  });
}
