import { ForwardedRef } from 'react';
import { PdfBaseProps, PdfExportRef } from '../../../../models/PdfExport';
import { DashboardComponent } from '../../../../models/Dashboard';
import { usePdfExport } from './usePdfExport';
import { ReportType } from '../../../../models/enums/ReportType';

export function useDashboard<T, P extends PdfBaseProps>(
  ref: ForwardedRef<PdfExportRef>,
  props: DashboardComponent<T>,
  createPDF: (props: P) => Promise<void>,
  pdfProps: P,
  title: ReportType
) {
  // Common dashboard logic
  usePdfExport(ref, props, createPDF, pdfProps, title);

  // Common null check
  if (!props.data) {
    return { shouldRender: false };
  }

  return { shouldRender: true };
}
