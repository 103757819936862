import { Stack, Text, Title } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

export const PwaNotAvailableForAssignmentBlockerPage = () => {
  return (
    <Stack flex={1} align="center" justify="center">
      <IconExclamationCircle color="black" size={150} stroke={1.2} />
      <Title order={3} style={{ textAlign: 'center' }}>
        Device cannot be added to a project.
      </Title>
      <Text c="black">Select a different device</Text>
    </Stack>
  );
};
