import { Device, DeviceType } from '../../../../models/Device';
import { DeviceDefaultImage } from './DeviceDefaultImage';
import { Center, Image, MantineStyleProp, Stack } from '@mantine/core';
import {
  getArrowDirectionImage,
  getImageUrlForSign,
  getPoweredEquipmentImage,
  getPSAImage,
} from '../../../../utils/ImageHelpers';
import { EquipmentType, PoweredEquipment } from '../../../../models/enums/DeviceEnums';
import {
  IconBarrierBlock,
  IconPlugConnected,
  IconRoadSign,
  IconSolarPanel,
} from '@tabler/icons-react';

type Props = {
  baseDevice: Device;
  height?: 'auto' | 'fit-content';
};

export const DeviceImageWrapper = ({ baseDevice, height = 'auto' }: Props) => {
  const ImageProps = {
    height: height,
    width: 'auto',
    maxHeight: '60px',
    maxWidth: '40px',
    marginRight: 'sm',
  } as MantineStyleProp;

  if (baseDevice.deviceType == DeviceType.ArrowBoard && baseDevice.arrowDirection != null) {
    const img = getArrowDirectionImage(baseDevice.arrowDirection);
    if (img) return <Image style={ImageProps} src={img} />;
  }
  if (baseDevice.deviceType == DeviceType.SmartCable && baseDevice.poweredEquipment != null) {
    const img = getPoweredEquipmentImage(baseDevice.poweredEquipment);
    if (img)
      return (
        <>
          {baseDevice.poweredEquipment === PoweredEquipment.Other ? (
            <IconPlugConnected size={40} />
          ) : (
            <Stack style={{ ...ImageProps, position: 'relative' }}>
              <Center
                pos="absolute"
                bottom={0}
                right={0}
                bg="white"
                p="2px"
                bd="solid black 1px"
                style={{ borderRadius: '50%' }}
              >
                <IconPlugConnected size={16} />
              </Center>
              <Image src={img} />
            </Stack>
          )}
        </>
      );
  }
  if (baseDevice.deviceType == DeviceType.SmartSolar && baseDevice.poweredEquipment != null) {
    const img = getPoweredEquipmentImage(baseDevice.poweredEquipment);
    if (img)
      return (
        <>
          {baseDevice.poweredEquipment === PoweredEquipment.Other ? (
            <IconSolarPanel size={40} />
          ) : (
            <Stack style={{ ...ImageProps, position: 'relative' }}>
              <Center
                pos="absolute"
                bottom={0}
                right={0}
                bg="white"
                p="2px"
                bd="solid black 1px"
                style={{ borderRadius: '50%' }}
              >
                <IconSolarPanel size={16} />
              </Center>
              <Image src={img} />
            </Stack>
          )}
        </>
      );
  }
  if (baseDevice.deviceType == DeviceType.PSA) {
    const img = getPSAImage(baseDevice.deviceModel);
    if (img) return <Image style={ImageProps} src={img} />;
  }
  if (baseDevice.deviceType == DeviceType.Intellitag) {
    if (baseDevice.currentEquipmentType == EquipmentType.Sign) {
      const img = getImageUrlForSign(baseDevice.attachmentRef);
      if (img) return <Image style={ImageProps} src={img} />;
      return <IconRoadSign stroke={1.5} color="gray" size={40} />;
    }
    if (baseDevice.currentEquipmentType == EquipmentType.Barrier) {
      return <IconBarrierBlock stroke={1.5} color="gray" size={40} />;
    }
  }
  return <DeviceDefaultImage deviceType={baseDevice.deviceType} />;
};
