import { getAccessToken } from '../../auth';
import { IPublicClientApplication } from '@azure/msal-browser';

export async function prepareHeaders(
  instance: IPublicClientApplication,
  isFormData: boolean = false
): Promise<Headers> {
  const headers = new Headers();
  if (!isFormData) {
    headers.set('Content-Type', 'application/json');
  }
  const accessToken = await getAccessToken(instance);
  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }
  return headers;
}
export function buildQueryString(params: { [key: string]: any }): string {
  const queryString = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
  return queryString ? `?${queryString}` : '';
}

export interface APIResponseList<T> {
  items: T[];
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  nextPage?: number;
}
