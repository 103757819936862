import { PoweredEquipment, PoweredEquipmentNames } from '../../../../models/enums/DeviceEnums';
import { Device, DeviceTypeNames } from '../../../../models/Device';
import { useEffect, useState } from 'react';
import { Box, Input, InputWrapper, Select, Stack, Text } from '@mantine/core';
import { getDeviceInfoDescription, getDeviceInfoLabel } from '../../../../copy';

interface Props {
  device: Device;
  country: string;
  onChange: (data: SmartCableSubmitData) => void;
}

export interface SmartCableSubmitData {
  poweredEquipment: PoweredEquipment;
  currentName: string | null;
}

export const SmartCableInput = ({ device, country, onChange }: Props) => {
  const [poweredEquipment, setPoweredEquipment] = useState<PoweredEquipment>(
    PoweredEquipment.Other
  );
  const [currentName, setCurrentName] = useState<string | null>(null);

  useEffect(() => {
    onChange({
      poweredEquipment: poweredEquipment,
      currentName: currentName,
    });
  }, [currentName, onChange, poweredEquipment, setPoweredEquipment]);

  return (
    <Stack>
      <Box w={'100%'}>
        <Text size="sm" fw={600}>
          Device Type
        </Text>
        <Text fw={300}>{DeviceTypeNames[device.deviceType]}</Text>
        <Text size="xs" c={'gray.6'}>
          {device.referenceId}
        </Text>
      </Box>

      <Select
        label="Select Powered Equipment"
        data={Object.values(PoweredEquipment)
          .filter(
            (value): value is PoweredEquipment =>
              typeof value === 'number' && value !== PoweredEquipment.Unknown
          )
          .map((key) => ({
            value: key.toString(),
            label: PoweredEquipmentNames[key],
          }))}
        value={poweredEquipment.toString()}
        onChange={(value) => {
          if (value) {
            const equipment = parseInt(value, 10) as PoweredEquipment;
            setPoweredEquipment(equipment);
          }
        }}
        placeholder="Choose equipment"
      />

      <InputWrapper
        w={'100%'}
        label={getDeviceInfoLabel(country ?? '')}
        description={getDeviceInfoDescription(country ?? '')}
      >
        <Input
          value={currentName ?? ''}
          onChange={(event) => {
            const value = event.currentTarget.value;
            setCurrentName(value);
          }}
        />
      </InputWrapper>
    </Stack>
  );
};
