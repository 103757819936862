import { IconPlugConnected, IconSolarPanel } from '@tabler/icons-react';
import { PowerSource } from '../../../../../../models/enums/DeviceEnums';

interface Props {
  poweredBy: PowerSource;
  size: number;
}
export const PowerIcon = ({ poweredBy, size }: Props) =>
  poweredBy === PowerSource.SmartCable ? (
    <IconPlugConnected size={size} />
  ) : (
    <IconSolarPanel size={size} />
  );
