import React from 'react';
import { Card, Text, Group, RingProgress } from '@mantine/core';

interface ProvisioningSummaryCardProps {
  totalDevices: number;
  totalConnectedDevices: number;
  totalNotConnectedDevices: number;
  totalProvisionings: number;
  completedProvisionings: number;
}

const ProvisioningSummaryCard: React.FC<ProvisioningSummaryCardProps> = ({
  totalDevices,
  totalConnectedDevices,
  totalNotConnectedDevices,
  totalProvisionings,
  completedProvisionings,
}) => {
  const connectedPercentage = (totalConnectedDevices / totalDevices) * 100;
  const provisioningCompletionPercentage = (completedProvisionings / totalProvisionings) * 100;

  return (
    <Card withBorder radius="md" p="lg" shadow="sm">
      <Group justify="space-between">
        <div>
          <Text size="lg" fw={500}>
            Provisioning Summary
          </Text>
          <Text size="sm" color="dimmed">
            Overview of device connections
          </Text>
        </div>
      </Group>

      <Group justify="left" mt="md" gap="lg">
        <div>
          <Text size="sm" color="dimmed">
            Total Devices
          </Text>
          <Text fw={700}>{totalDevices}</Text>
        </div>
        <div>
          <Text size="sm" color="dimmed">
            Connected
          </Text>
          <Text fw={700} color="green">
            {totalConnectedDevices}
          </Text>
        </div>
        <div>
          <Text size="sm" color="dimmed">
            Not Connected
          </Text>
          <Text fw={700} color="red">
            {totalNotConnectedDevices}
          </Text>
        </div>
        <RingProgress
          size={80}
          thickness={9}
          sections={[{ value: connectedPercentage, color: 'green' }]}
          rootColor="red"
          label={
            <Text size="xs" ta="center">
              {totalConnectedDevices}/{totalDevices}
            </Text>
          }
        />
      </Group>

      <Group justify="left" mt="md" gap="lg">
        <div>
          <Text size="sm" color="dimmed">
            Total Provisionings
          </Text>
          <Text fw={700}>{totalProvisionings}</Text>
        </div>
        <div>
          <Text size="sm" color="dimmed">
            Completed Provisionings
          </Text>
          <Text fw={700} color={'green'}>
            {completedProvisionings}
          </Text>
        </div>
        <div>
          <Text size="sm" color="dimmed">
            Incomplete Provisionings
          </Text>
          <Text fw={700} color="red">
            {totalProvisionings - completedProvisionings}
          </Text>
        </div>
        <RingProgress
          size={80}
          thickness={9}
          rootColor="red"
          sections={[{ value: provisioningCompletionPercentage, color: 'green' }]}
          label={
            <Text size="xs" ta="center">
              {completedProvisionings}/{totalProvisionings}
            </Text>
          }
        />
      </Group>
    </Card>
  );
};

export default ProvisioningSummaryCard;
