import { Group, Text } from '@mantine/core';
import { DeviceLog, GetArrowDirectionFromDeviceLog } from '../../../models/DeviceLog.ts';

export const ArrowBoardDeviceLogTableRow = (log: DeviceLog) => {
  return (
    <Group p="apart" gap="m" m="xs">
      <Text size="sm">
        <strong>Battery (V): </strong>
        {log.externalBatteryVoltage ?? '-'}
      </Text>
      <Text size="sm">
        <strong>Arrow Direction: </strong>
        {GetArrowDirectionFromDeviceLog(log)}
      </Text>
    </Group>
  );
};
