import {
  CATEGORIES_SE,
  DEsigns,
  FIsigns,
  NOsigns,
  SEsigns,
  UKsigns,
  Map,
  CATEGORIES_NO,
  CATEGORIES_FI,
  CAsigns,
  CATEGORIES_CA,
} from '../config/roadsigns';
import { Device } from '../models/Device';
import { EquipmentType } from '../models/enums/DeviceEnums';

export const getSignIdByName = (countryCode: string, signName: string): string | null => {
  switch (
    countryCode // TODO Fetch from hook
  ) {
    case 'SE':
      return SEsigns[signName] ?? null;
    case 'GB':
      return UKsigns[signName] ?? null;
    case 'No':
      return NOsigns[signName] ?? null;
    case 'FI':
      return FIsigns[signName] ?? null;
    case 'DE':
      return DEsigns[signName] ?? null;
    default:
      return null;
  }
};

export const getSignNameBySignId = (countryCode: string, signId: string): string | null => {
  switch (
    countryCode // TODO Fetch from hook
  ) {
    case 'SE':
      return Object.keys(SEsigns).find((key) => SEsigns[key] === signId) ?? null;
    case 'GB':
      return Object.keys(UKsigns).find((key) => UKsigns[key] === signId) ?? null;
    case 'NO':
      return Object.keys(NOsigns).find((key) => NOsigns[key] === signId) ?? null;
    case 'FI':
      return Object.keys(FIsigns).find((key) => FIsigns[key] === signId) ?? null;
    case 'DE':
      return Object.keys(DEsigns).find((key) => DEsigns[key] === signId) ?? null;
    default:
      return null;
  }
};

export const getAllSigns = (countryCode: string): Map => {
  switch (
    countryCode // TODO Fetch from hook
  ) {
    case 'SE':
      return SEsigns;
    case 'GB':
      return UKsigns;
    case 'NO':
      return NOsigns;
    case 'FI':
      return FIsigns;
    case 'DE':
      return DEsigns;
    case 'CA':
      return CAsigns;
    default:
      return {};
  }
};

export const getSignCategories = (countryCode: string): Map | null => {
  switch (
    countryCode // TODO Fetch from hook
  ) {
    case 'SE':
      return CATEGORIES_SE;
    case 'NO':
      return CATEGORIES_NO;
    case 'FI':
      return CATEGORIES_FI;
    case 'DE':
      return null;
    case 'CA':
      return CATEGORIES_CA;
    default:
      return null;
  }
};

export const getCategoryFromSignName = ({
  countryCode,
  signName,
}: {
  countryCode: string;
  signName: string;
}) => {
  switch (countryCode) {
    case 'SE':
      return signName[0];
    case 'NO':
      return signName[0];
    case 'FI':
      return signName[0];
    case 'DE':
      return null;
    case 'CA':
      return signName[0];
    default:
      return null;
  }
};

export const getAttachmentText = (device: Device, countryCode: string): string | null => {
  if (device.currentEquipmentType === EquipmentType.Barrier) {
    return 'Barrier';
  }
  const countrySigns = getAllSigns(countryCode);

  const name =
    Object.keys(countrySigns)[Object.values(countrySigns).indexOf(device?.attachmentRef)];
  return name || null;
};
