import { useMutation } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';

interface ToggleExternalConnectionParams {
  id: string;
}

const path = `/workZone/toggle-external-connection`;

const useToggleExternalConnection = (instance: IPublicClientApplication) => {
  const { mutateAsync, mutate, isPending } = useMutation({
    mutationFn: (params: ToggleExternalConnectionParams) => {
      return postData(path, params, instance);
    },
    onError: (error) => {
      console.error('Toggle external connection failed:', error);
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
  };
};

export default useToggleExternalConnection;
