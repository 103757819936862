import { Badge, Group, Text } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

import styles from './styles.module.css';
import { AlarmStatus } from '../../models/enums/DeviceEnums';

type Props = {
  status?: AlarmStatus;
  number?: number;
  size?: 'md' | 'lg';
};

export default function StatusBadge({ status, number, size = 'lg' }: Props) {
  if (!status) return null;

  const getBatteryIcon = () =>
    status === AlarmStatus.Alarming && (
      <IconExclamationCircle color="black" stroke={1.5} size={18} />
    );

  return (
    <Badge
      size={size ? size : 'lg'}
      variant={status === AlarmStatus.OK ? 'dot' : 'default'}
      className={styles[AlarmStatus[status]]}
      leftSection={getBatteryIcon()}
    >
      <Group gap={6} ml={2}>
        <Text size={size == 'md' ? 'xs' : 'sm'} c="black" fw={500}>
          {`${number || ''} ${AlarmStatus[status]}`}
        </Text>
      </Group>
    </Badge>
  );
}
