import { Image, Stack, Stepper, Title } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
  ARROWBOARD_EVENT_TYPE,
  ArrowboardsReportDto,
  ONLINE_STATUS,
  ONLINE_STATUS_NAMES,
} from '../../../../../models/SupervisionReport';
import { ARROW_DIRECTION, ARROW_DIRECTION_NAMES } from '../../../../../models/Device';
import { PdfExportRef } from '../../../../../models/PdfExport';
import { useDashboard } from '../../hooks/useDashboard';
import { createPDF, PdfProps } from '../../utils/ExportPdfArrowboard';
import arrowOffImg from '../../../../../assets/ArrowBoardIcons/arrow_board_off.png';
import arrowLeftImg from '../../../../../assets/ArrowBoardIcons/arrow_board_left.png';
import arrowRightImg from '../../../../../assets/ArrowBoardIcons/arrow_board_right.png';
import arrowBarImg from '../../../../../assets/ArrowBoardIcons/arrow_board_bar_only.png';
import arrowLeftRightImg from '../../../../../assets/ArrowBoardIcons/arrow_board_double_arrow.png';
import { forwardRef } from 'react';
import { ArrowboardDashboardProps } from '../../../../../models/DashboardTypes';
import { ReportType } from '../../../../../models/enums/ReportType';
import { formatTimeByCountry } from '../../../../../utils/utils';

const ArrowIcon = ({
  arrowDirection,
  size,
}: {
  arrowDirection: ARROW_DIRECTION;
  size?: number;
}) => {
  let iconSrc;
  if (arrowDirection === ARROW_DIRECTION.Off) iconSrc = arrowOffImg;
  else if (arrowDirection === ARROW_DIRECTION.Left) iconSrc = arrowLeftImg;
  else if (arrowDirection === ARROW_DIRECTION.Right) iconSrc = arrowRightImg;
  else if (arrowDirection === ARROW_DIRECTION.Bar) iconSrc = arrowBarImg;
  else if (arrowDirection === ARROW_DIRECTION.LeftRight) iconSrc = arrowLeftRightImg;

  return <Image src={iconSrc} w={size} />;
};

const ArrowboardDashboard = forwardRef<PdfExportRef, ArrowboardDashboardProps>((props, ref) => {
  const { shouldRender } = useDashboard<ArrowboardsReportDto, PdfProps>(
    ref,
    props,
    createPDF,
    {
      rows: props.data ?? [],
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      depot: props.depot,
      countryCode: props.countryCode,
    },
    ReportType.Arrowboards
  );

  if (!shouldRender || !props.data?.length) {
    return null;
  }

  return (
    <Stack>
      <Title order={3}>Arrowboards (BETA)</Title>
      {props.data.map((device) => (
        <Stack key={device.referenceId}>
          <Title order={4} size="h5">
            {device.referenceId}
          </Title>
          <Stepper active={device.events.length} mx="md">
            {device.events.map((event, index) => (
              <Stepper.Step
                key={index}
                mb="xl"
                color="none"
                label={
                  event.eventType === ARROWBOARD_EVENT_TYPE.OnlineStatus
                    ? ONLINE_STATUS_NAMES[event.eventValue as ONLINE_STATUS]
                    : ARROW_DIRECTION_NAMES[event.eventValue as ARROW_DIRECTION]
                }
                description={`${dayjs(event.timestamp).format('MMM DD')}, ${formatTimeByCountry(dayjs(event.timestamp).toISOString(), props.countryCode)}`}
                completedIcon={
                  event.eventType === ARROWBOARD_EVENT_TYPE.ArrowChange ? (
                    <ArrowIcon size={32} arrowDirection={event.eventValue as ARROW_DIRECTION} />
                  ) : (
                    <IconCircleFilled
                      style={{
                        color: event.eventValue === ONLINE_STATUS.Online ? '#008a19' : '#e53400',
                      }}
                    />
                  )
                }
                styles={{
                  step: { position: 'relative' },
                  stepBody: {
                    position: 'absolute',
                    margin: 0,
                    top: '-16px',
                    left: '-50%',
                    width: 'max-content',
                  },
                  stepLabel: {
                    marginBottom: '40px',
                    textAlign: 'center',
                    fontSize: '14px',
                  },
                  stepDescription: {
                    fontSize: '12px',
                    textAlign: 'center',
                  },
                }}
              />
            ))}
          </Stepper>
        </Stack>
      ))}
    </Stack>
  );
});

export default ArrowboardDashboard;
