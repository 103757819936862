import { Loader, Select } from '@mantine/core';
import { useState } from 'react';
import useInfProjectList from '../../../../data/hooks/Project/useInfProjectListHook.ts';
import { useMsal } from '@azure/msal-react';
import { WorkZone } from '../../../../models/Project.ts';

type Props = {
  onSelect: (project: WorkZone) => void;
};

export const SelectProjectInput = ({ onSelect }: Props) => {
  const { instance } = useMsal();

  const [searchString, setSearchString] = useState<string>('');
  const [selected, setSelected] = useState<WorkZone | null>(null);

  const {
    projects,
    isLoading: projectsFetching,
    handleSearchChange: projectSearch,
  } = useInfProjectList(instance);

  return (
    <Select
      label="Select project"
      ta="left"
      key={selected?.id || 'default'}
      data={Object.values(projects).map((p) => ({
        value: p.id,
        label: p.name,
      }))}
      searchable
      searchValue={searchString} // Bind the search input to `searchString`
      onSearchChange={(value) => {
        setSearchString(value); // Update the search string
        projectSearch(value); // Trigger the project search logic
      }}
      clearable
      onChange={(value) => {
        const selected = Object.values(projects).find((p) => p.id === value) ?? null;
        setSelected(selected);

        if (selected) {
          onSelect(selected);
        }
      }}
      rightSection={projectsFetching && <Loader size={18} />}
      placeholder="Search for project name"
      nothingFoundMessage={
        searchString.trim().length > 0 ? 'No project with that name found...' : undefined
      }
    />
  );
};
