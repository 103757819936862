import { Fragment, forwardRef, useState } from 'react';
import { Table, Text, Title, ScrollArea, Group, Switch, Stack, Checkbox } from '@mantine/core';
import circleDotted from '../../../../../assets/report/StatusIcons/circle-dotted.png';
import circleAlert from '../../../../../assets/report/StatusIcons/alert-circle.png';
import circleCheck from '../../../../../assets/report/StatusIcons/circle-check.png';
import squareGray from '../../../../../assets/report/StatusIcons/square-gray.png';
import roadSignImg from '../../../../../assets/road-sign.png';
import barrierImg from '../../../../../assets/barrier-block.png';
import { useMobileDevice } from '../../../../../hooks/useMobileDevice';
import { AlarmStatus, EquipmentType } from '../../../../../models/enums/DeviceEnums';
import { DeviceEntry, SupervisionReportDto } from '../../../../../models/SupervisionReport';
import { createPDF, PdfProps } from '../../utils/ExportPdfIntellitag';
import { IconBarrierBlock, IconRoadSign } from '@tabler/icons-react';
import { getImageUrlForSign } from '../../../../../utils/ImageHelpers';
import { PdfExportRef } from '../../../../../models/PdfExport';
import { useDashboard } from '../../hooks/useDashboard';
import { IntellitagDashboardProps } from '../../../../../models/DashboardTypes';
import { ReportType } from '../../../../../models/enums/ReportType';
import { formatTimeByCountry } from '../../../../../utils/utils';

function getNextTimestamp(dateString: string, intervalInHours: number | null): string {
  const date = new Date(dateString);
  date.setHours(date.getHours() + (intervalInHours ?? 0));
  return date.toISOString();
}

function formatColumnHeader(
  timestamp: string,
  nextTimestamp: string,
  countryCode: string
): string[] {
  const date = new Date(timestamp);
  const day = date.toLocaleDateString('en-US', { weekday: 'short' });

  const time = formatTimeByCountry(timestamp, countryCode);
  const nextTime = formatTimeByCountry(nextTimestamp, countryCode);

  if (countryCode === 'GB' || countryCode === 'CA') {
    return [day, time, nextTime];
  }

  return [day, time, nextTime];
}

const IntellitagDashboard = forwardRef<PdfExportRef, IntellitagDashboardProps>((props, ref) => {
  const isMobile = useMobileDevice();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showSnoozed, setShowSnoozed] = useState<boolean>(true);
  const imageMap = new Map<string, HTMLImageElement>();
  const imagePromises: Promise<void>[] = [];

  const allTimestamps = props.data?.intervals
    ? Array.from(new Set(props.data.intervals)).sort()
    : [];
  const filteredRows = props.data?.rows.filter((_, idx) => !selectedRows.includes(idx)) ?? [];

  const { shouldRender } = useDashboard<SupervisionReportDto, PdfProps>(
    ref,
    props,
    createPDF,
    {
      timeStamps: allTimestamps,
      intervalInHours: props.intervalInHours ?? 8,
      rows: filteredRows,
      showSnoozed: showSnoozed,
      imageMap,
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      depot: props.depot,
      countryCode: props.countryCode,
    },
    ReportType.Intellitags
  );

  if (!shouldRender || !props.data?.rows.length) {
    return null;
  }

  const getStatus = (entry: DeviceEntry) => {
    const logsToShow = entry?.logs?.length
      ? showSnoozed
        ? entry.logs
        : entry.logs.filter((dl) => !dl.workZoneSnoozed)
      : [];

    const iconSrc = () => {
      if (logsToShow.length === 0) return circleDotted;
      if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming)) return circleAlert;
      return circleCheck;
    };

    return (
      <>
        <img width="20px" src={iconSrc()} alt="" />
        {logsToShow.length}
      </>
    );
  };

  const statusExplanation = (text: string, img: string) => {
    return (
      <Group gap="xs" align="center">
        <img width="20px" src={img} alt="" />
        <Text c="gray.7" size="xs">
          {text}
        </Text>
      </Group>
    );
  };

  const getRoadSignImageAsync = (attachmentRef: string): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
      const roadSignUrl = getImageUrlForSign(attachmentRef as string, true);
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = roadSignUrl ?? '';
      img.onload = () => resolve(img);
      img.onerror = () => {
        const fallbackImg = new Image();
        fallbackImg.src = roadSignImg;
        resolve(fallbackImg);
      };
    });
  };

  // Remove unwanted characters from the name
  filteredRows.forEach((row) => {
    row.name = row.name.replace(/(\r\n|\n|\r)/gm, ' ');
  });

  filteredRows.forEach((row) => {
    const promise = getRoadSignImageAsync(row.attachmentRef).then((img) => {
      if (!row.attachmentRef) {
        if (row.currentEquipmentType === EquipmentType.Barrier) {
          img.src = barrierImg;
          imageMap.set(EquipmentType.Barrier.toString(), img);
        } else {
          img.src = roadSignImg;
          imageMap.set(EquipmentType.Sign.toString(), img);
        }
        return;
      }
      imageMap.set(row.attachmentRef, img);
    });
    imagePromises.push(promise);
  });

  const getRoadSign = (attachmentRef: string) => {
    const roadSignUrl = getImageUrlForSign(attachmentRef);
    return roadSignUrl ? (
      <img
        style={{
          maxWidth: '40px',
          maxHeight: '25px',
          width: 'auto',
          height: 'auto',
        }}
        src={roadSignUrl}
        alt=""
      />
    ) : (
      <IconRoadSign stroke={1.5} color="gray" size={25} />
    );
  };

  const equipmentImage = (currentEquipmentType: EquipmentType, attachmentRef: string) =>
    currentEquipmentType === EquipmentType.Barrier ? (
      <IconBarrierBlock stroke={1.5} color="gray" size={25} />
    ) : (
      getRoadSign(attachmentRef)
    );

  return (
    <Stack>
      <Group justify="space-between" align="end">
        <Title order={3}>Intellitags</Title>
        <Group>
          <Text c="gray.7" size="xs">
            Interval: {props.intervalInHours}h
          </Text>
          {[
            { text: 'OK', img: circleCheck },
            { text: 'Fallen', img: circleAlert },
            { text: 'No Log', img: circleDotted },
            { text: 'Snoozed', img: squareGray },
          ].map((s, index) => (
            <Fragment key={index}>{statusExplanation(s.text, s.img)}</Fragment>
          ))}
          <Switch
            label="Show snoozed"
            checked={showSnoozed}
            onChange={(event) => setShowSnoozed(event.currentTarget.checked)}
          />
        </Group>
      </Group>

      <ScrollArea.Autosize mah="55vh">
        <Table
          withRowBorders
          highlightOnHover
          withTableBorder
          withColumnBorders
          bg="white"
          stickyHeader
          id="pdf"
        >
          <Table.Thead bg="deepGreen.7" c="white">
            <Table.Tr>
              <Table.Th ta="center" miw="10px">
                Hide
              </Table.Th>
              {isMobile ? null : (
                <Table.Th ta="center" miw="120px">
                  Reference Id
                </Table.Th>
              )}
              <Table.Th ta="center" miw="120px">
                Additional info
              </Table.Th>
              <Table.Th ta="center" miw="60px">
                Attachment
              </Table.Th>
              {allTimestamps.map((timestamp, index) => (
                <Table.Th fw={500} ta="center" key={index}>
                  {formatColumnHeader(
                    timestamp,
                    getNextTimestamp(timestamp, props.intervalInHours),
                    props.countryCode
                  ).map((line, i) => (
                    <Text key={i} size="sm" fw="600" style={{ whiteSpace: 'nowrap' }}>
                      {line}
                      <br />
                    </Text>
                  ))}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {props.data.rows.map((row, rowIndex) => (
              <Table.Tr
                key={rowIndex}
                style={{
                  opacity: selectedRows.includes(rowIndex) ? 0.5 : 1,
                }}
              >
                <Table.Td ta="center">
                  <Checkbox
                    data-testid={`hideCheckbox-${rowIndex}`}
                    checked={selectedRows.includes(rowIndex)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSelectedRows(
                        event.currentTarget.checked
                          ? [...selectedRows, rowIndex]
                          : selectedRows.filter((idx) => idx !== rowIndex)
                      )
                    }
                  />
                </Table.Td>
                {isMobile ? null : <Table.Td ta="center">{row.referenceId}</Table.Td>}
                <Table.Td ta="center">{row.name}</Table.Td>
                <Table.Td ta="center">
                  {equipmentImage(row.currentEquipmentType, row.attachmentRef)}
                </Table.Td>
                {allTimestamps.map((_, colIndex) => (
                  <Table.Td
                    key={colIndex}
                    ta="center"
                    style={{
                      background:
                        showSnoozed &&
                        row.entries[colIndex]?.logs?.some((log) => log.workZoneSnoozed)
                          ? '#CED4DA'
                          : 'transparent',
                    }}
                  >
                    {getStatus(row.entries[colIndex])}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea.Autosize>
    </Stack>
  );
});

export default IntellitagDashboard;
