import { Button } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { PWAContext } from '../../providers/PWAProvider';
import { EquipmentType } from '../../../models/enums/DeviceEnums';

const EQUIPMENT = [
  { name: 'Sign', type: EquipmentType.Sign },
  { name: 'Barrier', type: EquipmentType.Barrier },
];

export default function EquipmentTypeSelector() {
  const { equipmentType, setEquipmentType, setSign } = useContext(PWAContext);

  useEffect(() => setEquipmentType(EquipmentType.Sign), [setEquipmentType]);

  const handleEquipmentSelect = (type: EquipmentType) => {
    setEquipmentType(type);
    setSign(null);
  };

  return (
    <Button.Group w="100%">
      {EQUIPMENT.map((item) => (
        <Button
          key={item.type}
          flex={1}
          size="md"
          onClick={() => handleEquipmentSelect(item.type)}
          variant={equipmentType === item.type ? 'filled' : 'light'}
        >
          {item.name}
        </Button>
      ))}
    </Button.Group>
  );
}
