import { useContext, useEffect } from 'react';
import { Button, Flex } from '@mantine/core';
import { PWAContext } from '../providers/PWAProvider.tsx';
import { useDeviceConnection } from '../hooks/useDeviceConnection.tsx';
import DeviceSummary from '../components/DeviceSummary.tsx';
import { getAllSigns } from '../../utils/signs.ts';

export const PwaDisconnectPage = () => {
  const { disconnect } = useDeviceConnection();
  const { device, project } = useContext(PWAContext);
  const countrySigns = getAllSigns(project?.countryCode ?? '');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deviceEquipmentValue = project
    ? `${project?.countryCode}_${
        Object.keys(countrySigns)[Object.values(countrySigns).indexOf(device?.attachmentRef)]
      }`
    : undefined;

  return (
    <Flex h="100%" direction="column" justify="space-between" mb="lg">
      <DeviceSummary
        projectName={device?.workZoneName || '-'}
        referenceID={device?.referenceId || '-'}
        deviceType={device?.deviceType}
        equipment={
          device?.currentEquipmentType
            ? {
                type: device.currentEquipmentType,
                name: device?.currentName || '-',
                value: deviceEquipmentValue,
              }
            : undefined
        }
        poweredEquipment={device?.poweredEquipment || null}
        additionalInfo={device?.currentName}
      />

      <Button
        disabled={!device}
        size="xl"
        w="100%"
        bg="dangerRed"
        onClick={() => device && disconnect(device)}
      >
        Remove from project
      </Button>
    </Flex>
  );
};
