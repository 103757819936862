import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import React, { useEffect } from 'react';
import { Device } from '../../models/Device';

interface Props {
  width?: string;
  height?: string;
  defaultCenter?: { lat: number; lng: number };
  defaultZoom?: number;
  disableDefaultUI?: boolean;
  markers?: React.JSX.Element[];
  selectedDevice?: Device | null;
}

const LightMapSetup = ({
  width = '100%',
  height = '600px',
  defaultCenter = { lat: 59.32709, lng: 18.06788 },
  defaultZoom = 6,
  disableDefaultUI = false,
  markers = [],
  selectedDevice,
}: Props) => {
  const map = useMap();

  useEffect(() => {
    if (selectedDevice && map && selectedDevice.position) {
      const { coordinates } = selectedDevice.position;
      const latLng = { lat: coordinates[1], lng: coordinates[0] };
      map.panTo(latLng);
    }
  }, [selectedDevice, map]);

  return (
    <Map
      style={{ width: width, height: height }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      gestureHandling={'greedy'}
      disableDefaultUI={disableDefaultUI}
      mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
    >
      {markers.map((marker, index) => React.cloneElement(marker, { key: `marker-${index}` }))}
    </Map>
  );
};

const LightMap = (props: Props) => {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
      <LightMapSetup {...props} />
    </APIProvider>
  );
};

export default LightMap;
