import { QRCode } from '../components/Scanner/components/Scanner/Scanner';

export const parseQRCode = (rawValue: string): QRCode | null => {
  const parsers = [parseV1, parseHRSIntellitagV1];

  for (const parse of parsers) {
    const qrCode = parse(rawValue);
    if (qrCode) return qrCode;
  }

  return null;
};

const parseV1 = (rawValue: string): QRCode | null => {
  try {
    const parsedData = JSON.parse(rawValue) as QRCode;
    return parsedData.imei && parsedData.referenceId && parsedData.version ? parsedData : null;
  } catch {
    return null;
  }
};

const parseHRSIntellitagV1 = (rawValue: string): QRCode | null => {
  const imeiMatch = rawValue.match(/IMEI:(\d+)/);
  const referenceIdMatch = rawValue.match(/ID:(.+)/);

  return imeiMatch && referenceIdMatch
    ? {
        imei: imeiMatch[1],
        referenceId: referenceIdMatch[1],
        version: 'HRS Intellitag 1.0',
      }
    : null;
};
