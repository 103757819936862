import { Center, Overlay, Stack, Text } from '@mantine/core';
import { IconMapPinOff } from '@tabler/icons-react';
import nordicsImg from '../assets/map_nordics.png';

type Props = {
  height?: string;
};

export const NoMapData = ({ height = '100%' }: Props) => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: height,
        }}
      >
        <Center w={'100%'} h={'100%'} pos={'absolute'}>
          <Stack justify="center" align="center" style={{ zIndex: 101 }}>
            <IconMapPinOff size={'48px'} />
            <Text opacity={0.7} fw={300}>
              No map data available
            </Text>
          </Stack>
        </Center>
        <img
          src={nordicsImg}
          alt="No Map Data"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: height,
          }}
        />
        <Overlay zIndex={100} color="#fff" backgroundOpacity={0.35} blur={10} />
      </div>
    </>
  );
};
