import { IPublicClientApplication } from '@azure/msal-browser';
import { prepareHeaders } from '../../api/apiHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = '/UserAccess/add';

interface Params {
  userId: string;
  resource: string;
  scope: number;
}

const useAddUserAccess = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError } = useMutation({
    mutationFn: async (params: Params) => {
      await fetch(`${baseUrl}${path}`, {
        mode: 'cors',
        method: 'POST',
        headers: await prepareHeaders(instance),
        body: JSON.stringify(params),
      });
    },
    onError: (error) => {
      console.error(`Error adding user access`, error);
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['UserAccess', variables.userId] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
  };
};

export default useAddUserAccess;
