import { useMsal } from '@azure/msal-react';
import { BackgroundImage, Button, Stack, Image } from '@mantine/core';
import { loginRequest } from '../auth';
import logo from '../assets/R_L1_gul_neg_RGB.svg';
import login_screen from '../assets/login_screen.jpg';

export default function Login() {
  const { instance } = useMsal();
  const handleLogin = async (isInternal: boolean) => {
    const authority = isInternal
      ? import.meta.env.VITE_AUTH_AUTHORITY_INTERNAL
      : import.meta.env.VITE_AUTH_AUTHORITY_EXTERNAL;

    localStorage.setItem('selectedAuthority', authority);

    const loginRequestWithAuthority = {
      ...loginRequest,
      authority: authority,
      redirectStartPage: '/after-login',
    };
    try {
      await instance.loginRedirect(loginRequestWithAuthority);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <BackgroundImage src={login_screen} h={'100vh'}>
      <div style={{ backgroundColor: 'rgba(0,0,0,0.4)', height: '100%' }}>
        <Stack h={'100%'} justify="center" align="center">
          <Image content="center" src={logo} maw={'400px'} />
          <Button
            size="md"
            w="300px"
            px={'48px'}
            variant="filled"
            bg={'deepGreen.8'}
            onClick={() => handleLogin(true)}
          >
            Ramudden SSO Login
          </Button>
          <Button
            size="md"
            w="300px"
            px={'48px'}
            variant="outline"
            bg={'deepGreen.0'}
            onClick={() => handleLogin(false)}
          >
            External Login
          </Button>
        </Stack>
      </div>
    </BackgroundImage>
  );
}
