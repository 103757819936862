import { useState } from 'react';
import { Stack, Select, Title, Divider, Container, Grid, Text } from '@mantine/core';
import useCountries from '../data/hooks/Country/useCountriesHook';
import { useMsal } from '@azure/msal-react';
import useUserAccesses from '../data/hooks/UserAccess/useUserAccesses';
import CountryTable from '../components/AccessManagement/CountryTable';
import ProjectTable from '../components/AccessManagement/ProjectTable';
import UserInfo from '../components/AccessManagement/UserInfo';
import useAdd from '../data/hooks/UserAccess/useAddUserAccess';
import useRemove from '../data/hooks/UserAccess/useRemoveUserAccess';
import { Scope } from '../models/enums/UserAccessScopeEnum';
import useInfProjectList from '../data/hooks/Project/useInfProjectListHook';
import { Country } from '../models/Country';
import { SearchUser } from '../components/SearchUser/SearchUser.tsx';
import { UserInformationDto } from '../models/User.ts';
import { getUsers } from '../services/auth.ts';

const AccessManagement = () => {
  const { instance } = useMsal();
  const { countries } = useCountries({ instance });
  const [user, setUser] = useState<UserInformationDto | null>(null);

  const { userAccesses, isFetching } = useUserAccesses({
    instance,
    userId: user?.userId ?? '',
  });
  const countryOptions = countries
    ? countries
        .map((country) => ({
          label: country.name,
          value: country.code,
        }))
        .filter((country) => !userAccesses?.countries.includes(country.value))
    : [];

  const { projects, search, handleSearchChange } = useInfProjectList(instance, 5, true);
  const projectsOptions = projects
    ? projects.map((project) => ({
        label: project.name,
        value: project.id,
      }))
    : [];

  const { mutate: addUserAccess } = useAdd(instance);
  const { mutate: removeUserAccess } = useRemove(instance);

  const fetchUsers = async (query: string): Promise<UserInformationDto[]> => {
    return await getUsers(instance, query);
  };

  const clearSelectedUser = () => {
    setUser(null);
  };

  const removeCountry = (country: string) => {
    if (!user) return;
    removeUserAccess({
      userId: user.userId,
      resource: country,
      scope: Scope.Country,
    });
  };

  const removeProject = (project: string) => {
    if (!user) return;
    removeUserAccess({
      userId: user.userId,
      resource: project,
      scope: Scope.Project,
    });
  };

  const addCountry = (country: string | null) => {
    if (!user || !country) return;
    addUserAccess({
      userId: user.userId,
      resource: country,
      scope: Scope.Country,
    });
  };

  const addProject = (project: string | null) => {
    if (!user || !project) return;
    addUserAccess({
      userId: user.userId,
      resource: project,
      scope: Scope.Project,
    });
  };

  const mapCountryCodeToCountry = (countryCode: string): Country => {
    const country = countries?.find((c) => c.code === countryCode);
    return country || { code: '-1', name: 'Unknown country' };
  };

  return (
    <Container size="xl">
      <Stack gap="xl">
        <Title order={2} mt="lg">
          Access Management
        </Title>
        <Divider />

        <Grid>
          <Grid.Col span={4}>
            <Stack gap="md">
              <Title order={3}>User</Title>
              <SearchUser
                width={'100%'}
                onChange={(user) => {
                  setUser(user);
                }}
                onClose={() => {}}
                fetchUsers={fetchUsers}
              />
              {user && (
                <>
                  <UserInfo user={user} clearSelectedUser={clearSelectedUser} />
                </>
              )}
            </Stack>
          </Grid.Col>

          {userAccesses && user && user.userId && !isFetching && (
            <Grid.Col span={6}>
              <Stack gap="md">
                <Title order={3}>Countries</Title>
                <Text opacity={0.5} fw={300} size="sm">
                  Access to a country also grants access to all projects within that country.
                </Text>
                <Select
                  key={'country'}
                  placeholder="Select country to add"
                  searchable
                  data={countryOptions}
                  style={{ maxWidth: 400 }}
                  onChange={addCountry}
                  nothingFoundMessage="All available countries are added"
                />
                <CountryTable
                  countries={userAccesses.countries.map(mapCountryCodeToCountry)}
                  removeCountry={removeCountry}
                />
              </Stack>

              <Stack gap="md" mt="xl">
                <Title order={3}>Projects</Title>
                <Select
                  key={'project'}
                  placeholder="Select project to add"
                  searchable
                  searchValue={search}
                  style={{ maxWidth: 400 }}
                  onChange={addProject}
                  data={projectsOptions}
                  onSearchChange={handleSearchChange}
                />
                <ProjectTable projects={userAccesses.projects} removeProject={removeProject} />
              </Stack>
            </Grid.Col>
          )}
        </Grid>
      </Stack>
    </Container>
  );
};

export default AccessManagement;
