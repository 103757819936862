import {
  Button,
  Group,
  Stack,
  Title,
  Input,
  SegmentedControl,
  Text,
  Paper,
  Alert,
  Center,
  Container,
  Box,
} from '@mantine/core';
import { useState } from 'react';
import { IconChevronLeft, IconMap2, IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { EditAndCreateProjectInputForm } from '../components/Project/CreateProjectModal/EditAndCreateProjectInputForm';
import { useMsal } from '@azure/msal-react';
import useCountries from '../data/hooks/Country/useCountriesHook';
import useDepots from '../data/hooks/Depots/useDepots';
import useMyUserAccesses from '../data/hooks/UserAccess/useMyUserAccess';
import useCreateProject from '../data/hooks/Project/useCreateProjectHook';
import { CreateWorkZone } from '../models/Project';
import { LocationMode } from '../shared/types';
import { APIProvider } from '@vis.gl/react-google-maps';
import AutoCompleteGoogleMapComponent from '../components/Map/AutoCompleteGoogleMap';
import { ManualCenterPoint } from '../components/Project/CreateProjectModal/EditAndCreateProjectModal';

// Feature flag to skip setup flow
const SKIP_SETUP_FLOW = true;

export default function CreateProject() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { countries } = useCountries({ instance });
  const { depots } = useDepots(instance);
  const { userAccesses } = useMyUserAccesses({ instance: instance });
  const { mutate: createProject } = useCreateProject(instance);
  const [centerPoint, setCenterPoint] = useState<ManualCenterPoint | null>(null);
  const [locationMode, setLocationMode] = useState<LocationMode>(LocationMode.Automatic);
  const [error, setError] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<boolean>(false);
  const [createdProjectId, setCreatedProjectId] = useState<string | null>(null);

  const handleCreateProject = (data: CreateWorkZone) => {
    const manualCenter = locationMode == LocationMode.Manual;

    if (manualCenter && centerPoint == null) {
      setError(true);
      setTimeout(() => setError(false), 5000);
      return;
    }

    createProject(
      {
        ...data,
        center:
          manualCenter && centerPoint
            ? {
                type: 'Point',
                coordinates: [centerPoint.position.lng, centerPoint.position.lat],
              }
            : null,
        manualCenter: manualCenter,
      },
      {
        onSuccess: async (response) => {
          const id = (await response.text()).replace(/"/g, '');
          setCreatedProjectId(id);
          if (SKIP_SETUP_FLOW) {
            navigate(`/projects/${id}`);
          }
        },
      }
    );
  };

  if (createdProjectId && !SKIP_SETUP_FLOW) {
    return (
      <Container h="100%" py="xl">
        <Paper shadow="xs" p="xl" radius="sm" withBorder maw={500} mx="auto">
          <Stack align="center" gap="xl">
            <Center bg="green.1" w={80} h={80} style={{ borderRadius: '50%' }}>
              <IconCheck size={40} color="var(--mantine-color-green-6)" />
            </Center>
            <Title order={2} ta="center">
              Project Created Successfully!
            </Title>
            <Text ta="center" c="dimmed">
              Would you like to continue with the project setup now or do it later?
            </Text>
            <Group w="100%" grow>
              <Button variant="outline" onClick={() => navigate(`/projects/${createdProjectId}`)}>
                View Project Details
              </Button>
              <Button onClick={() => navigate(`/projects/${createdProjectId}/setup`)}>
                Continue Setup
              </Button>
            </Group>
          </Stack>
        </Paper>
      </Container>
    );
  }

  return (
    <Stack h="100%" gap={0}>
      <Paper shadow="xs" radius={0} withBorder>
        <Box px="md" py="xl">
          <Group justify="space-between">
            <Button
              size="xs"
              leftSection={<IconChevronLeft />}
              variant="outline"
              onClick={() => navigate('/projects')}
            >
              Back
            </Button>
          </Group>
          <Title fw={700} order={3} mt="md">
            Create new project
          </Title>
        </Box>
      </Paper>

      <Box style={{ flex: 1, overflow: 'auto' }}>
        <Box px="md" pt={28} pb={84}>
          <Paper shadow="xs" p="xl" radius="sm" withBorder>
            <Group align="flex-start" grow wrap="wrap">
              <Stack miw={{ base: '100%', md: '45%' }}>
                <EditAndCreateProjectInputForm
                  create={createForm}
                  onSubmit={(data) => {
                    if (data == null) {
                      setCreateForm(false);
                      return;
                    }
                    handleCreateProject(data);
                  }}
                  initialValues={null}
                  countries={countries.filter((c) => userAccesses?.countries.includes(c.code))}
                  depots={depots}
                />
              </Stack>

              <Stack miw={{ base: '100%', md: '45%' }}>
                <Input.Wrapper label="Location" required>
                  <SegmentedControl
                    defaultValue={locationMode}
                    data={[LocationMode.Manual, LocationMode.Automatic]}
                    fullWidth
                    p={1}
                    onChange={(mode) =>
                      setLocationMode(
                        mode == 'Automatic' ? LocationMode.Automatic : LocationMode.Manual
                      )
                    }
                  />
                </Input.Wrapper>

                <Paper
                  withBorder
                  p="md"
                  ta={centerPoint && locationMode == LocationMode.Manual ? 'left' : 'center'}
                  bg="sand.1"
                >
                  {locationMode == LocationMode.Automatic ? (
                    <Group justify="center">
                      <IconMap2 size={20} />
                      <Text size="sm" fw={200}>
                        Location will be calculated based on all devices
                      </Text>
                    </Group>
                  ) : centerPoint && locationMode == LocationMode.Manual ? (
                    <>
                      <Text>{centerPoint.address}</Text>
                      <Text c="dimmed" fw={300} size="xs">
                        {centerPoint.position.lat.toString()}, {centerPoint.position.lng.toString()}
                      </Text>
                    </>
                  ) : (
                    <Group justify="center">
                      <IconMap2 size={20} />
                      <Text size="sm" fw={200}>
                        No location specified
                      </Text>
                    </Group>
                  )}
                </Paper>

                {error && (
                  <Alert
                    variant="light"
                    color="red"
                    title="No location specified"
                    icon={<IconAlertCircle />}
                  >
                    You need to choose a location for your project
                  </Alert>
                )}

                <Box mih={300}>
                  <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
                    <AutoCompleteGoogleMapComponent
                      mapOverlay={locationMode == LocationMode.Automatic}
                      onUpdate={(centerPoint) => {
                        setCenterPoint(centerPoint);
                      }}
                      height="300px"
                    />
                  </APIProvider>
                </Box>
              </Stack>
            </Group>
          </Paper>
        </Box>
      </Box>

      <Paper
        withBorder
        radius={0}
        py="md"
        px="xl"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'white',
          zIndex: 302,
        }}
      >
        <Group justify="flex-end">
          <Button variant="default" onClick={() => navigate('/projects')}>
            Cancel
          </Button>
          <Button onClick={() => setCreateForm(true)}>Create Project</Button>
        </Group>
      </Paper>
    </Stack>
  );
}
