import { Group, Text } from '@mantine/core';
import { DeviceLog } from '../../../models/DeviceLog.ts';
import { EquipmentTypeNames } from '../../../models/enums/DeviceEnums.ts';

export const IntelliTagDeviceLogTableRow = (log: DeviceLog) => {
  return (
    <>
      <Group p="apart" gap="m" m="xs">
        <Text size="sm">
          <strong>Battery (V): </strong>
          {log.batteryVoltage ?? '-'}
        </Text>
        <>
          <Text size="sm">
            <strong>Type: </strong>
            {EquipmentTypeNames[log.currentEquipmentType] ?? '-'}
          </Text>
          <Text size="sm">
            <strong>Attached to: </strong>
            {log.attachmentRef ?? '-'}
          </Text>
        </>
      </Group>
    </>
  );
};
