import {
  Anchor,
  Breadcrumbs,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Input,
  InputWrapper,
  Loader,
  Select,
  Space,
  Stack,
  Title,
} from '@mantine/core';
import { IconBatteryCharging, IconChevronLeft, IconQrcode } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TwoColumnText } from '../shared/TwoColumnText';

import { useMsal } from '@azure/msal-react';
import { useMediaQuery } from 'react-responsive';
import DeviceLogsTable from '../components/Device/DeviceLogsTable';
import { PopoverConfirm } from '../components/Popover/PopoverConfirm.tsx';
import useDevice from '../data/hooks/Device/useDeviceHook';
import useDeviceReplaceBattery from '../data/hooks/Device/useDeviceReplaceBatteryHook.ts';
import useInfProjectList from '../data/hooks/Project/useInfProjectListHook';
import useProject from '../data/hooks/Project/useProjectHook.ts';
import { Device } from '../models/Device';
import { EquipmentType, EquipmentTypeNames } from '../models/enums/DeviceEnums';
import { SearchableSelect } from '../shared/SearchableSelect';
import { getSignNameBySignId } from '../utils/signs.ts';
import { generateQrCode, getEstimatedBatteryLifetime } from '../utils/utils.tsx';

export const EditDevicePage = () => {
  const { deviceId } = useParams();
  const { instance } = useMsal();
  const mobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [device, setDevice] = useState<Device>();

  const { project } = useProject({
    instance: instance,
    workZoneId: device?.workZoneId ?? '',
  });
  const [countryCode, setCountryCode] = useState<string>('');
  const [openedConfirmChangeBattery, setOpenedConfirmChangeBattery] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutateAsync: replaceDeviceBattery } = useDeviceReplaceBattery(instance);

  useEffect(() => {
    if (project?.countryCode) {
      setCountryCode(project!.countryCode);
    }
  }, [project]);

  const NoDevice = () => (
    <Stack p={'xl'}>
      <Title order={4}>No device</Title>
      <Button
        w={'fit-content'}
        leftSection={<IconChevronLeft />}
        variant="outline"
        onClick={() => navigate('/devices')}
      >
        Back
      </Button>
    </Stack>
  );

  if (!deviceId) {
    return <NoDevice />;
  }

  const {
    device: deviceData,
    isLoading: deviceLoading,
    isError: deviceError,
  } = useDevice({ instance, deviceId });

  const { projects, setFilters } = useInfProjectList(instance);

  useEffect(() => {
    if (deviceData) {
      setDevice(deviceData);
    }
  }, [deviceData, deviceError]);

  if (!device) {
    return <NoDevice />;
  }

  const crumbs = [
    { title: 'Home', href: '/home' },
    { title: 'Devices', href: '/devices' },
    { title: device?.currentName, href: `/projects/${device?.id}` },
  ].map((item, index) => (
    <Anchor size="h2" href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const convertEquipment = (value: string | null): number | null => {
    switch (value) {
      case 'Sign':
        return 1;
      case 'Barrier':
        return 2;
      default:
        return null;
    }
  };

  const handleCreateQrCode = async () => {
    try {
      if (device) {
        generateQrCode(device);
      }
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  return (
    <>
      {deviceLoading ? (
        <Center mt="xl">
          <Loader />
        </Center>
      ) : (
        <Stack p={'xl'}>
          <Breadcrumbs separator=">" separatorMargin={'md'} mt={'sm'}>
            {crumbs}
          </Breadcrumbs>
          <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'}>
            <Stack miw={'300px'} mb={'sm'}>
              <Title size="h3" order={2}>
                {device.referenceId}
              </Title>
              <TwoColumnText first="IMEI" second={device.imei} />

              <TwoColumnText
                first="Battery (V)"
                second={device.batteryVoltage ? device.batteryVoltage.toString() : '-'}
              />

              {device.estimatedBatteryEndDate && (
                <TwoColumnText
                  first="Estimated battery lifetime"
                  second={getEstimatedBatteryLifetime(device.estimatedBatteryEndDate)}
                />
              )}

              <TwoColumnText
                first="Power saving mode"
                second={device.inPowerSavingMode ? 'Yes' : 'No'}
              />

              <TwoColumnText first="Model" second={device.deviceModel} />
            </Stack>
            <Stack>
              <Button
                variant="filled"
                onClick={() => {
                  navigate('/map', {
                    state: {
                      device: device,
                    },
                  });
                }}
              >
                View on Map
              </Button>
              {device.estimatedBatteryEndDate && (
                <PopoverConfirm
                  opened={openedConfirmChangeBattery}
                  setOpened={setOpenedConfirmChangeBattery}
                  text="Are you sure you want to log the battery change?"
                  btnText="Log Battery Change"
                  width={300}
                  onClick={() => {
                    replaceDeviceBattery({ deviceId: deviceId }).then(() =>
                      setOpenedConfirmChangeBattery(false)
                    );
                  }}
                  position="bottom"
                  icon={<IconBatteryCharging />} // Passing the icon here
                  withGroup={true} // Enabling group for icon and text alignment
                />
              )}
            </Stack>
          </Flex>
          <Divider color="gray.6" label="Edit" labelPosition={mobile ? 'center' : 'left'} />
          <Flex direction={{ base: 'column', md: 'row' }} gap={'md'} wrap={'wrap'}>
            <InputWrapper
              style={{ flexGrow: 1, maxWidth: '1000px' }}
              ta={'left'}
              label="Additional info"
            >
              <Input
                value={device.currentName}
                onChange={(event) => setDevice({ ...device, currentName: event.target.value })}
                disabled={true}
              ></Input>
            </InputWrapper>
            <Select
              label="Type"
              ta={'left'}
              data={Object.values(EquipmentTypeNames)}
              value={
                device.currentEquipmentType ? EquipmentTypeNames[device.currentEquipmentType] : ''
              }
              onChange={(type) =>
                setDevice({
                  ...device,
                  currentEquipmentType: convertEquipment(type),
                })
              }
              searchable
              disabled
            />
            {device.currentEquipmentType == EquipmentType.Sign && countryCode != '' ? (
              <Select
                label="Sign"
                ta={'left'}
                data={[getSignNameBySignId(countryCode, device.attachmentRef) ?? '']}
                value={getSignNameBySignId(countryCode, device.attachmentRef)}
                disabled
              />
            ) : null}
            <Select label="Country" ta={'left'} data={[countryCode]} value={countryCode} disabled />
            <SearchableSelect
              width={mobile ? '100%' : '250px'}
              label="Assigned to project"
              value={{
                id: device.workZoneId,
                label: device.workZoneName,
              }}
              items={projects
                .filter((p) => p.countryCode == countryCode)
                .map((p) => {
                  return { id: p.id, label: p.name };
                })}
              emitter={(value) =>
                setDevice(value?.id ? { ...device, workZoneId: value.id } : device)
              }
              setFilters={setFilters}
              disabled={true}
            />
          </Flex>
          <Stack>
            <Divider
              color="gray.6"
              label="Commands"
              labelPosition={mobile ? 'center' : 'left'}
              mt={'md'}
            />
            <Group>
              <Button onClick={handleCreateQrCode} mt={'md'} w={mobile ? '100%' : 'fit-content'}>
                Generate QR
                <Space w="sm" />
                <IconQrcode />
              </Button>
            </Group>
          </Stack>
          <Divider
            color="gray.6"
            label="History"
            labelPosition={mobile ? 'center' : 'left'}
            mt={'md'}
          />{' '}
          <DeviceLogsTable deviceId={device.id} />
        </Stack>
      )}
    </>
  );
};
