import { Grid, Stack, Text, List, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { NotificationContact } from '../../../models/NotificationContact';
import { ProductSettings } from './ProductSetupStep';

interface Props {
  notificationContacts: NotificationContact[];
  productSettings: ProductSettings;
}

export function SummaryStep({ notificationContacts, productSettings }: Props) {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Stack>
          <Text size="sm" fw={500}>
            Project Summary
          </Text>

          <Stack gap="xl">
            <div>
              <Text fw={500}>Notification Contacts</Text>
              <List
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCheck size="1rem" />
                  </ThemeIcon>
                }
              >
                {notificationContacts.map((contact, index) => (
                  <List.Item key={index}>
                    {contact.name} ({contact.external ? 'External' : 'Internal'})
                  </List.Item>
                ))}
              </List>
            </div>

            <div>
              <Text fw={500}>Product Settings</Text>
              <List
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCheck size="1rem" />
                  </ThemeIcon>
                }
              >
                {productSettings.intellitags && <List.Item>Intellitags enabled</List.Item>}
                {productSettings.arrowboards && <List.Item>Arrowboards enabled</List.Item>}
              </List>
            </div>
          </Stack>
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
