import { Table, Tooltip, Button, Text } from '@mantine/core';
import { UserAcessProject } from '../../models/UserAccess';
import classes from './AccessManagement.module.css';

const ProjectTable = (props: {
  projects: UserAcessProject[];
  removeProject: (projectId: string) => void;
}) => {
  const rows = props.projects.map((project) => (
    <Table.Tr key={project.projectId}>
      <Table.Td>{project.name}</Table.Td>
      <Table.Td>{project.projectId}</Table.Td>
      <Table.Td>{project.country}</Table.Td>
      <Table.Td ta="end">
        <Tooltip label="Remove access to project" position="left">
          <Button
            size="xs"
            c="red"
            bg="rgba(255,0,0,0.1)"
            onClick={() => props.removeProject(project.projectId)}
          >
            Remove
          </Button>
        </Tooltip>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table highlightOnHover bg={'white'} className={classes.table}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>ID</Table.Th>
          <Table.Th>Country</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {props.projects.length > 0 ? (
          rows
        ) : (
          <Table.Tr>
            <Table.Td colSpan={4} style={{ textAlign: 'center', padding: '20px' }}>
              <Text size="sm">No project accesses</Text>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};

export default ProjectTable;
