import { Button, Drawer, Group, Space, Stack, Text, Title } from '@mantine/core';

type Props = {
  children?: React.ReactNode;
  confirmColor?: string;
  confirmLabel?: string;
  confirmDisabled?: boolean;
  label?: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title?: string;
};

export default function HalfPanel({
  children,
  confirmColor,
  confirmDisabled,
  confirmLabel = 'Yes',
  label,
  open,
  onClose,
  onConfirm,
  title,
}: Props) {
  return (
    <Drawer.Root radius="8 8 0 0" opened={open} onClose={onClose} position="bottom" size="sm">
      <Drawer.Overlay />
      <Drawer.Content h="auto">
        <Drawer.Header>
          <Stack gap={0}>
            <Title fw={500} size="h3">
              {title}
            </Title>
            <Text size="sm" opacity={0.6}>
              {label}
            </Text>
          </Stack>
        </Drawer.Header>
        <Space h="md" />
        <Drawer.Body mb="10px">
          {children}
          <Space h="xl" />
          <Group gap={10}>
            <Button flex={1} onClick={onClose} size="lg" variant="default">
              Cancel
            </Button>
            {onConfirm && (
              <Button
                disabled={confirmDisabled}
                flex={1}
                size="lg"
                color={confirmColor}
                onClick={onConfirm}
              >
                {confirmLabel}
              </Button>
            )}
          </Group>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
}
