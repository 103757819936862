import { Divider, Modal, Stack } from '@mantine/core';
import { useMobileDevice } from '../../../hooks/useMobileDevice';
import { Device, DeviceType } from '../../../models/Device';
import { ConfirmButtonGroup } from '../../../shared/ConfirmButtonGroup/ConfirmButtonGroup';
import { useEffect, useState } from 'react';
import { FlexSpacer } from '../../../shared/FlexSpacer';
import { GenericDeviceInput } from './AddDeviceSteps/GenericDeviceInput';
import { WorkZone } from '../../../models/Project';
import { IntellitagInput } from './AddDeviceSteps/IntellitagInput';
import { PoweredEquipment } from '../../../models/enums/DeviceEnums.ts';
import { SmartCableInput } from './AddDeviceSteps/SmartCableInput.tsx';
import { SelectDeviceInput } from './AddDeviceSteps/SelectDeviceInput.tsx';
import { SelectProjectInput } from './AddDeviceSteps/SelectProjectInput.tsx';
import { SmartSolarInput } from './AddDeviceSteps/SmartSolarInput.tsx';

type Props = {
  showModal: boolean;
  initialDevice: Device | null;
  initialProject: WorkZone | null;
  close: () => void;
  onSubmit: (device: Device) => void;
};

export const AddDeviceToProjectModal = ({
  showModal,
  initialDevice,
  initialProject,
  close,
  onSubmit,
}: Props) => {
  const isMobile = useMobileDevice();
  const [step, setStep] = useState<AssignmentStep>(
    initialProject ? AssignmentStep.SelectDevice : AssignmentStep.SelectProject
  );
  const [selectedProject, setSelectedProject] = useState<WorkZone | null>();
  const [selectedDevice, setSelectedDevice] = useState<Device | null>();

  useEffect(() => {
    if (initialDevice) {
      setSelectedDevice(initialDevice);
      setStep(AssignmentStep.SelectProject);
    }
    if (initialProject) {
      setSelectedProject(initialProject);
      setStep(AssignmentStep.SelectDevice);
    }
  }, [initialDevice, initialProject, showModal]);

  const renderInput = {
    [AssignmentStep.SelectProject]: <SelectProjectInput onSelect={setSelectedProject} />,
    [AssignmentStep.SelectDevice]: <SelectDeviceInput onSelect={setSelectedDevice} />,
    [AssignmentStep.IntelliTag]:
      selectedDevice && selectedProject ? (
        <IntellitagInput
          device={selectedDevice}
          country={selectedProject.countryCode ?? ''}
          onChange={(v) => {
            selectedDevice.currentEquipmentType = v.equipmentType;
            selectedDevice.attachmentRef = v.attachmentRef ?? '';
            selectedDevice.currentName = v.currentName ?? '';
            selectedDevice.workZoneId = selectedProject.id;
          }}
        />
      ) : null,
    [AssignmentStep.SmartCable]:
      selectedDevice && selectedProject ? (
        <SmartCableInput
          device={selectedDevice}
          country={selectedProject.countryCode}
          onChange={(v) => {
            selectedDevice.currentName = v.currentName ?? '';
            selectedDevice.poweredEquipment = v.poweredEquipment ?? PoweredEquipment.Other;
            selectedDevice.workZoneId = selectedProject.id;
          }}
        />
      ) : null,
    [AssignmentStep.SmartSolar]:
      selectedDevice && selectedProject ? (
        <SmartSolarInput
          device={selectedDevice}
          country={selectedProject.countryCode}
          onChange={(v) => {
            selectedDevice.currentName = v.currentName ?? '';
            selectedDevice.poweredEquipment = v.poweredEquipment ?? PoweredEquipment.Other;
            selectedDevice.workZoneId = selectedProject.id;
          }}
        />
      ) : null,
    [AssignmentStep.Other]:
      selectedDevice && selectedProject ? (
        <GenericDeviceInput
          device={selectedDevice}
          project={selectedProject}
          onChange={(value) => {
            selectedDevice.currentName = value;
            selectedDevice.workZoneId = selectedProject.id;
          }}
        />
      ) : null,
  };

  return (
    <Modal.Root
      opened={showModal}
      onClose={() => {
        setSelectedProject(null);
        setSelectedDevice(null);
        close();
      }}
      fullScreen={isMobile}
      centered
      size={400}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Add device to project</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h={500}>
          <Stack h={'100%'} gap={0}>
            {renderInput[step]}
            <FlexSpacer />
            <Divider my={'sm'} />
            <ConfirmButtonGroup
              confirmBtnText={
                step === AssignmentStep.SelectDevice || step === AssignmentStep.SelectProject
                  ? 'Proceed'
                  : 'Add to project'
              }
              abortBtnText={step === AssignmentStep.IntelliTag ? 'Back' : 'Cancel'}
              onAbort={() => {
                setSelectedProject(null);
                setStep(AssignmentStep.SelectProject);
                close();
              }}
              onConfirm={() => {
                if (step === AssignmentStep.SelectProject || step === AssignmentStep.SelectDevice) {
                  switch (selectedDevice?.deviceType) {
                    case DeviceType.Intellitag:
                      setStep(AssignmentStep.IntelliTag);
                      break;
                    case DeviceType.SmartCable:
                      setStep(AssignmentStep.SmartCable);
                      break;
                    case DeviceType.SmartSolar:
                      setStep(AssignmentStep.SmartSolar);
                      break;
                    default:
                      setStep(AssignmentStep.Other);
                      break;
                  }
                } else if (selectedDevice && selectedProject) {
                  onSubmit(selectedDevice);
                  close();
                }
              }}
            />
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

const enum AssignmentStep {
  SelectProject,
  SelectDevice,
  IntelliTag,
  SmartCable,
  SmartSolar,
  Other,
}
