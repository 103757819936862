import { Group, Stack, Title, Text, Button } from '@mantine/core';
import { useContext, useState } from 'react';
import { PWAContext } from '../../providers/PWAProvider';
import DeviceScanner from '../../components/Scanner/DeviceScanner';
import DeviceStatuses from './components/DeviceStatuses';
import NoDeviceMessage from './components/NoDeviceMessage';
import { IconExclamationCircle } from '@tabler/icons-react';
import PwaPageHeading from '../../components/PwaPageHeading';

export const PwaHealthCheckPage = () => {
  const { device } = useContext(PWAContext);
  const [showIntroMessage, setIntroMessage] = useState(true);

  return (
    <>
      {showIntroMessage ? (
        <Stack flex={1} mb="lg">
          <Stack
            flex={1}
            p="xl"
            bg="primaryGreenLight"
            justify="center"
            style={{ borderRadius: '24px' }}
          >
            <Stack gap="lg" align="center">
              <IconExclamationCircle size={80} />

              <Stack align="center">
                <Title order={3} ta="center">
                  For optimal conditions to check health
                </Title>

                <Stack w="fit-content">
                  <Text>1. Make sure the device is added to a project</Text>
                  <Text>2. Make sure the device is outdoors</Text>
                  <Text>3. Wait for 8 hours (UK: 2 hours)</Text>
                  <Text>4. Continue to health check</Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Button
            size="lg"
            onClick={() => {
              if (window._paq) {
                window._paq.push(['trackEvent', 'Health Check', 'Continued to health check']);
              }
              setIntroMessage(false);
            }}
          >
            Continue
          </Button>
        </Stack>
      ) : (
        <Stack flex={1} justify="space-between" mb="lg">
          <Stack gap="sm" flex={1}>
            <Group justify="space-between" align="end">
              <PwaPageHeading label="Health Check">Device Health</PwaPageHeading>
              {device && <Text size="lg">{device.referenceId}</Text>}
            </Group>

            {device ? (
              <DeviceStatuses
                latestUpdate={device.latestUpdate}
                batteryLevel={device.batteryVoltage}
                batteryStatus={device.batteryStatus}
              />
            ) : (
              <NoDeviceMessage />
            )}
          </Stack>

          <DeviceScanner isHealthCheck manualScanButtonLabel="Check" trackingId="Health Check" />
        </Stack>
      )}
    </>
  );
};
