import { Paper, Stack, Title, Text, Box, Tabs, Center, Loader } from '@mantine/core';
import { useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import Papa from 'papaparse';
import useBulkProvisionDevices from '../data/hooks/Device/useBulkProvisionDevices';
import useInfBulkProvisionListHook from '../data/hooks/BulkProvision/useInfBulkProvisionListHook';
import ProvisionTable from '../components/BulkProvision/ProvisionTable';
import '@mantine/dropzone/styles.css';
import { FileWithPath } from '@mantine/dropzone';
import FileDropzone from '../components/BulkProvision/FileDropzone';
import ProvisioningSummaryCard from '../components/BulkProvision/ProvisioningSummaryCard';
interface DeviceData {
  referenceId: string;
  imei: string;
  modelRef: number;
  deviceType: number;
}

export const BulkProvisionDevices = () => {
  const { instance } = useMsal();
  const [fileError, setFileError] = useState<string>('');
  const [provisionSuccess, setProvisionSuccess] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const requiredColumns = ['referenceId', 'imei', 'modelRef', 'deviceType'];

  const { bulkProvisions, isLoading } = useInfBulkProvisionListHook(instance);

  const { mutateAsync: bulkProvisionDevices } = useBulkProvisionDevices(instance);

  const totalDevices = bulkProvisions.reduce(
    (acc, provision) => acc + provision.provisions.length,
    0
  );

  const totalConnectedDevices = bulkProvisions.reduce((acc, provision) => {
    return acc + provision.provisions.filter((device) => device.hasConnected).length;
  }, 0);

  const totalNotConnectedDevices = totalDevices - totalConnectedDevices;

  const totalProvisionings = bulkProvisions.length;

  const completedProvisionings = bulkProvisions.filter((provision) =>
    provision.provisions.every((device) => device.hasConnected)
  ).length;

  const handleFileChange = (files: FileWithPath[]) => {
    setFileError('');
    setProvisionSuccess('');
    const file = files[0];

    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const { data, errors } = results;
          if (errors.length) {
            setFileError('Error parsing CSV file');
            return;
          }
          const headers = Object.keys(data[0] as DeviceData);
          const missingColumns = requiredColumns.filter((col) => !headers.includes(col));

          if (missingColumns.length > 0) {
            setFileError(`Missing required columns: ${missingColumns.join(', ')}`);
            return;
          }
          const validatedData: DeviceData[] = data.map((row: any) => ({
            ...row,
            modelRef: parseInt(row.modelRef, 10),
            deviceType: parseInt(row.deviceType, 10),
          }));

          handleBulkAdd(validatedData);
        },
      });
    } else {
      setFileError('Please upload a valid CSV file.');
    }
  };

  const handleBulkAdd = (data: DeviceData[]) => {
    bulkProvisionDevices({ DeviceProvisionings: data })
      .then(() => {
        setProvisionSuccess('All devices have been successfully provisioned.');
      })
      .catch((error) => {
        console.error('Bulk provision failed:', error);
        setFileError('Failed to provision devices. Please try again.');
      })
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      });
  };

  return (
    <>
      <Paper pt="lg" px="lg" py="md">
        <Title order={1} mb="xs">
          Device Provisioning
        </Title>
      </Paper>
      <Tabs defaultValue="provisionings">
        <Tabs.List px={'md'} bg={'white'}>
          <Tabs.Tab value="provisionings">
            <Text>Provisionings</Text>
          </Tabs.Tab>
          <Tabs.Tab value="new-bulk-provisioning">
            <Text>New Bulk Provisionings</Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="provisionings">
          <Box pl="lg">
            <Title order={2} pt="lg" pb="lg">
              Bulk Provisionings
            </Title>
            {isLoading ? (
              <Center mt="xl">
                <Loader />
              </Center>
            ) : (
              <Stack>
                <ProvisioningSummaryCard
                  totalDevices={totalDevices}
                  totalConnectedDevices={totalConnectedDevices}
                  totalNotConnectedDevices={totalNotConnectedDevices}
                  totalProvisionings={totalProvisionings}
                  completedProvisionings={completedProvisionings}
                />
                {bulkProvisions.map((provision) => (
                  <ProvisionTable key={provision.name} provision={provision} />
                ))}
              </Stack>
            )}
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="new-bulk-provisioning">
          <Paper p="lg">
            <Title order={2} p="sm">
              New Bulk Provisioning
            </Title>
            <Text c="dimmed" size="sm">
              Upload CSV files to provision devices in bulk. Each file will be processed and
              displayed under the Provisionings tab.
            </Text>
            <FileDropzone
              onDrop={handleFileChange}
              fileError={fileError}
              provisionSuccess={provisionSuccess}
            />
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
