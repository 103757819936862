import { Badge, Group, Text } from '@mantine/core';
import { BatteryBadge, SnoozeBadge, StatusBadge } from '../Badge';
import { formatDateTime } from '../../utils/utils';
import { Device } from '../../models/Device';
import { AlarmStatus, BatteryStatus } from '../../models/enums/DeviceEnums';

type Props = {
  devices: Device[];
  isSnoozed?: boolean;
  snoozedUntil?: Date;
  isClosed?: boolean;
  closedAt?: Date;
  size?: 'md' | 'lg';
};

export default function StatusSummaryRow({
  devices = [],
  isSnoozed,
  snoozedUntil,
  isClosed,
  closedAt,
  size = 'lg',
}: Props) {
  const getDeviceStatuses = () => {
    if (isClosed && closedAt) {
      return (
        <Badge
          c={'black'}
          bg={'#f1f3f5'}
          style={{ border: '1px solid #f1f3f5', textTransform: 'none' }}
          w={'fit-content'}
        >
          <Text size={size == 'md' ? 'xs' : 'sm'} c="black" fw={500}>
            Closed {formatDateTime(closedAt)}
          </Text>
        </Badge>
      );
    }
    if (devices.length === 0)
      return (
        <Badge
          c={'black'}
          bg={'#f1f3f5'}
          style={{ border: '1px solid #f1f3f5', textTransform: 'none' }}
          w={'fit-content'}
        >
          <Text size={size == 'md' ? 'xs' : 'sm'} c="black" fw={500}>
            No Devices
          </Text>
        </Badge>
      );

    let alarming: number = 0,
      ok: number = 0,
      lowBattery: number = 0,
      criticalBattery: number = 0,
      outOfBattery: number = 0;

    devices.map((device) => {
      if (device.alarmStatus === AlarmStatus.Alarming) alarming++;
      if (device.alarmStatus === AlarmStatus.OK) ok++;
      if (device.batteryStatus === BatteryStatus.OUT_OF_BATTERY) outOfBattery++;
      if (device.batteryStatus === BatteryStatus.CRITICAL) criticalBattery++;
      if (device.batteryStatus === BatteryStatus.LOW) lowBattery++;
    });

    return (
      <Group gap={8}>
        {ok > 0 && <StatusBadge size={size} number={ok} status={AlarmStatus.OK} />}
        {alarming > 0 && (
          <StatusBadge size={size} number={alarming} status={AlarmStatus.Alarming} />
        )}
        {criticalBattery > 0 && (
          <BatteryBadge
            size={size}
            batteryStatus={BatteryStatus.CRITICAL}
            number={criticalBattery}
          />
        )}
        {lowBattery > 0 && (
          <BatteryBadge size={size} batteryStatus={BatteryStatus.LOW} number={lowBattery} />
        )}
        {outOfBattery > 0 && (
          <BatteryBadge
            size={size}
            batteryStatus={BatteryStatus.OUT_OF_BATTERY}
            number={outOfBattery}
          />
        )}
      </Group>
    );
  };

  if (isSnoozed)
    return (
      <Group wrap="nowrap" gap={10}>
        <SnoozeBadge size={size} />
        <Text
          c={'gray.7'}
          size={size == 'md' ? 'xs' : 'sm'}
        >{`Until ${formatDateTime(snoozedUntil)}`}</Text>
      </Group>
    );

  return <Group gap={8}>{getDeviceStatuses()}</Group>;
}
