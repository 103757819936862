import { Button, FloatingPosition, Group, Popover, Text } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  opened: boolean;
  setOpened: (next: boolean) => void;
  text: string;
  btnText: string;
  width: number;
  position?: FloatingPosition;
  onClick: () => void;
  icon?: ReactNode; // Allow custom icon like the battery icon
  withGroup?: boolean; // Allow wrapping text and icon in a Group component
};

export const PopoverConfirm = ({
  opened,
  setOpened,
  text,
  btnText,
  width,
  onClick,
  position = 'right-start',
  icon,
  withGroup = false, // Default to false for optional Group
}: Props) => {
  return (
    <>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        width={width}
        position={position}
        withArrow
      >
        <Popover.Target>
          <Button
            variant="outline"
            onClick={() => setOpened(true)} // This will only open the popover
            mr="sm"
            mt="15px"
          >
            {withGroup ? (
              <Group align="center" p="md" gap="sm">
                {icon}
                <Text fw={600} size="sm">
                  {btnText}
                </Text>
              </Group>
            ) : (
              btnText
            )}
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <div style={{ padding: 10 }}>
            <Text size="sm">{text}</Text>
            <Group justify="end">
              <Button size="xs" variant="outline" onClick={() => setOpened(false)}>
                Cancel
              </Button>
              <Button size="xs" color="red.8" onClick={onClick} loading={false}>
                Confirm
              </Button>
            </Group>
          </div>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
