import { Button, Group, Image, Modal, Space, Stack, Text, Title } from '@mantine/core';
import { getImageUrlForSign } from '../../../utils/ImageHelpers';
import { Sign } from '../../../models/Sign';

type Props = {
  identifiedSign: Sign | null;
  onConfirm: (sign: Sign) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

export default function SignRecognitionModal({
  identifiedSign,
  onConfirm,
  showModal,
  setShowModal,
}: Props) {
  if (!identifiedSign) return null;

  return (
    <Modal opened={showModal} onClose={() => setShowModal(false)} centered>
      <Stack align="center">
        <Title size="h3">Sign identified as {identifiedSign?.name}</Title>
        <Image
          fit="contain"
          mah="140px"
          my="20px"
          src={getImageUrlForSign(identifiedSign?.value)}
        />

        <Text ta="center" opacity={0.5} fs="italic">
          If this was not correct, either try again or find the sign in the list.
        </Text>
      </Stack>
      <Space h="xl" />
      <Group gap={10}>
        <Button onClick={() => setShowModal(false)} flex={1} size="md" variant="default">
          Cancel
        </Button>
        <Button flex={1} onClick={() => onConfirm(identifiedSign)} size="md">
          Select {identifiedSign?.name}
        </Button>
      </Group>
    </Modal>
  );
}
