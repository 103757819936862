import { Stack } from '@mantine/core';
import { Device } from '../../../../models/Device';
import { IconRefresh } from '@tabler/icons-react';
import { BatteryStatus } from '../../../../models/enums/DeviceEnums';
import Badge, { BADGE_STATES } from '../../../../components/Badge/Badge';
import dayjs from 'dayjs';
import { BatteryIcon } from '../../../components/BatteryIcon';
import getBadgeStateFromBatteryStatus from '../../../utils/battery';

// Unassigned devices send updates every 24h's
export const UPDATE_INTERVAL = 24;

// TODO: Should move to backend in the future
function getBadgeStateFromLatestUpdate(latestUpdate: string) {
  if (!latestUpdate) {
    return BADGE_STATES.CRITICAL;
  } else if (dayjs().diff(dayjs(latestUpdate), 'h') > UPDATE_INTERVAL) {
    return BADGE_STATES.WARNING;
  } else {
    return BADGE_STATES.OK;
  }
}

interface DeviceStatusesProps {
  latestUpdate: Device['latestUpdate'];
  batteryLevel: Device['batteryVoltage'];
  batteryStatus: Device['batteryStatus'];
}
export default function DeviceStatuses({
  latestUpdate,
  batteryLevel,
  batteryStatus,
}: DeviceStatusesProps) {
  const batteryLevelValue = () => {
    if (batteryLevel === null) return '???';
    else if (batteryStatus === BatteryStatus.OUT_OF_BATTERY) return 'Out of battery';
    else return `${batteryLevel} V`;
  };

  return (
    <Stack w="100%" gap={8}>
      <Badge
        size="md"
        fullWidth
        icon={<IconRefresh size={24} color="black" />}
        label="Last update:"
        value={
          latestUpdate ? dayjs(latestUpdate).format('YYYY-MM-DD, HH:mm') : 'No update sent yet'
        }
        state={getBadgeStateFromLatestUpdate(latestUpdate)}
      />
      <Badge
        size="md"
        fullWidth
        icon={<BatteryIcon batteryStatus={batteryStatus} />}
        label="Battery:"
        value={batteryLevelValue()}
        state={batteryLevel ? getBadgeStateFromBatteryStatus(batteryStatus) : BADGE_STATES.OFFLINE}
      />
    </Stack>
  );
}
