import { Divider, Stack } from '@mantine/core';
import ManualConnection from './components/ManualConnection';
import Scanner from './components/Scanner/Scanner';
import { useDeviceConnection } from '../../hooks/useDeviceConnection';

interface Props {
  manualScanButtonLabel: string;
  scanLabel?: string;
  isHealthCheck?: boolean;
  trackingId: string;
}

export default function DeviceScanner({
  scanLabel,
  manualScanButtonLabel,
  isHealthCheck,
  trackingId,
}: Props) {
  const { handleConnection, isLoading } = useDeviceConnection(isHealthCheck);

  return (
    <Stack gap="sm">
      <Scanner text={scanLabel} handleConnection={handleConnection} trackingId={trackingId} />
      <Divider label="or" labelPosition="center" size="sm" />
      <ManualConnection
        isLoading={isLoading}
        handleConnection={handleConnection}
        buttonLabel={manualScanButtonLabel}
        trackingId={trackingId}
      />
    </Stack>
  );
}
