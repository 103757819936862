import { Button, Collapse, SimpleGrid, Stack, Title } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

export default function SignCategory({ title, children }: Props) {
  const [isOpen, setOpen] = useState(true);

  return (
    <Stack>
      <Button
        variant="transparent"
        onClick={() => setOpen(!isOpen)}
        c="black"
        fullWidth
        justify="space-between"
        px="none"
        h="42px"
        rightSection={isOpen ? <IconChevronUp size={32} /> : <IconChevronDown size={32} />}
      >
        <Title order={2} size="h5">
          {title}
        </Title>
      </Button>

      <Collapse in={isOpen}>
        <SimpleGrid cols={4}>{children}</SimpleGrid>
      </Collapse>
    </Stack>
  );
}
