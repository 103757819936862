import { GoogleMapComponent } from '../components/Map/GoogleMap';
import { APIProvider, useApiIsLoaded } from '@vis.gl/react-google-maps';
import { Center, Loader } from '@mantine/core';

export default function MapPage() {
  const apiIsLoaded = useApiIsLoaded();
  return (
    <>
      {apiIsLoaded ? (
        <Center>
          <Loader mt={'xl'} size={'md'}></Loader>
        </Center>
      ) : (
        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
          <GoogleMapComponent />
        </APIProvider>
      )}
    </>
  );
}
